import React from "react"
import Navbar from "../../../../Common/Navbar"
import Footer from "../../../../Common/Footer"
import Sidebar from "../../../../Sidebar/Sidebar"
import AssignmentGeneratorHistoryComp from "./Component/AssignmentGeneratorHistoryComp"

const AssignmentGeneratorHistory = () => {
    return (
        <>
            <Navbar />
            <div className="d-flex mainContentArea">
                <Sidebar />
                <AssignmentGeneratorHistoryComp />
            </div>
            <Footer />
        </>
    )
}

export default AssignmentGeneratorHistory;