import React from "react"
import Navbar from "../../../Common/Navbar"
import Footer from "../../../Common/Footer"
import Sidebar from "../../../Sidebar/Sidebar"
import AssignmentPreviewComp from "./Component/AssignmentPreviewComp"


const AssignmentPreview =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <AssignmentPreviewComp />
        </div>
        <Footer />
        </>
    )
}

export default AssignmentPreview