import React, { useState, useEffect, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';

import Heading from "../../../../Common/Heading";
import { toast } from "react-toastify"
import LoadingSpinner from "../../../../Common/LoadingSpinner";

import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import SaveAlt from '@mui/icons-material/SaveAlt';

import axios from "axios";
import url from "../../../../Common/URL"

import { useConfirm } from 'material-ui-confirm';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import MaterialReactTable from 'material-react-table';
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";

const ManagerTable = () => {
  const confirm = useConfirm();

  // ============= For Bulk Upload of Studnets in DB======================= 

  const [load, setLoad] = useState(false);
  const [file_import, setFile_import] = useState(null);
  const [open, setOpen] = useState(false);
  // ============= For Bulk Upload of Studnets in DB======================= 

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);



  const Columns = useMemo(
    () => [
      { header: 'First Name', accessorKey: 'fname' },
      { header: 'Last Name', accessorKey: 'lname' },
      { header: 'Location', id: 'location_name', accessorFn: rowData => <p style={{ whiteSpace: "pre", margin: -5 }}>{rowData.location_name.split(",").join(", ")}</p> },
      { header: 'Join Date', accessorKey: 'join_date' }
    ],
    [],
  );


  // ------------------------Fetching Teacher table's data-------------------------------------
  useEffect(() => {
    loadTeachers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTeachers = () => {
    const obj = { uname: sessionStorage.getItem("username") }
    axios.post(url + "php/manager/select_all_manager.php", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = JSON.parse(response.data.data);
          // console.log(a);
          setLoading(false)
          a === null ? setData([]) : setData(a);

        } else {
          setLoading(false)
          setData([]);
        }
      })
      .catch((err) => console.log(err));
  }
  // ------------------------Deleting Teacher table's data-------------------------------------

  const onDelete = (id, resolve) => {
    const delobj = {
      id: id,
      uname: sessionStorage.getItem("username"),
      tokenId: 123,
    };
    axios.post(url + "php/manager/delete_manager.php", delobj)
      .then((res) => {
        // console.log(res);
        resolve();
      })
      .catch((err) => console.log(err));
  }


  // ############################# For uploadig the Bulk Studnet upload Modal  #############################


  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const onChnage = (e) => {
    let file = e.target.files[0];
    setFile_import(file);
  }

  const UploadFile = () => {
    setLoad(true);
    var uname = sessionStorage.getItem("username");
    var file = new FormData();
    file.append("file", file_import);
    file.append("uname", uname);

    axios.post(url + "php/teachers/exp.php", file)
      .then((res) => {
        // console.log(res)
        if (res.data.success) {
          setOpen(false);
          toast.success("Data inserted successfully");
          loadTeachers();
          setLoad(false);
        } else {
          setOpen(false);
          setLoad(false);
          alert("Data not Inserted")
        }
      })
      .catch((err) => console.log(err));
  }

  // #############################################################################################


  return (
    <>
      {/* ############################################ Modal for Bulk Upload of Students in Database ############################################ */}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Password Dialog</DialogTitle>
        <DialogContent>
          <div className="row d-flex flex-column p-3">
            <div> <p>Click <a href={`${url}import/import-template-teacher.csv`}>here</a>, to download the file format.</p></div>
            <div className="d-flex">
              <input type="file" className="form-control-file" name="profile_pic" onChange={onChnage} />
              {load ? <><div className="spinner-grow spinner-grow-sm text-primary mx-5" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              </> : <Button variant="contained" onClick={UploadFile} disabled={file_import === null ? true : false} className="px-4" startIcon={<CloudUploadIcon />}>Upload</Button>}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* ###################################################################################################################################### */}

      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item active" aria-current="page">Managers list</li>
          </ol>
        </nav>
        <Heading name="Managers List" />

        <div className="container-fluid mb-5">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end ">
              {/* <Button variant="contained" onClick={handleOpen}  className="px-4" startIcon={<CloudUploadIcon />}>Add Teachers in Bulk</Button> */}
              <Button variant="contained" onClick={() => navigate("/add-manager")} >Add Manager <Add /></Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                
                getRowId={(originalRow) => originalRow.manager_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/view-manager/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <IconButton
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          // console.log(selectedRows);
                          const delPromise = selectedRows.map((row) => {
                            return new Promise((resolve, reject) => {
                              onDelete(row.id, resolve);
                            })
                          })
                          Promise.all(delPromise).then(() => {
                            loadTeachers();
                            table.resetRowSelection();
                            toast.error(selectedRows.length + " Row(s) Deleted!")
                          });
                        })
                    }}
                  ><DeleteOutline />
                  </IconButton>
                )}
              />
            </div>
          </div>

        </div>

      </div>

    </>
  )

}
export default ManagerTable




