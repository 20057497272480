import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Switch, Toolbar, Typography } from "@mui/material"
import axios from "axios"
import MaterialReactTable from 'material-react-table'
import { useConfirm } from 'material-ui-confirm'
import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import Heading from "../../../../../Common/Heading"
import StringToTime from "../../../../../Common/StringDateTime"
import { laravelUrl } from "../../../../../Common/URL"

import CloseIcon from '@mui/icons-material/Close'
import { ReadonlyInput } from "../../../../Setup/Student/Component/Input"
import { toast } from "react-toastify"


const StudentDetailsScreenDialog = ({ enqStudId, setOpenStudentDetailsDialog, openStudentDetailsDialog }) => {


    const [loading, setLoading] = useState(true);
    const [tcFeedback, setTcFeedback] = useState("");
    const [pocFeedback, setPocFeedback] = useState("");
    const [studentDetails, setStudentDetails] = useState({});


    // ------------------------Fetching Batch table's data-------------------------------------
    useEffect(() => {
        loadParticularStudentDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // This will fetch details of a particular slot student with the help of enq_stud_id
    const loadParticularStudentDetails = async () => {
        setLoading(true);
        let obj = {
            enq_stud_id: enqStudId
        }
        setOpenStudentDetailsDialog(true);

        await axios.post(laravelUrl + "enquiry_student_details", obj)
            .then((response) => {
                let a = response.data;
                console.log(a);
                a === null ? setStudentDetails({}) : setStudentDetails(a[0]);
                // Below creating separate variable to make state (studentDetails) fast and light, otherwise its lagging
                if (a !== null) {
                    setPocFeedback(a[0].poc_feedback ?? '');
                    setTcFeedback(a[0].tc_feedback ?? '');
                }

                setLoading(false);
            })
            .catch((err) => console.log(err));
    }

    const handleSwitchChange = async (e) => {
        setLoading(true);
        let calledFrom = e.target.name;
        let apiName = calledFrom === 'tc' ? 'edit_tc_data' : 'edit_poc_data';

        if (calledFrom === 'tc') {
            var obj = {
                enq_stud_id: studentDetails.enq_stud_id,
                tc_attended_flag: e.target.checked,
                tc_feedback_flag: studentDetails.tc_feedback_flag,
                tc_feedback: studentDetails.tc_feedback,
            }
        }
        else {
            var obj = {
                enq_stud_id: studentDetails.enq_stud_id,
                poc_attended_flag: e.target.checked,
                poc_feedback_flag: studentDetails.poc_feedback_flag,
                poc_feedback: studentDetails.poc_feedback,
            }
        }

        await axios.patch(laravelUrl + apiName, obj)
            .then((response) => {
                let a = response.data;
                console.log(a);
                loadParticularStudentDetails(enqStudId).then(() => {
                    setLoading(false);
                });
            })
            .catch((err) => console.log(err));

    }

    const handleFeedbackMessage = async (e, feedbackFlag = 1) => {

        setLoading(true);
        let calledFrom = e.target.name;
        let apiName = calledFrom === 'tc' ? 'edit_tc_data' : 'edit_poc_data';

        if (calledFrom === 'tc') {
            var obj = {
                enq_stud_id: studentDetails.enq_stud_id,
                tc_attended_flag: studentDetails.tc_attended_flag,
                tc_feedback_flag: feedbackFlag,
                tc_feedback: feedbackFlag === 1 ? tcFeedback : '',
            }
        }
        else {
            var obj = {
                enq_stud_id: studentDetails.enq_stud_id,
                poc_attended_flag: studentDetails.poc_attended_flag,
                poc_feedback_flag: feedbackFlag,
                poc_feedback: feedbackFlag === 1 ? pocFeedback : '',
            }
        }

        await axios.patch(laravelUrl + apiName, obj)
            .then((response) => {
                let a = response.data;
                console.log(a);
                loadParticularStudentDetails(enqStudId).then(() => {
                    setLoading(false);
                    feedbackFlag === 1 ? toast.success("Feedback updated") : toast.warn("Feedback removed")
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error("Updation failed!")
            });
    }

    return (

        // ------- Dialog to show student details of any particular selected slot------

        <Dialog
            fullScreen
            open={openStudentDetailsDialog}
            onClose={() => setOpenStudentDetailsDialog(false)}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpenStudentDetailsDialog(false)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography className="ms-3" variant="h6">Slot-Student Details</Typography>
                </Toolbar>
            </AppBar>

            <div className="container-fluid">
                <div className="row m-3">
                    {/*-----------------------Column-1 for Inputs------------------------- */}
                    <div className="col-11 col-md-4 mx-auto">

                        <h5 className="mb-4">Student Information</h5>

                        <ReadonlyInput field="First Name" value={studentDetails.student_fname} />
                        <ReadonlyInput field="Last Name" value={studentDetails.student_lname} />
                        <ReadonlyInput field="Age" value={studentDetails.age} />

                    </div>
                    {/*-----------------------Column-2 for Inputs------------------------- */}
                    <div className="col-11 col-md-4 mx-auto">

                        <h5 className="mb-4">Trial Class Information</h5>
                        <ReadonlyInput field="Invite Sent" value={studentDetails.tc_invite_sent_flag === 0 ? 'No' : 'Yes'} />
                        <FormControlLabel
                            className="ms-0 mb-3"
                            // disabled={studentDetails.tc_invite_sent_flag === 0}
                            control={<Switch
                                checked={studentDetails.tc_attended_flag === 1}
                                name='tc'
                                onChange={(event) => handleSwitchChange(event)}
                            />}
                            labelPlacement='start'
                            label={<Typography variant="body2">Trial Class Attended?</Typography>}
                        />

                        <div className="mb-3">
                            <label className="col-form-label">Teacher's Feedback</label>
                            <div className="">
                                <textarea
                                    name="tc"
                                    placeholder="Enter Feedback"
                                    className="form-control"
                                    value={tcFeedback}
                                    onChange={(e) => setTcFeedback(e.target.value)}
                                >
                                </textarea>
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                                <Button variant="contained" color="error" size="small" name="tc" onClick={(e) => handleFeedbackMessage(e, 0)} disabled={loading}>Remove Feedback</Button>
                                <Button variant="contained" size="small" name="tc" onClick={handleFeedbackMessage} disabled={loading}>Update</Button>
                            </div>

                        </div>

                    </div>

                    {/*-----------------------Column-3 for Inputs------------------------- */}
                    <div className="col-11 col-md-4 mx-auto">

                        <h5 className="mb-4">Parent Orientation Information</h5>

                        <ReadonlyInput field="Invite Sent" value={studentDetails.poc_invite_sent_flag === 0 ? 'No' : 'Yes'} />
                        <FormControlLabel
                            className="ms-0 mb-3"
                            // disabled={studentDetails.poc_invite_sent_flag === 0}
                            control={<Switch
                                name="poc"
                                checked={studentDetails.poc_attended_flag === 1}
                                onChange={(event) => handleSwitchChange(event)}
                            />}
                            labelPlacement='start'
                            label={<Typography variant="body1">Orientation Class Attended?</Typography>}
                        />

                        <div className="mb-3">
                            <label className="col-form-label">Teacher's Feedback</label>
                            <div className="">
                                <textarea
                                    name="poc"
                                    placeholder="Enter Feedback"
                                    className="form-control"
                                    value={pocFeedback}
                                    onChange={(e) => setPocFeedback(e.target.value)}
                                >
                                </textarea>
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                                <Button variant="contained" color="error" name="poc" size="small" onClick={(e) => handleFeedbackMessage(e, 0)} disabled={loading}>Remove Feedback</Button>
                                <Button variant="contained" size="small" name="poc" onClick={handleFeedbackMessage} disabled={loading}>Update</Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </Dialog >
        //---------------------------------------------------------------------------- 
    )
}
const MySlotsComp = () => {

    const [slotsLoading, setSlotsLoading] = useState(true);
    const [studentsLoading, setStudentsLoading] = useState(true);

    const [state] = useState({
        uname: sessionStorage.getItem("username"),
        role: sessionStorage.getItem('role')
    })

    // Below loading is used in Student's Details Screen Dialog
    const [enqStudId, setEnqStudId] = useState(null);


    const [openDialog, setOpenDialog] = useState(false);

    const [openStudentDetailsDialog, setOpenStudentDetailsDialog] = useState(false);


    const slotColumns = useMemo(
        () => [
            { header: 'Location', accessorKey: 'location_string' },
            { header: 'Slot Date', accessorKey: 'slot_date' },
            { header: 'Slot Timing', accessorKey: 'slot_time', accessorFn: rowData => StringToTime(rowData.slot_time) },
            { header: 'Slot Type', accessorKey: 'slot_type', accessorFn: rowData => rowData.slot_type === 0 ? 'Trial Class Slot' : 'Parent Orientation Slot' },
            { header: 'Slot Status', accessorKey: 'slot_status', accessorFn: rowData => rowData.slot_status === 1 ? 'Full' : 'Available' },
            ...(state.role !== "Teacher" ? [{ header: 'Assigned To', accessorKey: 'teacher_string' }] : [])
        ],
        [],
    );
    const [slotData, setSlotData] = useState([]);

    const studentsColumns = useMemo(
        () => [
            { header: 'First Name', accessorKey: 'student_fname' },
            { header: 'Last Name', accessorKey: 'student_lname' },
            { header: 'Age', accessorKey: 'age' },
            { header: 'TC Invite Sent', accessorKey: 'tc_invite_sent_flag', accessorFn: rowData => rowData.tc_invite_sent_flag === 0 ? 'No' : 'Yes' },
            { header: 'TC Attended', accessorKey: 'tc_attended_flag', accessorFn: rowData => rowData.tc_attended_flag === 0 ? 'No' : 'Yes' },
            { header: 'POC Invite Sent', accessorKey: 'poc_invite_sent_flag', accessorFn: rowData => rowData.poc_invite_sent_flag === 0 ? 'No' : 'Yes' },
            { header: 'POC Attended', accessorKey: 'poc_attended_flag', accessorFn: rowData => rowData.poc_attended_flag === 0 ? 'No' : 'Yes' },
        ],
        [],
    );
    const [studentsData, setStudentsData] = useState([]);




    // ------------------------Fetching Batch table's data-------------------------------------
    useEffect(() => {
        loadSlots();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // This will fetch Slots according to given role
    const loadSlots = async () => {
        let obj = {
            role: state.role,
            teacher_id: sessionStorage.getItem('id') // this key is used only if role === "Teacher"
        }
        await axios.post(laravelUrl + "fetch_role_based_slots", obj)
            .then((response) => {
                let a = response.data;
                // console.log(a);
                a === null ? setSlotData([]) : setSlotData(a);
                setSlotsLoading(false)
            })
            .catch((err) => console.log(err));
    }

    // This will fetch all the students related to a particular slot_id
    const loadSlotStudents = async (id) => {
        setSlotsLoading(true);
        let obj = {
            slot_id: id
        }

        await axios.post(laravelUrl + "slot_students", obj)
            .then((response) => {
                let a = response.data;
                // console.log(a);
                a === null ? setStudentsData([]) : setStudentsData(a);
                setSlotsLoading(false);
                setStudentsLoading(false);
                setOpenDialog(true);
            })
            .catch((err) => console.log(err));
    }


    return (
        <>
            {/* ----------------------------------------------------------------------------- */}

            {openStudentDetailsDialog &&
                <StudentDetailsScreenDialog
                    openStudentDetailsDialog={openStudentDetailsDialog}
                    setOpenStudentDetailsDialog={setOpenStudentDetailsDialog}
                    enqStudId={enqStudId}
                />
            }
            {/* ------- Dialog to show All Students in a Particular selected slot ------ */}
            <Dialog
                fullWidth={true}
                maxWidth="lg"
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle id="alert-dialog-title">STUDENTS ASSIGNED TO THIS SLOT</DialogTitle>
                <DialogContent>
                    <div className="row ">
                        <div className="col-12 mx-auto">
                            <MaterialReactTable
                                state={{ isLoading: studentsLoading }}
                                columns={studentsColumns}
                                data={studentsData}
                                initialState={{ density: 'compact' }}
                                enableDensityToggle={false}
                                getRowId={
                                    (originalRow) => originalRow.enq_stud_id
                                }
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => {
                                        setEnqStudId(row.id);
                                        // loadParticularStudentDetails(row.id)

                                        setOpenStudentDetailsDialog(true);
                                    },
                                    sx: { cursor: 'pointer' },
                                })}
                            />
                        </div>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">Close</Button>
                </DialogActions>
            </Dialog>


            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">CRM</li>
                        <li className="breadcrumb-item active" aria-current="page">My Slots</li>
                    </ol>
                </nav>

                <Heading name="My Slots" />

                <div className="container-fluid mb-5">

                    <div className="row ">
                        <div className="col-12 mx-auto">
                            <MaterialReactTable
                                state={{ isLoading: slotsLoading }}
                                columns={slotColumns}
                                data={slotData}
                                initialState={{ density: 'compact' }}
                                enableDensityToggle={false}
                                getRowId={(originalRow) => originalRow.slot_id}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => loadSlotStudents(row.id),
                                    sx: { cursor: 'pointer' },
                                })}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default MySlotsComp

