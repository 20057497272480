import React, { useState, useEffect } from "react"
// import { useNavigate} from "react-router-dom"
import { ReadonlyInput } from "../../../Tabs/Setup/Student/Component/Input"
import axios from "axios"
import url from "../../../Common/URL"


const ManagerDetailProfileComp = () => {
    // const navigate = useNavigate();

    // ################################################ Below Data for 1st Tab ########################################################

    const [field, setField] = useState({

        manager_id: "",
        // ------------normal input fields--------------
        fname: "", mname: "", lname: "", city: "", zip: "", mobile_number: "", home_number: "", email: "",
        join_date: "", leave_date: "", registration_number: "", kvk_number: "",
        contract_period: "", contract_renewal_date: "", probation_period: "",
        // ------------Textarea input fields--------------
        address_line1: "", address_line2: "",
        // ------------Checkbox input fields--------------
        active: false, photo_consent: false, disclaimer_signed: false,
        // ------------Select inputs fields--------------
        province: "", country: "", location_name: "", batch_name: [], level_name: [], user_name: "",
        picture: null
    })

    useEffect(() => {
        ManagerDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ------------------------------
    const ManagerDetail = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: sessionStorage.getItem("id")
        }
        axios.post(url + "php/manager/select_manager.php", obj)
            .then((response) => {
                // console.log(response);
                let resData = JSON.parse(response.data.data);
                // console.log(resData);
                setField({
                    fname: resData[0].fname, mname: resData[0].mname, lname: resData[0].lname, city: resData[0].city,
                    zip: resData[0].zip, mobile_number: resData[0].mobile_number, home_number: resData[0].home_number,
                    email: resData[0].email, join_date: resData[0].join_date, leave_date: resData[0].leave_date,
                    manager_id: resData[0].manager_id,
                    probation_period: resData[0].probation_period, contract_renewal_date: resData[0].contract_renewal_date,
                    contract_period: resData[0].contract_period, kvk_number: resData[0].kvk_number,
                    // ------------Textarea input fields--------------
                    address_line1: resData[0].address_line1, address_line2: resData[0].address_line2,
                    // ------------Checkbox input fields--------------
                    active: resData[0].active, photo_consent: resData[0].photo_consent, disclaimer_signed: resData[0].disclaimer_signed,
                    // ------------Select inputs fields--------------
                    province: resData[0].province, country: resData[0].country, user_name: resData[0].user_name,
                    location_name: resData[0].location_name,

                })

            })
            .catch((err) => console.log(err));
    }

    return (
        <div className="container-fluid">
            {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}

            <div className="row mb-5">
                {/*-----------------------Column-1 for Inputs------------------------- */}
                <div className="col-11 col-md-6 mx-auto">

                    <h5 className="mb-4">Personal Information</h5>


                    {/* <Input field="Profile Picture" type="file" className="form-control-file" name="picture" onChange={handleInputChange} value={field.picture}/> */}

                    {/* <div className="mb-3 row">
    <label className="col-sm-5 col-form-label">Teacher ID</label>
    <div className="col-sm-7 col-md-7 ">
        <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={field.manager_id} />
    </div>
    </div> */}

                    <ReadonlyInput field="First Name" name="fname" value={field.fname} />

                    {/* <ReadonlyInput field="Middle Name"   name="mname" value={field.mname}/> */}

                    <ReadonlyInput field="Last Name" name="lname" value={field.lname} />

                    <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Address Line 1</label>
                        <div className="col-sm-7 col-md-7 ">
                            <textarea readOnly className="form-control bg-light border-0" name="address_line1" placeholder="--" value={field.address_line1}  ></textarea>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Address Line 2</label>
                        <div className="col-sm-7 col-md-7 ">
                            <textarea readOnly className="form-control bg-light border-0" name="address_line2" placeholder="--" value={field.address_line2} ></textarea>
                        </div>
                    </div>

                    <ReadonlyInput field="City" name="city" value={field.city} />


                    {/* ---Select Input---- */}
                    <ReadonlyInput field="Country" className="form-control bg-light border-0 " value={field.country} />


                    {/* ---Select Input---- */}
                    <ReadonlyInput field="Province" className="form-control bg-light border-0 " value={field.province} />

                    <ReadonlyInput field="Zip" name="zip" value={field.zip} />

                    <ReadonlyInput field="Mobile Number" name="mobile_number" value={field.mobile_number} />

                    <ReadonlyInput field="Home Phone" name="home_number" value={field.home_number} />

                    <ReadonlyInput field="Email" type="email" placeholder="--" name="email" value={field.email} />

                    <ReadonlyInput field="KVK Number" name="kvk_number" value={field.kvk_number} />

                    {/* <div className="mb-3 row">
    <label className="col-sm-5 col-form-label">Contract Document</label>
    <div className="col-sm-7 col-md-7 d-flex align-items-center">
        <a href="#" download >View Contract Document &nbsp;<i className="fa fa-download"></i></a>
    </div>
    </div> */}

                </div>

                {/*-----------------------Column-2 for Inputs------------------------- */}
                <div className="col-11 col-md-6 mx-auto">

                    <h5 className="mb-4">Location Information</h5>


                    <ReadonlyInput field="Location Name" name="location_name" value={field.location_name} />

                    {/* <div className="mb-3 row">
                <label className="col-sm-4 col-form-label">Batch Name</label>
                <div className="col-sm-7 col-md-8 ">
                    <textarea readOnly className="form-control bg-light border-0" rows="3" name="batch_name"  placeholder="--" value={field.batch_name}  ></textarea>
                </div>
                </div>

                <div className="mb-3 row">
                <label className="col-sm-4 col-form-label">Level Name</label>
                <div className="col-sm-7 col-md-8 ">
                    <textarea readOnly className="form-control bg-light border-0" rows="3" name="level_name"  placeholder="--" value={field.level_name}  ></textarea>
                </div>
                </div> */}

                    <h5 className="mt-5 mb-4">Account Information</h5>


                    {/* ---Select Input---- */}
                    <ReadonlyInput field="User" className="form-control bg-light border-0 " value={field.user_name} />


                    <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Active Account</label>
                        <div className="col-sm-7 col-md-7 ps-4">
                            <div className="form-check">
                                <input readOnly className="form-check-input" type="checkbox" name="active" checked={(field.active === "1" || field.active === "true") ? true : false} />
                            </div>
                        </div>
                    </div>

                    {/* <div className="mb-3 row">
                <label className="col-sm-5 col-form-label">Photography Consent Provide?</label>
                <div className="col-sm-7 col-md-7 ps-4">
                <div className="form-check">
                        <input readOnly className="form-check-input" type="checkbox" name="photo_consent" checked={(field.photo_consent === "1" || field.photo_consent === "true") ? true : false} />
                    </div>
                </div>
                </div>
                <div className="mb-3 row">
                <label className="col-sm-5 col-form-label">Disclaimer Signed?</label>
                <div className="col-sm-7 col-md-7 ps-4">
                <div className="form-check">
                        <input readOnly className="form-check-input" type="checkbox" name="disclaimer_signed" checked={(field.disclaimer_signed === "1" || field.disclaimer_signed === "true") ? true : false} />
                    </div>
                </div>
                </div> */}

                    <ReadonlyInput name="join_date" field="Join Date" type="date" placeholder="--" value={field.join_date} />

                    <ReadonlyInput name="probation_period" value={field.probation_period} field="Probation Period" type="number" placeholder="In Months" />

                    <ReadonlyInput name="contract_renewal_date" value={field.contract_renewal_date} field="Contract Renewal Date" type="date" placeholder="--" />

                    <ReadonlyInput name="contract_period" value={field.contract_period} field="Contract Period" type="number" placeholder="In Months" />

                    <ReadonlyInput name="leave_date" value={field.leave_date} field="Leave Date" type="date" placeholder="--" />


                </div>
            </div>

        </div>
    )
}

export default ManagerDetailProfileComp

