import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Input, { ReadonlyInput } from "../../../Setup/Student/Component/Input"
import Heading from "../../../../Common/Heading"
import axios from "axios";
import url from "../../../../Common/URL"
import { Button } from "@mui/material";
import { useSelector } from "react-redux"


const AddActTransComp = () => {

    let navigate = useNavigate();
    const [stu, setStu] = useState([]);
    const [loading, setLoading] = useState(false);
    const [invItem, setInvItem] = useState([]);

    const transaction_option = [
        { name: "Sell" }, { name: "Refund" },
    ];

    const [state, setState] = useState({
        stud_id: "", acc_trans_date: "", fname: "", lname: "", item_name: "",
        type: "", inventory_id: "", stu_uname: "", price: ""
    })


    useEffect(() => {
        loadStudents();
        loadInventoryItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Students--------------------
    const loadStudents = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "php/students/students.php", obj)
            .then((response) => {
                let a = JSON.parse(response.data.data);
                //   console.log(a);
                setStu(a);
            })
            .catch((err) => console.log(err));
    }
    // -----------This function will Fetch all the Existing Inventory Items--------------------

    const loadInventoryItems = () => {
        const obj = { uname: sessionStorage.getItem("username") };

        axios.post(url + "php/inventory/inventory.php", obj)
            .then((response) => {
                // console.log(response)
                if (response.data.success) {
                    let a = JSON.parse(response.data.data);
                    // console.log(a);
                    a !== null ? setInvItem(a.filter(x => x.status === "Active")) : setInvItem([]);
                } else {
                    setInvItem([]);
                }
            }).catch((err) => console.log(err));
    }
    //---------------One onChange function is used for setting the state of various types of input fields ---------------     

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }
    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        // ------------Storing form data in "obj" object fetched from various input fields-------------
        const obj = {
            uname: sessionStorage.getItem("username"), stud_id: state.stud_id,
            acc_trans_date: state.acc_trans_date, price: state.price, item_name: state.item_name,
            fname: state.fname, lname: state.lname, user_name: state.stu_uname,
            type: state.type, inventory_id: "", created_by: sessionStorage.getItem("username"),
            creator_fname: sessionStorage.getItem("userFname"), creator_lname: sessionStorage.getItem("userLname")
        }
        // ------------Sending form data stored in "obj" object to the add_student.php file uusing AXIOS-------------

        // console.log(obj);
        axios.post(url + "php/accounts_transaction/add_acc_trans.php", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("New accounts transaction created successfully");
                    navigate("/act-trans");
                } else {
                    alert("Unable to create accounts transaction")
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })

    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'} >
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Accounts</li>
                        <li className="breadcrumb-item "><Link to="/act-trans">Accounts Transactions</Link></li>
                        <li className="breadcrumb-item active">Add Transaction</li>
                    </ol>
                </nav>
                <Heading name="Add Transaction" />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                <form onSubmit={onSubmit} id="myform">
                    <div className="container-fluid mt-3">
                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-end mx-auto">
                                <Button variant="contained" disabled={loading} type="submit" >Save</Button>
                            </div>
                        </div>

                        <div className="row">
                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto mx-md-0">

                                <h5 className="mb-4">Transaction Information</h5>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Select Item</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="item_name" value={`${state.item_name}^${state.price}`}
                                            onChange={(e) => {
                                                let data = e.target.value;
                                                setState({ ...state, price: data.split("^")[1], item_name: data.split("^")[0] })
                                            }}
                                            className="form-select">
                                            <option value="" >Choose</option>
                                            {invItem.map((data, i) => (<option key={i} value={`${data.product_name}^${data.price}`}>{data.product_name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Student</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="stud_id" value={`${state.stud_id}#${state.fname}#${state.lname}#${state.stu_uname}`}
                                            onChange={(e) => {
                                                let val = e.target.value;
                                                let id = val.split("#")[0];
                                                let fn = val.split("#")[1];
                                                let ln = val.split("#")[2];
                                                let un = val.split("#")[3];
                                                setState({ ...state, stud_id: id, fname: fn, lname: ln, stu_uname: un });
                                            }}
                                            className="form-select">
                                            <option value="" >Choose</option>
                                            {stu.map((data, i) => (<option key={i} value={`${data.stud_id}#${data.fname}#${data.lname}#${data.user_name}`}>{`${data.fname} ${data.lname}`}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Transaction Type</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="type" value={state.type} onChange={onChange} className="form-select">
                                            <option value="" >Choose</option>
                                            {transaction_option.map((x, i) => <option key={i} value={x.name}>{x.name}</option>)}
                                        </select>
                                    </div>
                                </div>

                                <ReadonlyInput field="Price" type="text"  value={state.price} />

                                <Input name="acc_trans_date" onChange={onChange} field="Transaction Date" type="date" className="form-control" value={state.acc_trans_date} required={true} />

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default AddActTransComp