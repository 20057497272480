import React, { useState, useEffect } from "react"
import Input from "../../Student/Component/Input"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Heading from "../../../../Common/Heading"
import axios from "axios"
import url from "../../../../Common/URL"
import { Button } from "@mui/material";
import { useSelector } from "react-redux"


const EditQuestionComp = () => {
    let message = "";
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const [loc , setLoc] =useState([]);
    const [lev, setLev] = useState([]);

    const { id } = useParams();

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"),
        ques_num1: "", ques_num2: "", ques_num3: "", ques_num4: "",
        ques_num5: "", ques_num6: "", ques_num7: "", answer: "", level_name: "",
        location_name: "", category_name: "", ques_id: ""
    })

    useEffect(() => {
        Questiondetails();
        // loadLocation();
        loadLevel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // -----------This function will Fetch all the Existing Locations--------------------
    // const loadLocation =  () => {
    //     const obj = {
    //       uname: sessionStorage.getItem("username"),
    //     };

    //      axios.post(url+"php/locations/locations.php", obj)
    //     .then( (response) => {
    //       let a = JSON.parse(response.data.data);
    //       console.log(a);
    //       setLoc(a);
    //     })
    //     .catch( (err) => console.log(err) );
    //     }

    // -----------This function will Fetch all the Existing Levels--------------------
    const loadLevel = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "php/levels/levels.php", obj)
            .then((response) => {
                let a = JSON.parse(response.data.data);
                //   console.log(a);
                setLev(a);
            })
            .catch((err) => console.log(err));
    }

    // ------------------------------
    const Questiondetails = () => {
        const obj = {
            uname: state.uname,
            id: id
        }
        axios.post(url + "php/questions/select_questions.php", obj)
            .then((response) => {
                let resData = JSON.parse(response.data.data);
                // console.log(response);
                setState({
                    ques_num1: resData[0].ques_num1, ques_num2: resData[0].ques_num2, ques_num3: resData[0].ques_num3,
                    ques_num4: resData[0].ques_num4, ques_num5: resData[0].ques_num5, ques_num6: resData[0].ques_num6,
                    ques_num7: resData[0].ques_num7, answer: resData[0].answer,
                    level_name: resData[0].level_name, location_name: resData[0].location_name,
                    category_name: resData[0].category_name, ques_id: resData[0].ques_id,

                })
            })
            .catch((err) => console.log(err));
    }
    //---------------One onChange function is used for setting the state of various types of input fields ---------------     
    const onChange = (e) => {

        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }
    const onSubmit = (e) => {

        e.preventDefault();
        setLoading(true);

        /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;

        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            updated_by: state.uname, updated_date: newdate, uname: sessionStorage.getItem("username"), id: id,
            ques_num1: state.ques_num1, ques_num2: state.ques_num2, ques_num3: state.ques_num3, ques_num4: state.ques_num4,
            ques_num5: state.ques_num5, ques_num6: state.ques_num6, ques_num7: state.ques_num7, answer: state.answer,
            level_name: state.level_name, location_name: state.location_name, category_name: state.category_name,
        }
        axios.post(url + "php/questions/edit_question.php", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("Updated successfully!")
                    Questiondetails();
                } else {
                    message = res.data.message;
                    alert(message)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })
    }
    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item "><Link to="/question">Questions</Link></li>
                        <li className="breadcrumb-item active">Add Questions</li>
                    </ol>
                </nav>
                <Heading name={`Edit Question - ID  ${id}`} />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                <form onSubmit={onSubmit}>
                    <div className="container-fluid mt-3">
                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-end mx-auto">
                                <Button variant="contained" onClick={() => navigate("/view-question/" + state.ques_id)} className="me-3 ">Back</Button>
                                <Button variant="contained" disabled={loading} type="submit" >Save</Button>
                            </div>
                        </div>

                        <div className="row">

                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Edit Question</h5>

                                {/* <div className="mb-3 row">
                            <label className="col-sm-5 col-form-label">Quesdtion ID</label>
                            <div className="col-sm-7 col-md-6 ">
                            <input type="text" readonly className="form-control-plaintext" value="<Auto generated>"  />
                            </div>
                        </div> */}


                                <Input field="Number 1" name="ques_num1" type="text" placeholder="Enter Number" className="form-control" onChange={onChange} value={state.ques_num1} />



                                <Input field="Number 2" name="ques_num2" type="text" placeholder="Enter Number" className="form-control" onChange={onChange} value={state.ques_num2} />



                                <Input field="Number 3" name="ques_num3" type="text" placeholder="Enter Number" className="form-control" onChange={onChange} value={state.ques_num3} />



                                <Input field="Number 4" name="ques_num4" type="text" placeholder="Enter Number" className="form-control" onChange={onChange} value={state.ques_num4} />



                                <Input field="Number 5" name="ques_num5" type="text" placeholder="Enter Number" className="form-control" onChange={onChange} value={state.ques_num5} />



                                <Input field="Number 6" name="ques_num6" type="text" placeholder="Enter Number" className="form-control" onChange={onChange} value={state.ques_num6} />



                                <Input field="Number 7" name="ques_num7" type="text" placeholder="Enter Number" className="form-control" onChange={onChange} value={state.ques_num7} /><br />

                                <Input field="Answer" name="answer" type="text" placeholder="Enter Answer" className="form-control" onChange={onChange} value={state.answer} />
                            </div>

                            {/*-----------------------Column-2 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">
                                <h5 className="mb-4">Question Infotmation</h5>
                                {/* <div className="mb-3 row">
                            <label className="col-sm-5 col-form-label">Location</label>
                            <div className="col-sm-7 col-md-7 ">
                                <select className="form-select" name="location_name" onChange={onChange} value={state.location_name}>
                                    <option selected>Choose</option>
                                    {loc.map((data , i)=>(<option key={i} value={data.name}>{data.name}</option>)) } 
                                    </select>
                            </div>
                        </div> */}
                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Level</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select className="form-select" name="level_name" onChange={onChange} value={state.level_name}>
                                            {lev.map((data, i) => (<option key={i} value={data.level_name}>{data.level_name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Category</label>
                                    <div className="col-sm-7 col-md-7 align-items-center d-flex ">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio"
                                                name="category_name"
                                                id="inlineRadio1"
                                                value="Mind Math"
                                                checked={state.category_name === "Mind Math"}
                                                onChange={onChange} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Mind Math</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio"
                                                name="category_name"
                                                id="inlineRadio2"
                                                value="Abacus"
                                                checked={state.category_name === "Abacus"}
                                                onChange={onChange} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">Abacus</label>
                                        </div>
                                    </div>
                                </div>
                                {/*     <div className="mb-3 row">
                                <label className="col-sm-5 col-md-3 col-form-label">Test 1</label>
                                <div className="col-sm-7 col-md-6 mt-2 ">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="invalidCheck2"  />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-5 col-md-3 col-form-label">Test 2</label>
                                <div className="col-sm-7 col-md-6 mt-2 ">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="invalidCheck2"  />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-5 col-md-3 col-form-label">Test 3</label>
                                <div className="col-sm-7 col-md-6 mt-2 ">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="invalidCheck2"  />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-5 col-md-3 col-form-label">Test 4</label>
                                <div className="col-sm-7 col-md-6 mt-2 ">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="invalidCheck2"  />
                                    </div>
                                </div>
                            </div> */}

                            </div>

                        </div>
                    </div>
                </form>

            </div>

        </>
    )
}

export default EditQuestionComp