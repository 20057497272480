import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ReadonlyInput } from "../../../Tabs/Setup/Student/Component/Input"
import axios from "axios"
import url from "../../../Common/URL"
import { Button } from "@mui/material";


const TeacherDetailProfileComp = () => {
    const navigate = useNavigate();

    // ################################################Below Data for 1st Tab ########################################################

    const [field, setField] = useState({


        teacher_id: "",
        // ------------normal input fields--------------
        fname: "", mname: "", lname: "", city: "", zip: "", mobile_no: "", home_no: "", email: "",
        parent_fname: "", parent_lname: "", parent_mobile_no: "", parent_email: "", parent_mobile_no_2: "",
        parent_email_2: "", join_date: "", leave_date: "", registration_number: "", approved_date: "", kvk_number: "",
        contract_period: "", contract_renewal_date: "", probation_period: "",
        // ------------Textarea input fields--------------
        addressline1: "", addressline2: "",

        // ------------Checkbox input fields--------------
        active: false, photo_consent: false, disclaimer_signed: false,

        // ------------Select inputs fields--------------
        state: "", country: "", location_name: [], batch_name: [], level_name: [], user_name: "",
        picture: null
    })

    useEffect(() => {
        TeacherDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ------------------------------
    const TeacherDetail = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: sessionStorage.getItem("id")
        }
        axios.post(url + "php/teachers/select_teacher.php", obj)
            .then((response) => {
                let resData = JSON.parse(response.data.data);
                // console.log(resData);
                setField({
                    fname: resData[0].fname, mname: resData[0].mname, lname: resData[0].lname, city: resData[0].city,
                    zip: resData[0].zip, mobile_no: resData[0].mobile_no, home_no: resData[0].home_no,
                    email: resData[0].email, parent_fname: resData[0].parent_fname, parent_lname: resData[0].parent_lname,
                    parent_mobile_no: resData[0].parent_mobile_no, parent_mobile_no_2: resData[0].parent_mobile_no_2,
                    parent_email: resData[0].parent_email, join_date: resData[0].join_date, leave_date: resData[0].leave_date,
                    parent_email_2: resData[0].parent_email_2, registration_number: resData[0].registration_number,
                    teacher_id: resData[0].teacher_id, approved_date: resData[0].approved_date,
                    probation_period: resData[0].probation_period, contract_renewal_date: resData[0].contract_renewal_date,
                    contract_period: resData[0].contract_period, kvk_number: resData[0].kvk_number,
                    // ------------Textarea input fields--------------
                    addressline1: resData[0].addressline1, addressline2: resData[0].addressline2,

                    // ------------Checkbox input fields--------------
                    active: resData[0].active, photo_consent: resData[0].photo_consent, disclaimer_signed: resData[0].disclaimer_signed,

                    // ------------Select inputs fields--------------
                    state: resData[0].state, country: resData[0].country, user_name: resData[0].user_name,

                })

                axios.post(url + "php/teachers/select_teacher_params.php", { uname: resData[0].user_name })
                    .then(res => {
                        // console.log(res);
                        if (res.data.success) {
                            var batch_name_arr = JSON.parse(res.data.batch_data).map(a => a.batch_name);
                            var location_name_arr = JSON.parse(res.data.location_data).map(a => a.location_name);
                            var level_name_arr = JSON.parse(res.data.level_data).map(a => a.level_name);

                            // console.log(batch_name_arr);console.log(location_name_arr);console.log(level_name_arr);
                            setField(pre => {
                                return {
                                    ...pre, location_name: location_name_arr.join(",\n"), batch_name: batch_name_arr.join(",\n"),
                                    level_name: level_name_arr.join(",\n"),
                                }
                            })

                        } else {
                            alert("An error occurred, Location, Level & Batch can't be fetched.")
                        }
                    }).catch(res => console.log(res))

            })
            .catch((err) => console.log(err));
    }

    return (
        <div className="container-fluid">
            {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
            <div className="row">
                <div className="col-md-12 text-end">
                    <Button variant="contained" onClick={() => navigate("/compose")} >Contact Student</Button>
                </div>

            </div>
            <div className="row mb-5">
                <div className="col-11 col-md-6 mx-auto">

                    <h5 className="mb-4">Personal Information</h5>


                    {/* <Input field="Profile Picture" type="file" className="form-control-file" name="picture" onChange={handleInputChange} value={field.picture}/> */}

                    {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Teacher ID</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={field.teacher_id} />
                                </div>
                            </div> */}

                    <ReadonlyInput field="First Name" name="fname" value={field.fname} />

                    {/* <ReadonlyInput field="Middle Name"   name="mname" value={field.mname}/> */}

                    <ReadonlyInput field="Last Name" name="lname" value={field.lname} />

                    <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Address Line 1</label>
                        <div className="col-sm-7 col-md-7 ">
                            <textarea readOnly className="form-control bg-light border-0 " name="addressline1" placeholder="--" value={field.addressline1}  ></textarea>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Address Line 2</label>
                        <div className="col-sm-7 col-md-7 ">
                            <textarea readOnly className="form-control bg-light border-0 " name="addressline2" placeholder="--" value={field.addressline2} ></textarea>
                        </div>
                    </div>

                    <ReadonlyInput field="City" name="city" value={field.city} />


                    {/* ---Select Input---- */}
                    <ReadonlyInput field="Country" className="form-control bg-light border-0 " value={field.country} />

                    {/* ---Select Input---- */}
                    <ReadonlyInput field="Province" className="form-control bg-light border-0 " value={field.state} />

                    <ReadonlyInput field="Zip" name="zip" value={field.zip} />

                    <ReadonlyInput field="Mobile Number" name="mobile_no" value={field.mobile_no} />

                    <ReadonlyInput field="Home Phone" name="home_no" value={field.home_no} />

                    <ReadonlyInput field="Email" type="email" placeholder="--" name="email" value={field.email} />

                    <ReadonlyInput field="KVK Number" name="kvk_number" value={field.kvk_number} />

                    {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Contract Document</label>
                                <div className="col-sm-7 col-md-7 d-flex align-items-center">
                                    <a href="#" download >View Contract Document &nbsp;<i className="fa fa-download"></i></a>
                                </div>
                            </div> */}

                </div>

                {/*-----------------------Column-2 for Inputs------------------------- */}
                <div className="col-11 col-md-6 mx-auto">

                    <h5 className="mb-4">Batch Information</h5>


                    <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label">Location Name</label>
                        <div className="col-sm-7 col-md-8 ">
                            <textarea readOnly className="form-control bg-light border-0 " rows="3" name="location_name" placeholder="--" value={field.location_name}  ></textarea>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label">Batch Name</label>
                        <div className="col-sm-7 col-md-8 ">
                            <textarea readOnly className="form-control bg-light border-0 " rows="3" name="batch_name" placeholder="--" value={field.batch_name}  ></textarea>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label">Level Name</label>
                        <div className="col-sm-7 col-md-8 ">
                            <textarea readOnly className="form-control bg-light border-0 " rows="3" name="level_name" placeholder="--" value={field.level_name}  ></textarea>
                        </div>
                    </div>

                    <h5 className="mt-5 mb-4">Account Information</h5>


                    {/* ---Select Input---- */}
                    {/* <ReadonlyInput field="User"  className="form-control bg-light border-0 "  value={field.user_name}/> */}


                    <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Active Account</label>
                        <div className="col-sm-7 col-md-7 ps-4">
                            <div className="form-check">
                                <input readOnly className="form-check-input" type="checkbox" name="active" checked={field.active === "true" || field.active === "1" ? true : false} />
                            </div>
                        </div>
                    </div>

                    {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Photography Consent Provide?</label>
                                <div className="col-sm-7 col-md-7 ps-4">
                                <div className="form-check">
                                        <input readOnly className="form-check-input" type="checkbox" name="photo_consent" checked={field.photo_consent === "true" || field.photo_consent === "1"? true :false} />
                                    </div>
                                </div>
                            </div> */}
                    {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Disclaimer Signed?</label>
                                <div className="col-sm-7 col-md-7 ps-4">
                                <div className="form-check">
                                        <input readOnly className="form-check-input" type="checkbox" name="disclaimer_signed" checked={field.disclaimer_signed === "true" || field.disclaimer_signed === "1"? true :false} />
                                    </div>
                                </div>
                            </div> */}

                    <ReadonlyInput name="join_date" field="Join Date" type="date" placeholder="--" value={field.join_date} />

                    <ReadonlyInput name="probation_period" value={field.probation_period} field="Probation Period" type="number" placeholder="In Months" />

                    <ReadonlyInput name="contract_renewal_date" value={field.contract_renewal_date} field="Contract Renewal Date" type="date" placeholder="--" />

                    <ReadonlyInput name="contract_period" value={field.contract_period} field="Contract Period" type="number" placeholder="In Months" />

                    <ReadonlyInput name="leave_date" value={field.leave_date} field="Leave Date" type="date" placeholder="--" />


                </div>
            </div>

        </div>
    )
}

export default TeacherDetailProfileComp

