import React, { useState, useEffect } from "react"
import { Link, useParams, useNavigate, useLocation } from "react-router-dom"
// import {toast} from "react-toastify"
import Heading from "../../../../Common/Heading"
import { ReadonlyInput } from "../../../Setup/Student/Component/Input"
import axios from "axios"
import url from "../../../../Common/URL"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button } from "@mui/material"
import { toast } from "react-toastify"
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { useSelector } from "react-redux"


const ViewTestHistoryComp = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [history_url, setHistory_url] = useState({
        from: "", id: ""
    });

    const [attachment, setAttachment] = useState([]);
    const [open, setOpen] = useState(false);  // To open the popup , for adding or editing  the Teacher's comment 
    const [backdrop, setBackdrop] = useState(false);

    const [data, setData] = useState({
        comment: "", approved: ""
    })
    useEffect(() => {
        if (location.state === undefined) {
            // nothing to do here, this condition will occur when we come form viewReportComp page
        }
        else if (location.state.from === "StudentDetailPage") {
            setHistory_url({ from: "StudentDetailPage", id: location.state.id })
        } else {
            setHistory_url({ from: "TestHistoryPage", id: "" })
        }
    }, [])

    const { id } = useParams();

    const [field, setField] = useState({
        approved: "", batch_name: "", location_name: "", test_id: "",
        attempted_percentage: "", created_by: "", created_date: "", level_name: "", test_level: "",
        not_ans: "", overall_percentage: "", ques_id: "", score: "", fname: "", lname: "",
        test_name: "", test_type: "", time_taken_abacus: "", time_taken_mm: "",
        user_ans_id: "", user_answer: "", wrong_ans: "",
        accuracy_ab: '', accuracy_mm: "", accuracy_per_ab: "", accuracy_per_mm: "",
        teacher_comment: "", total_accuracy_per: "", total_attempted_ab: "", total_attempted_mm: "",
        total_attempted_per_ab: "", total_attempted_per_mm: "", test_mode: ""
    })

    const loadScores = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        }
        axios.post(url + "php/questions/view_score.php", obj)
            .then((response) => {
                let resData = JSON.parse(response.data.data);
                console.log(resData);
                setField({
                    approved: resData[0].approved, batch_name: resData[0].batch_name, location_name: resData[0].location_name,
                    test_id: resData[0].test_id, test_mode: resData[0].test_mode, test_level: resData[0].test_level,
                    created_by: resData[0].created_by, created_date: resData[0].created_date,
                    level_name: resData[0].level_name, not_ans: resData[0].not_ans,
                    overall_percentage: parseFloat(resData[0].overall_percentage),
                    ques_id: resData[0].ques_id, score: resData[0].score, test_name: resData[0].test_name,
                    test_type: resData[0].test_type, fname: resData[0].fname, lname: resData[0].lname,
                    time_taken_abacus: resData[0].time_taken_abacus, time_taken_mm: resData[0].time_taken_mm,
                    user_ans_id: resData[0].user_ans_id, user_answer: resData[0].user_answer, wrong_ans: resData[0].wrong_ans,
                    attempted_percentage: parseFloat(resData[0].attempted_percentage),
                    accuracy_ab: parseFloat(resData[0].accuracy_ab),
                    accuracy_mm: parseFloat(resData[0].accuracy_mm),
                    accuracy_per_ab: parseFloat(resData[0].accuracy_per_ab),
                    accuracy_per_mm: parseFloat(resData[0].accuracy_per_mm),
                    teacher_comment: resData[0].teacher_comment,
                    total_accuracy_per: parseFloat(resData[0].total_accuracy_per),
                    total_attempted_ab: resData[0].total_attempted_ab, total_attempted_mm: resData[0].total_attempted_mm,
                    total_attempted_per_ab: parseFloat(resData[0].total_attempted_per_ab),
                    total_attempted_per_mm: parseFloat(resData[0].total_attempted_per_mm)
                })
                LoadAttachments(resData[0].test_id, resData[0].test_name, resData[0].user_name, resData[0].created_date)
                setData({
                    comment: resData[0].teacher_comment, approved: resData[0].approved
                })
            })
            .catch((err) => console.log(err));

    }
    //   ###########################################################

    const LoadAttachments = (id, t_name, stu_name, date) => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            test_id: id,
            test_name: t_name,
            user_name: stu_name,
            created_date: date
        }
        axios.post(url + "php/students/select_stud_attachment.php", obj)
            .then((response) => {
                // console.log(response)
                if (response.data.success) {
                    let a = JSON.parse(response.data.data);
                    // console.log(a);
                    if (a !== null) {
                        setAttachment(a);
                    } else setAttachment([]);
                } else {
                    setAttachment([]);
                }
            })
            .catch((err) => {
                console.log(err);
                alert("Error occurred, Try again later.")
            })
    }

    //   ###########################################################
    useEffect(() => {
        loadScores()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = (e) => {
        const input = e.target;
        const value = input.value;
        const name = input.name;
        setData({ ...data, [name]: value })
    }

    const OnCommentSubmit = () => {
        const obj = {
            id: field.user_ans_id, uname: sessionStorage.getItem("username"), role: sessionStorage.getItem("role"),
            teacher_comment: data.comment, approved: data.approved
        }
        axios.post(url + "php/questions/edit_score.php", obj)
            .then(res => {
                // console.log(res);
                if (res.data.success) {
                    toast.success("Comment uploaded successfully");
                    loadScores();
                } else {
                    alert("An Error occurred, please try again later.")
                }
                setOpen(false)
            })
            .catch(res => console.log(res))
    }

    const onApproveSubmit = (e) => {
        setBackdrop(true);
        var isChecked = e.target.checked;
        const obj = {
            id: field.user_ans_id, uname: sessionStorage.getItem("username"), role: sessionStorage.getItem("role"),
            teacher_comment: data.comment, approved: isChecked
        }

        axios.post(url + "php/questions/edit_score.php", obj)
            .then(res => {
                // console.log(res);
                if (res.data.success) {
                    isChecked ? toast.success("Comment Approved successfully") : toast.error("Comment Refuced");
                    loadScores();
                } else {
                    alert("An Error occurred, please try again later.")
                }
                setBackdrop(false);
            })
            .catch(res => console.log(res))
    }

    return (
        <>

            {/* ############################################ Dialog for Uploading  Teacher's comment ################################################## */}
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={() => setOpen(false)}>
                <DialogTitle id="max-width-dialog-title">Teacher's Comment</DialogTitle>
                <DialogContent>
                    <div className="mb-3">
                        <label className=" col-form-label">Comment</label>
                        <div >
                            <textarea name="comment" className="form-control" placeholder="Add Comment..." onChange={onChange} value={data.comment} rows="3"></textarea>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setOpen(false)}>Close</Button>
                    <Button color="primary" onClick={() => OnCommentSubmit()} >Save</Button>
                </DialogActions>
            </Dialog>


            {/* ############################################################### */}

            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                {history_url.from !== "StudentDetailPage" ?
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb Breadcrumb align-items-center">
                            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                            <li className="breadcrumb-item">Tests</li>
                            <li className="breadcrumb-item"><Link to="/test-history">Test History</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Detail View</li>
                        </ol>
                    </nav>
                    :
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb Breadcrumb align-items-center">
                            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                            <li className="breadcrumb-item">Students</li>
                            <li className="breadcrumb-item"><Link to="/location-student-table">Students List</Link></li>
                            <li className="breadcrumb-item"><Link to={"/student-detail/" + history_url.id}>Student Details</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Test Details</li>
                        </ol>
                    </nav>
                }
                <Heading name={`Detail View`} />
                <div className="container-fluid">

                    <div className="row mb-4">

                        <div className="col-12 d-flex ">
                            <h5>Attempted Test Information</h5> &nbsp; {field.test_mode === "offline" && <CloudOffIcon style={{ color: "#be1623" }} />}
                            {(field.test_type !== "Assignment" && field.approved === "1") && <Button variant='contained' onClick={() => navigate(`/view-report/${id}`)} className=" ms-auto ">View Report</Button>}
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-11 col-md-6 mx-auto">

                            <ReadonlyInput field="Student Name"  className="form-control bg-light border-0 " value={`${field.fname} ${field.lname}`} />

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Test Name</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.test_name} ></textarea>
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Test Type</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.test_type}></textarea>
                                </div>
                            </div>

                            <ReadonlyInput field="Student's Level"  className="form-control bg-light border-0 " value={field.level_name} />

                            {field.test_mode === "offline" && <>
                                <hr />
                                <small >The level of the test/exam the student has taken</small>
                                <ReadonlyInput field="Test Level"   value={field.test_level} />
                                <hr />
                            </>
                            }
                            <ReadonlyInput field="Exam Given On"  className="form-control bg-light border-0 " value={field.created_date.split(" ")[0]} />

                            <hr className="p-0 my-4 w-50" />
                            <ReadonlyInput field="Total Attempted Percentage"  className="form-control bg-light border-0 " value={((field.total_attempted_per_ab + field.total_attempted_per_mm) / 2).toFixed(2)} />
                            <ReadonlyInput field="Total Accuracy Percentage"  className="form-control bg-light border-0 " value={((field.accuracy_per_ab + field.accuracy_per_mm) / 2).toFixed(2)} />
                            <hr className="p-0 my-4 w-50" />
                            <h5 className="mb-3">Attachments Added</h5>
                            {attachment.length !== 0 ?
                                <table className="table table-bordered  ">

                                    <tbody>
                                        {attachment.map((x, i) => {
                                            return (
                                                <tr key={i} >
                                                    <td scope="row">{i + 1}</td>
                                                    {/* <td className="text-center ">{x.created_by}</td> */}
                                                    <td className="text-left">
                                                        <a href={`${url}php/questions/stud_attachments/${x.attachments}`} rel="noopener noreferrer" target="_blank"> Attachment</a>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table> : <h6 > <ErrorOutlineIcon fontSize="small" className="me-2" />No Attachments Found</h6>
                            }

                        </div>

                        <div className="col-11 col-md-6 mx-auto">

                            {field.test_type === "Exam" &&
                                <>
                                    <h6 style={{ letterSpacing: ".5px", fontWeight: "500" }} className="mb-3">MindMath -</h6>
                                    <ReadonlyInput field="Time Taken"  className="form-control bg-light border-0 " value={field.time_taken_mm} />
                                    {/* <ReadonlyInput field="Total Attempted  Questions"  className="form-control bg-light border-0 " value={field.total_attempted_mm} /> */}
                                    <ReadonlyInput field="Total Attempted Question %"  className="form-control bg-light border-0 " value={field.total_attempted_per_mm.toFixed(1)} />
                                    {/* <ReadonlyInput field="Accuracy"  className="form-control bg-light border-0 " value={field.accuracy_mm.toFixed(1)} /> */}
                                    <ReadonlyInput field="Accuracy %"  className="form-control bg-light border-0 " value={field.accuracy_per_mm.toFixed(1)} />

                                    <h6 style={{ letterSpacing: ".5px", fontWeight: "500" }} className="mb-3 mt-5">Abacus -</h6>
                                    <ReadonlyInput field="Time Taken"  className="form-control bg-light border-0 " value={field.time_taken_abacus} />
                                    {/* <ReadonlyInput field="Total Attempted  Questions"  className="form-control bg-light border-0 " value={field.total_attempted_ab} /> */}
                                    <ReadonlyInput field="Total Attempted Question %"  className="form-control bg-light border-0 " value={field.total_attempted_per_ab.toFixed(1)} />
                                    {/* <ReadonlyInput field="Accuracy"  className="form-control bg-light border-0 " value={field.accuracy_ab.toFixed(1)} /> */}
                                    <ReadonlyInput field="Accuracy %"  className="form-control bg-light border-0 " value={field.accuracy_per_ab.toFixed(1)} />
                                </>
                            }

                            {field.test_type === "Test" &&
                                <>
                                    <h6 style={{ letterSpacing: ".5px", fontWeight: "500" }} className="mb-3">MindMath -</h6>
                                    <ReadonlyInput field="Time Taken"  className="form-control bg-light border-0 " value={field.time_taken_mm} />
                                    {/* <ReadonlyInput field="Total Attempted  Questions"  className="form-control bg-light border-0 " value={field.total_attempted_mm} /> */}
                                    <ReadonlyInput field="Total Attempted Question %"  className="form-control bg-light border-0 " value={field.total_attempted_per_mm.toFixed(1)} />
                                    {/* <ReadonlyInput field="Accuracy"  className="form-control bg-light border-0 " value={field.accuracy_mm.toFixed(1)} /> */}
                                    <ReadonlyInput field="Accuracy %"  className="form-control bg-light border-0 " value={field.accuracy_per_mm.toFixed(1)} />

                                    <h6 style={{ letterSpacing: ".5px", fontWeight: "500" }} className="mb-3 mt-5">Abacus -</h6>
                                    <ReadonlyInput field="Time Taken"  className="form-control bg-light border-0 " value={field.time_taken_abacus} />
                                    {/* <ReadonlyInput field="Total Attempted  Questions"  className="form-control bg-light border-0 " value={field.total_attempted_ab} /> */}
                                    <ReadonlyInput field="Total Attempted Question %"  className="form-control bg-light border-0 " value={field.total_attempted_per_ab.toFixed(1)} />
                                    {/* <ReadonlyInput field="Accuracy"  className="form-control bg-light border-0 " value={field.accuracy_ab.toFixed(1)} /> */}
                                    <ReadonlyInput field="Accuracy %"  className="form-control bg-light border-0 " value={field.accuracy_per_ab.toFixed(1)} />
                                </>
                            }

                            {(field.test_type === "Test" || field.test_type === "Exam") &&
                                <>
                                    <hr className="me-5 " />
                                    {(sessionStorage.getItem("role") === "Superadmin" || sessionStorage.getItem("role") === "Systemadmin" || sessionStorage.getItem("role") === "Admin" || sessionStorage.getItem("role") === "Location Manager") &&

                                        <p>Add/Edit Comment <IconButton className="ms-2" onClick={() => setOpen(true)} ><RateReviewIcon style={{ color: "black" }} className="text-black" /></IconButton></p>
                                    }
                                    <div className="mb-3">
                                        <label className="form-label" style={{ fontWeight: 500 }}>Teacher's Comment</label>
                                        <div className="pe-5">
                                            <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.teacher_comment} ></textarea>
                                        </div>
                                    </div>

                                    {field.test_name !== "" &&
                                        (data.approved === "1" ? <h6 className="d-flex align-items-center"><CheckCircleIcon style={{ color: "#4BB543 " }} />&nbsp;Comment has been Approved by the Admin.</h6> :
                                            <h6 className="d-flex align-items-center"><ErrorIcon color="error" />&nbsp;Comment has not been Approved by the Admin yet.</h6>)
                                    }

                                    {(sessionStorage.getItem("role") === "Superadmin" || sessionStorage.getItem("role") === "Systemadmin" || sessionStorage.getItem("role") === "Admin" || sessionStorage.getItem("role") === "Location Manager") &&
                                        <>
                                            <hr className="me-5 mb-3" />
                                            <div className="mb-3 row ">
                                                <label className="col-sm-5 col-form-label">Approve Comment</label>
                                                <div className="col-sm-7 col-md-7 ps-4 pt-2">
                                                    <div className="form-check ">
                                                        <input className="form-check-input" name="approved" type="checkbox" checked={data.approved} onChange={onApproveSubmit} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                </>}
                            <Backdrop sx={{ zIndex: 3001, color: '#fff' }} open={backdrop} ><CircularProgress style={{ color: "#fff" }} /></Backdrop>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewTestHistoryComp
