import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";


import Heading from "../../../../Common/Heading";
import { toast } from "react-toastify"

import LoadingSpinner from "../../../../Common/LoadingSpinner";

import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import SaveAlt from '@mui/icons-material/SaveAlt';

import axios from "axios";
import url from "../../../../Common/URL";
import { useConfirm } from 'material-ui-confirm';

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";

const AccountFeeComp = () => {

  const confirm = useConfirm();


  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
  })

  const Columns = useMemo(
    () => [
      { header: 'Name', id: 'name', accessorFn: rowData => `${rowData.fname} ${rowData.lname}` },
      { header: 'Fee', accessorKey: 'fee', },
      { header: 'Duration', accessorKey: 'duration', },
      { header: 'Created By', id: 'creator_name', accessorFn: rowData => `${rowData.creator_fname} ${rowData.creator_lname}` },
      { header: 'Status', id: 'paid', accessorFn: rowData => rowData.paid === "1" ? "Paid" : "Not Paid" },
      { header: 'Valid From', id: 'valid_from', accessorFn: rowData => rowData.valid_from === "0000-00-00" ? "--" : rowData.valid_from },
      { header: 'Valid To', id: 'valid_to', accessorFn: rowData => rowData.valid_to === "0000-00-00" ? "--" : rowData.valid_to },
    ],
    [],
  );

  // ------------------------Fetching Fee table's data-------------------------------------
  useEffect(() => {
    loadFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadFee = () => {
    const obj = { uname: sessionStorage.getItem("username") }
    axios.post(url + "php/student_fee/fee.php", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = JSON.parse(response.data.data);
          // console.log(a);
          setData(a);
          setLoading(false)
        } else {
          setData([]);
          setLoading(false)
        }
      })
      .catch((err) => console.log(err));
  }

  // ------------------------Deleting Fee table's data-------------------------------------

  const onDelete = (id, resolve) => {
    //alert(id);
    const delobj = {
      id: id,
      uname: sessionStorage.getItem("username"),
      tokenId: 123,
    };
    axios.post(url + "php/student_fee/delete_fee.php", delobj)
      .then((res) => {
        // console.log(res);
        resolve();
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Accounts</li>
            <li className="breadcrumb-item active" aria-current="page">Accounts Fees</li>
          </ol>
        </nav>

        <Heading name="Accounts Fees" />

        <div className="container-fluid mb-5">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end ">
              <Button variant='contained' onClick={() => navigate("/act-fee-add-all")} >Add Fee To All <Add /></Button>
              <Button variant='contained' onClick={() => navigate("/add-act-fee")} className="ms-3">Add Fee <Add /></Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto">

              <MaterialReactTable
                state={{ isLoading: loading }}
                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                
                getRowId={(originalRow) => originalRow.stud_fees_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/edit-act-fee/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <IconButton
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          // console.log(selectedRows);
                          const delPromise = selectedRows.map((row) => {
                            return new Promise((resolve, reject) => {
                              onDelete(row.id, resolve);
                            })
                          })
                          Promise.all(delPromise).then(() => {
                            loadFee();
                            table.resetRowSelection();
                            toast.error(selectedRows.length + " Row(s) Deleted!")
                          });
                        })
                    }}
                  ><DeleteOutline />
                  </IconButton>
                )}
              />
            </div>
          </div>
        </div>

      </div>

    </>
  )

}
export default AccountFeeComp