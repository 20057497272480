// Development
const url = "https://gs-lms-dev.portalwiz.in/";
const laravelUrl = "https://gs-lms-dev.portalwiz.in/api/public/api/";


// Production Clone
// const url = "https://portaltest.guruscool.com/";
// const laravelUrl = "https://portaltest.guruscool.com/api/public/api/";


// Production
// const url = "https://portal.guruscool.com/";
// const laravelUrl = "https://portal.guruscool.com/api/public/api/";

export default url;
export { laravelUrl };