import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Heading from "../../../../Common/Heading";

import { toast } from "react-toastify";
import LoadingSpinner from "../../../../Common/LoadingSpinner";


import AssignmentIcon from '@mui/icons-material/Assignment';
import VisibilityIcon from '@mui/icons-material/Visibility';

import axios from "axios";
import MaterialReactTable from 'material-react-table';
import url from "../../../../Common/URL";

// ------------------Imports for the Assign test Popup----------------------

import { Box, Button, IconButton, Tooltip } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";

const AssignTestComp = () => {

  const [loading, setLoading] = useState(true);
  const [popup_load, setPopup_load] = useState(true); // This loading is for the popup student table

  const [state] = useState({
    uname: sessionStorage.getItem("username"),
  })

  const [open, setOpen] = useState(false);   //for the Assign test Popup
  const [axios_data, setAxios_data] = useState({
    test_type: "", test_name: "", level_name: "", assignment_type: "", page: "", test_id: ""
  })   // Variables created to store received api data  and send the data to axios

  const [stuData, setStuData] = useState([]);     //Data of assign test popup

  const [testStartDate, setTestStartDate] = useState("");

  const Student_Column = [
    { header: 'First Name', accessorKey: 'fname' },
    { header: 'Last Name', accessorKey: 'lname' },
    { header: "Batch", accessorKey: "batch_name" },
    { header: 'Location', accessorKey: 'location_name' },
    { header: 'Level', accessorKey: 'level_name' },
  ]


  const Columns = [
    { header: 'Test Name', accessorKey: 'test_name' },
    { header: 'Type', accessorKey: 'test_type' },
    { header: 'Level', accessorKey: 'level_name' },
    { header: 'Description', accessorKey: 'test_desc' },
  ]

  const [test, setTest] = useState([]);

  // ------------------------Fetching Test  -------------------------------------
  //----------------------------------
  const loadTests = () => {
    axios.post(url + "php/test/test.php", { uname: state.uname })
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = JSON.parse(response.data.data);
          // console.log(a);
          setTest(a);
          setLoading(false)
        } else {
          setTest([]);
          setLoading(false)
        }

      })
      .catch((err) => console.log(err));
  }

  useEffect(loadTests, []);

  //----------------------------------
  const loadStudents = (lev_name) => {
    console.log(lev_name);
    setStuData([]);

    var location_name_arr = [], batch_name_arr = []  // Variables declared for filtering data process, for teacher

    //================== Getting the location , level , batch for Teacher ===============
    if (sessionStorage.getItem("role") === "Teacher") {

      axios.post(url + "php/teachers/select_teacher_params.php", { uname: state.uname })
        .then((res) => {
          // console.log(res)
          if (res.data.success) {
            batch_name_arr = JSON.parse(res.data.batch_data).map(a => a.batch_name.toLowerCase());
            location_name_arr = JSON.parse(res.data.location_data).map(a => a.location_name.toLowerCase())
          } else {
            alert("An error occurred, Location , Level & Batch can't be fetched.")
          }
        }).catch(res => console.log(res))

    }
    // ======= Normal flow of Function i.e. fetching students according to level of test selected ============
    const obj = { uname: state.uname, level_name: lev_name }

    axios.post(url + "php/students/stud_level.php", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = JSON.parse(response.data.data);
          // console.log(a);

          //========== Filtering Students according to Teacher's -> location , level , batch  ===========
          if (sessionStorage.getItem("role") === "Teacher") {
            var filterData = a.filter(stu => {
              return location_name_arr.indexOf(stu.location_name.toLowerCase()) >= 0 &&
                batch_name_arr.indexOf(stu.batch_name.toLowerCase()) >= 0
            })
            setPopup_load(false);
            filterData !== null ? setStuData(filterData) : setStuData([]);

            //======== Assigning all the students of location same as the manager having level same as test's  =======
          } else if (sessionStorage.getItem("role") === "Location Manager") {
            let managerLocations = sessionStorage.getItem("location").toLowerCase().split(',');
            let filter = a.filter(stu => managerLocations.indexOf(stu.location_name.toLowerCase()) >= 0)

            setPopup_load(false);
            filter !== null ? setStuData(filter) : setStuData([]);
            //======== Assigning all the students having level same as test's  =======   
          } else {
            setPopup_load(false);
            a !== null ? setStuData(a) : setStuData([]);
          }

        } else {
          setPopup_load(false);
          setStuData([]);
        }
      })
      .catch((err) => console.log(err));

  }

  // ------------------------Assigning Test to STudents-------------------------------------

  const OnAssignment = (rowData) => {

    let userArray = [];
    //Here id is coming as user_name, this is done with the help of MaterialReactTable 'getRowId' prop
    rowData.map(x => userArray.push(x.id));

    const obj = {
      stud_uname: userArray,
      assignment_name: axios_data.test_name, test_type: axios_data.test_type, assignment_type: axios_data.assignment_type,
      uname: state.uname, level_name: axios_data.level_name, page: axios_data.page,
      test_date: axios_data.test_type === "Assignment" ? "--" : testStartDate, test_id: axios_data.test_id
    }
    console.log(obj);

    axios.post(url + "php/students/assign_multi_stud.php", obj)
      .then((res) => {
        console.log(res);
        setOpen(false);
        setTestStartDate("");
        toast.info(axios_data.test_name + " Assigned to " + rowData.length + " Student(s)")
      })
      .catch((err) => console.log(err));
  }

  const handleChangeTime = (e) => {
    setTestStartDate(e.target.value)
  }

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item"><strong>Students</strong></li>
            <li className="breadcrumb-item active" aria-current="page">Assign Test</li>
          </ol>
        </nav>

        <Heading name="Assign Test" />

        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={open}>
          <DialogTitle id="max-width-dialog-title">Select Students To Assign Test</DialogTitle>
          <DialogContent>

            <div className="row">
              {(axios_data.test_type !== "Assignment") &&

                <div className=" mb-3 col-auto">
                  <label htmlFor="dateTime" className="form-label" style={{ fontWeight: "500" }}>Schedule Test </label>
                  <input type="datetime-local"
                    className="form-control col-12 col-md-2" id="dateTime"
                    value={testStartDate}
                    onChange={handleChangeTime} />
                </div>
              }
              <div className="col-12">

                <MaterialReactTable
                  state={{ isLoading: popup_load }}
                  columns={Student_Column}
                  data={stuData}
                  enableRowNumbers
                  enableRowSelection
                  initialState={{ density: 'compact' }}
                  enableDensityToggle={false}
                  getRowId={(originalRow) => originalRow.user_name}
                  renderTopToolbarCustomActions={({ table }) => (
                    <Button
                      variant="contained"
                      disabled={table.getSelectedRowModel().rows.length ? false : true}
                      onClick={() => {
                        // let selectedRows = table.getSelectedRowModel().rows;
                        // console.log(selectedRows);
                        OnAssignment(table.getSelectedRowModel().rows);
                      }}
                    >Assign Test
                    </Button>
                  )}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions> <Button color="primary" onClick={() => setOpen(false)}>Close</Button> </DialogActions>
        </Dialog>


        <div className="container-fluid">
          <div className="row my-5">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{ isLoading: loading }}
                columns={Columns}
                data={test}
                enableRowNumbers
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                positionActionsColumn="last"
                enableRowActions
                renderRowActions={(row, index) => (
                  <Box>
                    <Tooltip title="View">
                      <IconButton onClick={() => {
                        let rowData = row.row.original;
                        var level_name = rowData.level_name;
                        var test_type = rowData.test_type;
                        // var assignment_type =rowData.assignment_type;
                        // var page = rowData.page;
                        var test_name = rowData.test_name;
                        var test_id = rowData.test_id;

                        if (rowData.generator_test === '1') {
                          const win = window.open(`/modern-test-exam-preview/${rowData.test_id}/${0}/${null}`, "_blank");
                          win.focus();
                        }
                        else if (test_type === "Exam") {
                          const win = window.open(`/exam-preview/${test_name}/${test_id}/${0}/${null}/${null}`, "_blank");
                          win.focus();
                        } else if (test_type === "Assignment") {
                          const win = window.open(`/assignment-preview/${test_name}/${test_id}/${0}/${null}/${null}`, "_blank");
                          win.focus();
                        } else {
                          const win = window.open(`/test-preview/${test_name}/${test_id}/${0}/${null}/${null}`, "_blank");
                          win.focus();
                        }
                      }}>
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Assign">
                      <IconButton onClick={() => {
                        let rowData = row.row.original;
                        setAxios_data({
                          test_name: rowData.test_name, test_type: rowData.test_type,
                          level_name: rowData.level_name,
                          assignment_type: rowData.assignment_type, page: rowData.page, test_id: rowData.test_id
                        })
                        setPopup_load(() => true);
                        loadStudents(rowData.level_name);
                        setOpen(true);

                      }}>
                        <AssignmentIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}

              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AssignTestComp;