import { Button } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { useSelector } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Heading from "../../../../Common/Heading"
import url from "../../../../Common/URL"
import Input from "./Input"


const EditStudentComp = () => {
    let message = "";
    const navigate = useNavigate();

    const [loc, setLoc] = useState([]);
    const [batch, setBatch] = useState([]);
    const [lev, setLev] = useState([]);
    // const [usr , setUsr] =useState([]);

    const [disable, setDisable] = useState(true);
    const [form_data, setForm_data] = useState(true); //Used to compare if any update is done in form data.

    const { id } = useParams();

    const [field, setField] = useState({
        uname: sessionStorage.getItem("username"), stud_id: "",
        // ------------normal input fields--------------
        fname: "", mname: "", lname: "", city: "", zip: "", mobile_no: "", home_no: "", email: "",
        parent_fname: "", parent_lname: "", parent_mobile_no: "", parent_email: "", parent_mobile_no_2: "",
        parent_email_2: "", join_date: "", leave_date: "", dob: "", gsc: "", stud_code: "", acc_name: "", acc_number: "",
        // ------------Textarea input fields--------------
        addressline1: "", addressline2: "",
        // ------------Checkbox input fields-------------- 
        active: false, photo_consent: false, disclaimer_signed: false, status: "",
        // ------------Select inputs fields--------------
        state: "", country: "", location_name: "", batch_name: "", level_name: "", user_name: "",
        picture: "",
    })

    useEffect(() => {
        StudentDetail();
        loadLocation();
        loadBatch();
        loadLevel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        axios.post(url + "php/locations/locations.php", obj)
            .then((response) => {
                let a = JSON.parse(response.data.data);
                //   console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    }

    // -----------This function will Fetch all the Existing Batch--------------------
    const loadBatch = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        axios.post(url + "php/batches/batches.php", obj)
            .then((response) => {
                let a = JSON.parse(response.data.data);
                //  console.log(a);
                setBatch(a);
            })
            .catch((err) => console.log(err));
    }


    // -----------This function will Fetch all the Existing Levels--------------------
    const loadLevel = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        axios.post(url + "php/levels/levels.php", obj)
            .then((response) => {
                let a = JSON.parse(response.data.data);
                //   console.log(a);
                setLev(a);
            })
            .catch((err) => console.log(err));
    }


    // ------------------------------
    const StudentDetail = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        }
        await axios.post(url + "php/students/select_student.php", obj)
            .then((response) => {
                // console.log(response);
                let resData = JSON.parse(response.data.data);
                console.log(resData);

                setForm_data(resData);       //THis will help in checking any update done in form, so we can enable or disable the save form button

                setField({
                    fname: resData[0].fname, mname: resData[0].mname, lname: resData[0].lname, city: resData[0].city, zip: resData[0].zip,
                    mobile_no: resData[0].mobile_no, home_no: resData[0].home_no, email: resData[0].email,
                    parent_fname: resData[0].parent_fname, parent_lname: resData[0].parent_lname, parent_mobile_no: resData[0].parent_mobile_no,
                    parent_email: resData[0].parent_email, parent_mobile_no_2: resData[0].parent_mobile_no_2, approved_date: resData[0].approved_date,
                    parent_email_2: resData[0].parent_email_2, join_date: resData[0].join_date, leave_date: resData[0].leave_date,
                    stud_id: resData[0].stud_id,
                    gsc: resData[0].gsc, stud_code: resData[0].stud_code, dob: resData[0].dob, acc_name: resData[0].acc_name, acc_number: resData[0].acc_number,
                    // ------------Textarea input fields--------------
                    addressline1: resData[0].addressline1, addressline2: resData[0].addressline2,

                    // ------------Checkbox input fields--------------
                    active: resData[0].active, photo_consent: resData[0].photo_consent, disclaimer_signed: resData[0].disclaimer_signed,
                    status: resData[0].status,
                    // ------------Select inputs fields--------------
                    state: resData[0].state, country: resData[0].country, location_name: capitalizeFirstLetter(resData[0].location_name), batch_name: resData[0].batch_name,
                    level_name: resData[0].level_name, user_name: resData[0].user_name,

                })
            })
            .catch((err) => console.log(err));
        setDisable(true);
        // setForm_update(false); 
    }
    //---------------One onChange function is used for setting the state of various types of input fields ---------------     
    const onChange = (e) => {
        // console.log(form_data);
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        if (form_data[0][name] !== value) {
            setDisable(false);
        } else {
            setDisable(true);
        }

        setField((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    // ------------------- -------------------functions for Submitting Country and state- ------------------- -------------------
    const selectCountry = (val) => {

        setField((preValue) => {
            return {
                ...preValue,
                country: val
            }
        })
    }
    const selectRegion = (val) => {

        setField((preValue) => {
            return {
                ...preValue,
                state: val
            }
        })
    }

    //-------------------------- onSubmit function for Submitting the  Forms---------------------------
    const onSubmit = (e) => {
        e.preventDefault();
        setDisable(true);

        /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;

        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            uname: sessionStorage.getItem("username"), id: id, updated_date: newdate, updated_by: sessionStorage.getItem("username"),

            fname: field.fname, mname: field.mname, lname: field.lname,
            city: field.city, zip: field.zip, mobile_no: field.mobile_no,
            home_no: field.home_no, email: field.email, parent_fname: field.parent_fname,
            parent_lname: field.parent_lname, parent_mobile_no: field.parent_mobile_no, parent_email: field.parent_email,
            parent_mobile_no_2: field.parent_mobile_no_2, parent_email_2: field.parent_email_2,
            join_date: field.join_date, leave_date: field.leave_date, addressline1: field.addressline1,
            addressline2: field.addressline2,
            gsc: field.gsc, stud_code: field.stud_code, dob: field.dob, acc_name: field.acc_name, acc_number: field.acc_number,


            active: field.active, photo_consent: field.photo_consent, disclaimer_signed: field.disclaimer_signed, status: field.status,

            state: field.state, country: field.country, location_name: field.location_name, batch_name: field.batch_name,
            level_name: field.level_name, user_name: field.user_name,
        }

        axios.post(url + "php/students/edit_student.php", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("Updated successfully!")
                    StudentDetail();
                } else {
                    message = res.data.message;
                    alert(message)
                }
            })
            .catch((err) => console.log(err));
    }

    // --------------This will help in creating capitalize strings 
    const capitalizeFirstLetter = (string) => {
        // console.log(string);
        return string[0].toUpperCase() + string.slice(1);
    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item "><Link to="/student">Students list</Link></li>
                        <li className="breadcrumb-item active">Edit Student</li>
                    </ol>
                </nav>
                <Heading name={`Edit Student - ${field.fname}`} />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                <form onSubmit={onSubmit}>
                    <div className="container-fluid mt-3">

                        <div className="row my-4">
                            <div className="col-12 d-flex mx-auto">
                                <Button variant="contained" color="success" onClick={() => toast.info('Under Progress')} >Send Login Credentials</Button>
                                <Button variant="contained" className="ms-auto" onClick={() => navigate("/view-student/" + field.stud_id)} >Back</Button>
                                <Button variant="contained" type="submit" disabled={disable} className="ms-3">Save</Button>
                            </div>
                        </div>

                        <div className="row">

                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Personal Information</h5>

                                {/* <Input name="picture" onChange={handleInputChange} field="Profile Picture" type="file"  className="form-control-file" value={field.picture} /> */}

                                {/* <div className="mb-3 row">
                            <label className="col-sm-5 col-form-label">Student ID</label>
                            <div className="col-sm-7 col-md-7 ">
                            <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={field.stud_id} />
                        </div>
                    </div> */}

                                <Input name="fname" onChange={onChange} field="First Name" type="text" placeholder="Add text" className="form-control" value={field.fname} required={true} />

                                <Input name="mname" onChange={onChange} field="Middle Name" type="text" placeholder="Add text" className="form-control" value={field.mname} required={false} />

                                <Input name="lname" onChange={onChange} field="Last Name" type="text" placeholder="Add text" className="form-control" value={field.lname} required={true} />

                                <Input name="dob" onChange={onChange} field="Date of Birth" type="date" placeholder="Add DOB" className="form-control" value={field.dob} required={true} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Address Line 1</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea required name="addressline1" onChange={onChange} className="form-control" placeholder="Add text" value={field.addressline1}  ></textarea>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Address Line 2</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea name="addressline2" onChange={onChange} className="form-control" placeholder="Add text" value={field.addressline2} ></textarea>
                                    </div>
                                </div>

                                <Input name="city" onChange={onChange} field="City" type="text" placeholder="Add text" className="form-control" value={field.city} required={true} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Country</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        {/* <select name="state" value={state.state} onChange={onChange} className="form-select">
                                    <option value="province-1">Province 1</option><option value="province-2">Province 2</option><option value="province-3">Province 3</option>
                                </select> */}

                                        <CountryDropdown
                                            className="form-select"
                                            name="country"
                                            value={field.country}
                                            onChange={(val) => selectCountry(val)} />
                                    </div>
                                </div>


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Province</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        {/* <select name="country" value={state.country} onChange={onChange} className="form-select">
                                    <option value="1">Country 1</option><option value="country-2">Country 2</option><option value="country-3">Country 3</option>
                                </select> */}

                                        <RegionDropdown
                                            className="form-select"
                                            name="state"
                                            country={field.country}
                                            value={field.state}
                                            onChange={(val) => selectRegion(val)} />
                                    </div>
                                </div>


                                <Input name="zip" onChange={onChange} field="Zip" type="text" placeholder="Add text" className="form-control" value={field.zip} required={true} />

                                <Input name="mobile_no" onChange={onChange} field="Mobile Number" type="text" placeholder="Add text" className="form-control" value={field.mobile_no} required={true} />

                                <Input name="home_no" onChange={onChange} field="Home Phone" type="text" placeholder="Add text" className="form-control" value={field.home_no} required={false} />

                                <Input name="email" onChange={onChange} field="Email" type="email" placeholder="Add text" className="form-control" value={field.email} required={true} />

                                <h5 className="mt-5 mb-4">Parent Information</h5>

                                <Input name="parent_fname" onChange={onChange} field="Parent First Name" type="text" placeholder="Add text" className="form-control" value={field.parent_fname} required={true} />

                                <Input name="parent_lname" onChange={onChange} field="Parent Last Name" type="text" placeholder="Add text" className="form-control" value={field.parent_lname} required={true} />

                                <Input name="parent_mobile_no" onChange={onChange} field="Parent Mobile Number" type="text" placeholder="Add text" className="form-control" value={field.parent_mobile_no} required={true} />

                                <Input name="parent_email" onChange={onChange} field="Parent Email" type="email" placeholder="Add text" className="form-control" value={field.parent_email} required={true} />

                                <Input name="parent_mobile_no_2" onChange={onChange} field="Parent Mobile Number 2" type="text" placeholder="Add text" className="form-control" value={field.parent_mobile_no_2} />

                                <Input name="parent_email_2" onChange={onChange} field="Parent Email 2" type="email" placeholder="Add text" className="form-control" value={field.parent_email_2} />

                            </div>
                            {/*-----------------------Column-2 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Batch Information</h5>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Location</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="location_name" value={field.location_name} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            {loc.map((data, i) => (<option key={i} value={data.name}>{data.name}</option>))}
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Batch</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="batch_name" value={field.batch_name} onChange={onChange} className="form-select">
                                            <option value="">--</option>
                                            {batch.filter((data) => data.location_name === field.location_name)
                                                .map((data, i) => <option key={i} value={data.batch_name}>{data.batch_name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Level</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="level_name" value={field.level_name} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            {lev.map((data, i) => (<option key={i} value={data.level_name}>{data.level_name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <h5 className="mt-5 mb-4">Other Information</h5>

                                <Input name="gsc" onChange={onChange} field="Global Student Code" type="text" placeholder="Add code" className="form-control" value={field.gsc} />

                                <Input name="stud_code" onChange={onChange} field="Student Code" type="text" placeholder="Add code" className="form-control" value={field.stud_code} />

                                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">User</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <select name="user_name"  value={field.user_name} onChange={onChange} className="form-select">
                                    <option >Choose</option>
                                    {usr.map((data , i)=>(<option key={i} value={data.username}>{data.username}</option>)) } 
                                    </select>
                                </div>
                            </div> */}

                                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Active Account</label>
                                <div className="col-sm-7 col-md-7 ps-4">
                                    <div className="form-check">
                                        <input className="form-check-input" name="active" type="checkbox" checked={field.active}  onChange={onChange}  />
                                    </div>
                                </div>
                            </div> */}

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Status</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="status" value={field.status} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            <option value="Active" >Active</option> <option value="Inactive" >Inactive</option>
                                            <option value="On Leave" >On Leave</option>
                                            <option value="Graduated" >Graduated</option>
                                            <option value="Left" >Left</option>
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Photography Consent Provided?</label>
                                <div className="col-sm-7 col-md-7 ps-4">
                                <div className="form-check">
                                        <input className="form-check-input" name="photo_consent" type="checkbox" checked={field.photo_consent} onChange={onChange}   />
                                    </div>
                                </div>
                            </div> */}
                                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Disclaimer Signed?</label>
                                <div className="col-sm-7 col-md-7 ps-4">
                                <div className="form-check">
                                        <input className="form-check-input" name="disclaimer_signed" type="checkbox" checked={field.disclaimer_signed} onChange={onChange} />
                                    </div>
                                </div>
                            </div> */}

                                <Input name="acc_name" onChange={onChange} field="Account Name" type="text" placeholder="Add Account Name" className="form-control" value={field.acc_name} />

                                <Input name="acc_number" onChange={onChange} field="Account Number" type="text" placeholder="Add Account Number" className="form-control" value={field.acc_number} />

                                <Input name="join_date" onChange={onChange} field="Join Date" type="date" placeholder="Add text" className="form-control" value={field.join_date} required={true} />

                                <Input name="leave_date" onChange={onChange} field="Leave Date" type="date" placeholder="Add text" className="form-control" value={field.leave_date} />

                            </div>

                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}

export default EditStudentComp