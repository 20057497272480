/*
 ## short_name = short name of the operation 
 ## name = Long name of the operation 
 ## operation_type_id = 
        if 0, means this operation is normal type of operation
        if 1, means this operation has some difficulty levels associated with it
        if 3, means this operation is a Multiplication times table operation
 ## row =   
        if row = 10 : This means in mind-math a select input with 10 options will be shown from 1 to 10  as the value.
        if row = 20 : This means in mind-math a select input with 20 options will be shown from 1 to 20  as the value.
 ## type = the type of number generated (whole,decimal)
 ## show_digit = Its used to show label in the choose operation dropdown
 ## digit = Its used in actual question creation logic
 ## limit = its used in Multiplication types of questions, to provide the two number limit - [first_num_limit, second_num_limit]
 ## after_decimal = how much numbers we need after the decimal point 
 ## difficulty_state =>  
        if its 0 :  Means we dont need to add any difficult level condition while generating 
                    the question (It will be the toughest ones), It will be 0 only when we select
                    hard in the difficulty level dropdown.
        if its 1 :  Means we have to add some difficult level conditions while generating the questions 
                    it will generate some easy questions(as certain restrictions are added), It will only be one
                    when Easy or Medium level is selected in the difficulty level dropdown. 

## condition_type =>  
        if 0 :  Then it tells us that, the second_number_limit is an array which tells us the range,
                    i.e. [from , to] from which we have to take a random number inclusively. 
        if 1 :   Then it tells us that, we have to use the second_number_limit property as an array of numbers, 
                    we have to choose a number only from it, now the second_number_limit will 
                    not provide us a range i.e. [from, to].
*/

const LevelData = [
    {
        "level": "0",
        "operations": [
            { "id": 1, "operation_type_id": 3, "short_name": "mul", "name": "2 Times Table", "row": 2, "type": "whole", "show_digit": [1], "digit": [2], "after_decimal": [], },
            { "id": 2, "operation_type_id": 3, "short_name": "mul", "name": "3 Times Table", "row": 2, "type": "whole", "show_digit": [1], "digit": [3], "after_decimal": [], },
            { "id": 3, "operation_type_id": 3, "short_name": "mul", "name": "4 Times Table", "row": 2, "type": "whole", "show_digit": [1], "digit": [4], "after_decimal": [], },
            { "id": 4, "operation_type_id": 3, "short_name": "mul", "name": "5 Times Table", "row": 2, "type": "whole", "show_digit": [1], "digit": [5], "after_decimal": [], },
            { "id": 5, "operation_type_id": 3, "short_name": "mul", "name": "6 Times Table", "row": 2, "type": "whole", "show_digit": [1], "digit": [6], "after_decimal": [], },
            { "id": 6, "operation_type_id": 3, "short_name": "mul", "name": "7 Times Table", "row": 2, "type": "whole", "show_digit": [1], "digit": [7], "after_decimal": [], },
            { "id": 7, "operation_type_id": 3, "short_name": "mul", "name": "8 Times Table", "row": 2, "type": "whole", "show_digit": [1], "digit": [8], "after_decimal": [], },
            { "id": 8, "operation_type_id": 3, "short_name": "mul", "name": "9 Times Table", "row": 2, "type": "whole", "show_digit": [1], "digit": [9], "after_decimal": [], },
        ]
    },
    {
        "level": "1",
        "operations": [
            { "id": 1, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 10, "type": "whole", "show_digit": [1], "digit": [1], "after_decimal": [], },
        ]
    },
    {
        "level": "2",
        "operations": [
            { "id": 1, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 10, "type": "whole", "show_digit": [1], "digit": [1], "after_decimal": [], },
            { "id": 2, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 10, "type": "whole", "show_digit": [2, 1], "digit": [2, 1], "after_decimal": [], },
            { "id": 3, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 10, "type": "whole", "show_digit": [2, 2], "digit": [2, 2], "after_decimal": [], },
        ]
    },
    {
        "level": "3",
        "operations": [
            { "id": 1, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 10, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
            {
                "id": 2, "operation_type_id": 1, "short_name": "a_s", "name": "Add & Subtract", "row": 10, "type": "whole", "show_digit": [3], "digit": [3], "after_decimal": [],
                difficulty_levels: [
                    { id: 1, difficulty_state: 1, name: "Easy - Step 1", first_number_limit: [100, 120], second_number_limit: [100, 140], condition_type: 0 },
                    { id: 2, difficulty_state: 1, name: "Easy - Step 2", first_number_limit: [100, 120], second_number_limit: [100, 300], condition_type: 0 },
                    { id: 3, difficulty_state: 1, name: "Medium", first_number_limit: [100, 200], second_number_limit: [100, 500], condition_type: 0 },
                    { id: 4, difficulty_state: 0, name: "Hard" },
                ]
            },
            { "id": 3, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [2, 1], "digit": [2, 1], "after_decimal": [], },
            { "id": 4, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [3, 1], "digit": [3, 1], "after_decimal": [], },
        ]
    },
    {
        "level": "4",
        "operations": [
            { "id": 1, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 10, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
            {
                "id": 2, "operation_type_id": 1, "short_name": "a_s", "name": "Add & Subtract", "row": 10, "type": "whole", "show_digit": [3], "digit": [3], "after_decimal": [],
                difficulty_levels: [
                    { id: 1, difficulty_state: 1, name: "Easy - Step 1", first_number_limit: [100, 120], second_number_limit: [100, 140], condition_type: 0 },
                    { id: 2, difficulty_state: 1, name: "Easy - Step 2", first_number_limit: [100, 120], second_number_limit: [100, 300], condition_type: 0 },
                    { id: 3, difficulty_state: 1, name: "Medium", first_number_limit: [100, 200], second_number_limit: [100, 500], condition_type: 0 },
                    { id: 4, difficulty_state: 0, name: "Hard" },
                ]
            },
            { "id": 3, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [1, 2], "digit": [1, 2], "after_decimal": [], },
            { "id": 4, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [1, 3], "digit": [1, 3], "after_decimal": [], },
            {
                "id": 5, "operation_type_id": 1, "short_name": "mul", "name": "Multiplication", "limit": [30, 30], "row": 2, "type": "whole", "show_digit": [2, 2], "digit": [2, 2], "after_decimal": [],
                difficulty_levels: [
                    { id: 1, difficulty_state: 1, name: "Easy - Step 1", first_number_limit: [11, 20], second_number_limit: [11, 20], condition_type: 0 },
                    { id: 2, difficulty_state: 1, name: "Easy - Step 2", first_number_limit: [11, 30], second_number_limit: [11, 30], condition_type: 0 },
                    { id: 3, difficulty_state: 1, name: "Easy - Step 3", first_number_limit: [11, 50], second_number_limit: [11, 20], condition_type: 0 },
                    { id: 4, difficulty_state: 1, name: "Medium - Step 1", first_number_limit: [10, 99], second_number_limit: [22, 33, 44], condition_type: 1 },
                    { id: 5, difficulty_state: 1, name: "Medium - Step 2", first_number_limit: [10, 99], second_number_limit: [44, 55, 66, 77, 88, 99], condition_type: 1 },
                    { id: 6, difficulty_state: 0, name: "Hard" },
                ]
            },
            {
                "id": 6, "operation_type_id": 1, "short_name": "mul", "name": "Multiplication", "limit": [130, 30], "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [],
                difficulty_levels: [
                    { id: 1, difficulty_state: 1, name: "Easy - Step 1", first_number_limit: [100, 120], second_number_limit: [10, 20], condition_type: 0 },
                    { id: 2, difficulty_state: 1, name: "Easy - Step 2", first_number_limit: [120, 130], second_number_limit: [20, 30], condition_type: 0 },
                    { id: 3, difficulty_state: 1, name: "Medium - Step 1", first_number_limit: [100, 110], second_number_limit: [22, 33, 44, 55, 66, 77, 88, 99], condition_type: 1 },
                    { id: 4, difficulty_state: 1, name: "Medium - Step 2", first_number_limit: [110, 120], second_number_limit: [11, 22, 33, 44, 55, 66, 77, 88, 99], condition_type: 1 },
                    { id: 5, difficulty_state: 0, name: "Hard" },
                ]
            },
        ]
    },
    {
        "level": "5",
        "operations": [
            { "id": 1, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
            { "id": 2, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "whole", "show_digit": [3], "digit": [3], "after_decimal": [], },
            { "id": 3, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 10, "type": "whole", "show_digit": [4], "digit": [4], "after_decimal": [], },
            { "id": 4, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [1, 3], "digit": [1, 3], "after_decimal": [], },
            { "id": 5, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "limit": [50, 30], "row": 2, "type": "whole", "show_digit": [2, 2], "digit": [2, 2], "after_decimal": [], },
            { "id": 6, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [2, 1], "digit": [2, 1], "after_decimal": [], },
            {
                "id": 7, "operation_type_id": 1, "short_name": "mul", "name": "Multiplication", "limit": [130, 50], "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [],
                difficulty_levels: [
                    { id: 1, difficulty_state: 1, name: "Easy - Step 1", first_number_limit: [100, 120], second_number_limit: [10, 20], condition_type: 0 },
                    { id: 2, difficulty_state: 1, name: "Easy - Step 2", first_number_limit: [120, 130], second_number_limit: [20, 30], condition_type: 0 },
                    { id: 3, difficulty_state: 1, name: "Medium - Step 1", first_number_limit: [100, 110], second_number_limit: [22, 33, 44, 55, 66, 77, 88, 99], condition_type: 1 },
                    { id: 4, difficulty_state: 1, name: "Medium - Step 2", first_number_limit: [110, 120], second_number_limit: [22, 33, 44, 55, 66, 77, 88, 99], condition_type: 1 },
                    { id: 5, difficulty_state: 0, name: "Hard" },
                ]
            },
            { "id": 8, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [2, 1], "digit": [2, 1], "after_decimal": [], },
        ]
    },
    {
        "level": "6",
        "operations": [
            { "id": 1, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
            { "id": 2, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "whole", "show_digit": [3], "digit": [3], "after_decimal": [], },
            { "id": 3, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 10, "type": "whole", "show_digit": [4], "digit": [4], "after_decimal": [], },
            { "id": 4, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [1, 3], "digit": [1, 3], "after_decimal": [], },
            { "id": 5, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "limit": [40, 40], "row": 2, "type": "whole", "show_digit": [2, 2], "digit": [2, 2], "after_decimal": [], },
            { "id": 6, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [2, 1], "digit": [2, 1], "after_decimal": [], },
            { "id": 7, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "decimal", "show_digit": [1, 3], "digit": [1, 2], "after_decimal": [0, 1], },
            { "id": 8, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "decimal", "show_digit": [2, 2], "digit": [1, 1], "after_decimal": [1, 1], },
            { "id": 9, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [], },
            {
                "id": 10, "operation_type_id": 1, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [3, 3], "digit": [3, 3], "after_decimal": [],
                difficulty_levels: [
                    { id: 1, difficulty_state: 1, name: "Easy - Step 1", first_number_limit: [100, 110], second_number_limit: [100, 120], condition_type: 0 },
                    { id: 2, difficulty_state: 1, name: "Easy - Step 2", first_number_limit: [120, 130], second_number_limit: [100, 130], condition_type: 0 },
                    { id: 3, difficulty_state: 1, name: "Medium - Step 1", first_number_limit: [100, 110], second_number_limit: [122, 133, 144, 155, 166, 177, 188, 199], condition_type: 1 },
                    { id: 4, difficulty_state: 1, name: "Medium - Step 2", first_number_limit: [110, 120], second_number_limit: [122, 133, 144, 155, 166, 177, 188, 199], condition_type: 1 },
                    { id: 5, difficulty_state: 0, name: "Hard" },
                ]
            },
            { "id": 11, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [3, 1], "digit": [3, 1], "after_decimal": [], },
            { "id": 12, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [], },
            { "id": 13, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [4, 2], "digit": [4, 2], "after_decimal": [], },
            { "id": 14, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [4, 3], "digit": [4, 3], "after_decimal": [], },
        ]
    },
    {
        "level": "7",
        "operations": [
            { "id": 1, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "decimal", "show_digit": [2], "digit": [1], "after_decimal": [1], },
            { "id": 2, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "whole", "show_digit": [3], "digit": [3], "after_decimal": [], },
            { "id": 3, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 10, "type": "whole", "show_digit": [4], "digit": [4], "after_decimal": [], },
            { "id": 4, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [1, 3], "digit": [1, 3], "after_decimal": [], },
            { "id": 5, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "limit": [40, 40], "row": 2, "type": "whole", "show_digit": [2, 2], "digit": [2, 2], "after_decimal": [], },
            { "id": 6, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [2, 1], "digit": [2, 1], "after_decimal": [], },
            { "id": 7, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [], },
            { "id": 8, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "decimal", "show_digit": [2, 3], "digit": [1, 2], "after_decimal": [1, 1], },
            { "id": 9, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [4, 1], "digit": [4, 1], "after_decimal": [], },
            { "id": 10, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [], },
            { "id": 11, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [], },
            { "id": 12, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [4, 2], "digit": [4, 2], "after_decimal": [], },
            { "id": 13, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "decimal", "show_digit": [2, 2], "digit": [1, 1], "after_decimal": [1, 1], },
            { "id": 14, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "decimal", "show_digit": [3, 2], "digit": [2, 1], "after_decimal": [1, 1], },
        ]
    },
    {
        "level": "8",
        "operations": [
            { "id": 1, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "decimal", "show_digit": [1], "digit": [1], "after_decimal": [1], },
            { "id": 2, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "whole", "show_digit": [3], "digit": [3], "after_decimal": [], },
            { "id": 3, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "whole", "show_digit": [4], "digit": [4], "after_decimal": [], },
            { "id": 4, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [1, 3], "digit": [1, 3], "after_decimal": [], },
            { "id": 5, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "limit": [40, 40], "row": 2, "type": "whole", "show_digit": [2, 2], "digit": [2, 2], "after_decimal": [], },
            { "id": 6, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [2, 1], "digit": [2, 1], "after_decimal": [], },
            { "id": 7, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [], },
            { "id": 8, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "decimal", "show_digit": [2, 2], "digit": [1, 1], "after_decimal": [1, 1], },
            { "id": 9, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "decimal", "show_digit": [4], "digit": [4], "after_decimal": [], },
            { "id": 10, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "decimal", "show_digit": [2, 3], "digit": [1, 2], "after_decimal": [1, 1], },
            { "id": 11, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "decimal", "show_digit": [2, 2], "digit": [1, 1], "after_decimal": [1, 1], },
            { "id": 12, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [4, 1], "digit": [3, 1], "after_decimal": [], },
            { "id": 13, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [], },
            { "id": 14, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [], },
            { "id": 15, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [4, 2], "digit": [4, 2], "after_decimal": [], },
            { "id": 16, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [4, 3], "digit": [4, 3], "after_decimal": [], },
            { "id": 17, "operation_type_id": 0, "short_name": "gcd", "name": "GCD", "row": 2, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
            { "id": 18, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [3, 3], "digit": [3, 3], "after_decimal": [], },
            { "id": 19, "operation_type_id": 0, "short_name": "lcm", "name": "LCM", "row": 2, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
            { "id": 20, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "decimal", "show_digit": [2, 1], "digit": [1, 0.1], "after_decimal": [1, 1], },
            { "id": 21, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "decimal", "show_digit": [3, 2], "digit": [2, 0.01], "after_decimal": [1, 1], },
            { "id": 22, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "decimal", "show_digit": [3, 3], "digit": [2, 0.001], "after_decimal": [1, 1], },
        ]
    },
    {
        "level": "9",
        "operations": [
            { "id": 1, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "decimal", "show_digit": [2], "digit": [1], "after_decimal": [1], },
            { "id": 2, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "decimal", "show_digit": [3], "digit": [2], "after_decimal": [1], },
            { "id": 3, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "whole", "show_digit": [4], "digit": [4], "after_decimal": [], },
            { "id": 4, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "decimal", "show_digit": [2, 2], "digit": [1, 1], "after_decimal": [1, 1], },
            { "id": 5, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [2, 2], "digit": [2, 2], "after_decimal": [], },
            { "id": 6, "operation_type_id": 0, "short_name": "lcm", "name": "LCM", "row": 2, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
            { "id": 7, "operation_type_id": 0, "short_name": "gcd", "name": "GCD", "row": 2, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
            { "id": 8, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [], },
            { "id": 9, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "decimal", "show_digit": [2, 2], "digit": [1, 1], "after_decimal": [1, 1], },
            { "id": 10, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [4, 1], "digit": [4, 1], "after_decimal": [], },
            { "id": 11, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [], },
            { "id": 12, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [], },
            { "id": 13, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [4, 2], "digit": [4, 2], "after_decimal": [], },
            { "id": 14, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [4, 3], "digit": [4, 3], "after_decimal": [], },
            { "id": 15, "operation_type_id": 0, "short_name": "per", "name": "Percentage", "row": 2, "type": "whole", "show_digit": [1, 2], "digit": [1, 2], "after_decimal": [], },
            { "id": 16, "operation_type_id": 0, "short_name": "per", "name": "Percentage", "row": 2, "type": "whole", "show_digit": [2, 2], "digit": [2, 2], "after_decimal": [], },
            { "id": 17, "operation_type_id": 0, "short_name": "per", "name": "Percentage", "row": 2, "type": "whole", "show_digit": [2, 3], "digit": [2, 3], "after_decimal": [], },
            { "id": 18, "operation_type_id": 0, "short_name": "sqrt", "name": "Square Root", "row": 1, "type": "whole", "show_digit": [3], "digit": [3], "after_decimal": [], },
            { "id": 19, "operation_type_id": 0, "short_name": "sqrt", "name": "Square Root", "row": 1, "type": "whole", "show_digit": [4], "digit": [4], "after_decimal": [], },
            { "id": 20, "operation_type_id": 0, "short_name": "sqrt", "name": "Square Root", "row": 1, "type": "whole", "show_digit": [5], "digit": [5], "after_decimal": [], },
            { "id": 21, "operation_type_id": 0, "short_name": "sqrt", "name": "Square Root", "row": 1, "type": "whole", "show_digit": [6], "digit": [6], "after_decimal": [], },
            { "id": 22, "operation_type_id": 0, "short_name": "gcd", "name": "GCD", "row": 2, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
            { "id": 23, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [3, 3], "digit": [3, 3], "after_decimal": [], },
            { "id": 24, "operation_type_id": 0, "short_name": "lcm", "name": "LCM", "row": 2, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
        ]
    },
    {
        "level": "10",
        "operations": [
            { "id": 1, "operation_type_id": 0, "short_name": "a_s", "name": "Add & Subtract", "row": 20, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
            { "id": 2, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "decimal", "show_digit": [2, 2], "digit": [1, 1], "after_decimal": [1], },
            { "id": 3, "operation_type_id": 0, "short_name": "mul", "name": "Multiplication", "row": 2, "type": "whole", "show_digit": [2, 2], "digit": [2, 2], "after_decimal": [], },
            { "id": 4, "operation_type_id": 0, "short_name": "lcm", "name": "LCM", "row": 2, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
            { "id": 5, "operation_type_id": 0, "short_name": "gcd", "name": "GCD", "row": 2, "type": "whole", "show_digit": [2], "digit": [2], "after_decimal": [], },
            { "id": 6, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "whole", "show_digit": [3, 2], "digit": [3, 2], "after_decimal": [], },
            { "id": 7, "operation_type_id": 0, "short_name": "div", "name": "Division", "row": 2, "type": "decimal", "show_digit": [2, 2], "digit": [1, 1], "after_decimal": [1, 1], },
            { "id": 8, "operation_type_id": 0, "short_name": "sqrt", "name": "Square Root", "row": 1, "type": "whole", "show_digit": [3], "digit": [3], "after_decimal": [], },
            { "id": 9, "operation_type_id": 0, "short_name": "sqrt", "name": "Square Root", "row": 1, "type": "whole", "show_digit": [4], "digit": [4], "after_decimal": [], },
            { "id": 10, "operation_type_id": 0, "short_name": "sqrt", "name": "Square Root", "row": 1, "type": "whole", "show_digit": [5], "digit": [5], "after_decimal": [], },
            { "id": 11, "operation_type_id": 0, "short_name": "per", "name": "Percentage", "row": 2, "type": "whole", "show_digit": [1, 2], "digit": [1, 2], "after_decimal": [], },
            { "id": 12, "operation_type_id": 0, "short_name": "cbrt", "name": "Cube Root", "row": 1, "type": "whole", "show_digit": [3], "digit": [3], "after_decimal": [], },
            { "id": 13, "operation_type_id": 0, "short_name": "cbrt", "name": "Cube Root", "row": 1, "type": "whole", "show_digit": [4], "digit": [4], "after_decimal": [], },
            { "id": 14, "operation_type_id": 0, "short_name": "cbrt", "name": "Cube Root", "row": 1, "type": "whole", "show_digit": [5], "digit": [5], "after_decimal": [], },
        ]
    }];

const QuestionType = ['a_s', 'div', 'mul', 'sqrt', 'lcm', 'per', 'gcd', 'cbrt'];

const QuestionTypeFullName = [
    'Addition & Subtraction',
    'Division',
    'Multiplication',
    'Square Root',
    'LCM',
    'Percentage',
    'GCD',
    'Cube Root'
];

export default LevelData;
export { QuestionType, QuestionTypeFullName };

