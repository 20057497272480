import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../../../Common/LoadingSpinner"
import LabelImportantIcon from "@mui/icons-material/LabelImportant";

import { useParams } from "react-router-dom";
import Heading from "../../../../Common/Heading";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from "axios";
import url from "../../../../Common/URL";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useSelector } from "react-redux";
import { equal } from "mathjs";

const TestPreviewComp = () => {

    const { test_name, id, stu_ans, st_uname, date_time } = useParams();
    const [load, setLoad] = useState(true);

    useEffect(() => {
        loadQuestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //----------------- head_id_mm = head id for Mind math -----------------------

    const [head_id_mm, setHead_id_mm] = useState({
        table1: [], table2: [], table3: [],
    });

    //----------------- head_id_ab = head id for Abacus -----------------------

    const [head_id_ab, setHead_id_ab] = useState({
        table1: [], table2: [], table3: [], table4: [], table5: [], table6: [], table7: []
    });

    //----------------- answer_mm = answer for Mind math -----------------------

    const [answer_mm, setAnswer_mm] = useState({
        answer1: [], answer2: [], answer3: [],
    });

    //----------------- stu_ans_mm = answer for Mind math which student has submitted -----------------------
    const [stu_ans_mm, setStu_ans_mm] = useState({
        answer1: [], answer2: [], answer3: [],
    });

    //----------------- answer_ab = answer for Abacus -----------------------

    const [answer_ab, setAnswer_ab] = useState({
        answer1: [], answer2: [], answer3: [], answer4: [], answer5: [], answer6: [], answer7: [],
    });

    //----------------- stu_ans_ab = answer for Abacus which student has submitted ----------------------

    const [stu_ans_ab, setStu_ans_ab] = useState({
        answer1: [], answer2: [], answer3: [], answer4: [], answer5: [], answer6: [], answer7: [],
    });

    //----------------- data_mm = data for mindmath table ------------------
    const [data_mm, setData_mm] = useState({
        main_arr1: [], main_arr2: [], main_arr3: [],
    });

    //----------------- data_ab = data for Abacus table ------------------
    const [data_ab, setData_ab] = useState({
        main_arr1: [], main_arr2: [], main_arr3: [], main_arr4: [], main_arr5: [],
        main_arr6: [], main_arr7: []
    });


    // ==========================This Function Removes the empty bottom rows of any Table =====================

    const isEmpty = x => x[0].filter(Boolean).length === 0 ? true : false;

    // ====================================================================

    var answer1_mm = [], answer2_mm = [], answer3_mm = [];

    var answer1_ab = [], answer2_ab = [], answer3_ab = [], answer4_ab = [], answer5_ab = [],
        answer6_ab = [], answer7_ab = [];

    var length_mm, length_ab;


    const loadQuestions = async () => {


        var All_Abacus_Ans = [], All_MM_Ans = [];

        // ================  For Mind-Math
        var Table1_id_mm = [], Table1_arr1_mm = [], Table1_arr2_mm = [], Table1_arr3_mm = [], Table1_arr4_mm = [], Table1_arr5_mm = [],
            Table2_id_mm = [], Table2_arr1_mm = [], Table2_arr2_mm = [], Table2_arr3_mm = [], Table2_arr4_mm = [], Table2_arr5_mm = [],
            Table3_id_mm = [], Table3_arr1_mm = [], Table3_arr2_mm = [], Table3_arr3_mm = [], Table3_arr4_mm = [], Table3_arr5_mm = [];
        // ================= For Abacus
        var Table1_id_ab = [], Table1_arr1_ab = [], Table1_arr2_ab = [], Table1_arr3_ab = [], Table1_arr4_ab = [], Table1_arr5_ab = [], Table1_arr6_ab = [], Table1_arr7_ab = [],
            Table2_id_ab = [], Table2_arr1_ab = [], Table2_arr2_ab = [], Table2_arr3_ab = [], Table2_arr4_ab = [], Table2_arr5_ab = [], Table2_arr6_ab = [], Table2_arr7_ab = [],
            Table3_id_ab = [], Table3_arr1_ab = [], Table3_arr2_ab = [], Table3_arr3_ab = [], Table3_arr4_ab = [], Table3_arr5_ab = [], Table3_arr6_ab = [], Table3_arr7_ab = [],
            Table4_id_ab = [], Table4_arr1_ab = [], Table4_arr2_ab = [], Table4_arr3_ab = [], Table4_arr4_ab = [], Table4_arr5_ab = [], Table4_arr6_ab = [], Table4_arr7_ab = [],
            Table5_id_ab = [], Table5_arr1_ab = [], Table5_arr2_ab = [], Table5_arr3_ab = [], Table5_arr4_ab = [], Table5_arr5_ab = [], Table5_arr6_ab = [], Table5_arr7_ab = [],
            Table6_id_ab = [], Table6_arr1_ab = [], Table6_arr2_ab = [], Table6_arr3_ab = [], Table6_arr4_ab = [], Table6_arr5_ab = [], Table6_arr6_ab = [], Table6_arr7_ab = [],
            Table7_id_ab = [], Table7_arr1_ab = [], Table7_arr2_ab = [], Table7_arr3_ab = [], Table7_arr4_ab = [], Table7_arr5_ab = [], Table7_arr6_ab = [], Table7_arr7_ab = [];

        var Table1_arr_mm = [], Table2_arr_mm = [], Table3_arr_mm = []; // For Mind-Math

        var Table1_arr_ab = [], Table2_arr_ab = [], Table3_arr_ab = [], Table4_arr_ab = [], Table5_arr_ab = [],
            Table6_arr_ab = [], Table7_arr_ab = []; //For Abacus

        var arr1_mm = [], arr2_mm = [], arr3_mm = []; //For Mind-Math

        var arr1_ab = [], arr2_ab = [], arr3_ab = [], arr4_ab = [], arr5_ab = [],
            arr6_ab = [], arr7_ab = []; // For Abacus


        // ======================================== FETCHING MIND- MATH QUESTIONS ======================================================
        // =============================================================================================================================

        // const obj_mm = { uname:sessionStorage.getItem("username"), level_name:level_name , test_type:test_type,category_name:"Mind Math" }

        const obj_mm = { uname: sessionStorage.getItem("username"), test_id: id, category_name: "Mind Math" }

        await axios.post(url + "php/questions/questions_level.php", obj_mm)
            .then((response) => {
                // console.log(response);
                if (response.data.success) {
                    var a = JSON.parse(response.data.data);
                    length_mm = a.length;  // storing in variable ,so that we can create header id till the length
                    // console.log(length_mm);

                    Table1_arr_mm.push((a.splice(0, 10)));
                    Table2_arr_mm.push((a.splice(0, 10)));
                    Table3_arr_mm.push((a.splice(0, 10)));


                    //Storing header values for the 3 tables
                    var x = [...Array(length_mm).keys()];
                    x = x.map(x => x + 1);
                    // console.log(x)
                    Table1_id_mm.push((x.splice(0, 10)));
                    Table2_id_mm.push((x.splice(0, 10)));
                    Table3_id_mm.push((x.splice(0, 10)));

                    setHead_id_mm({
                        table1: Table1_id_mm, table2: Table2_id_mm, table3: Table3_id_mm,
                    })

                    //Storing Answer of each questions for the 3 Mind Math tables
                    answer1_mm.push(Table1_arr_mm.map((x) => x.map(x => x.answer)));
                    answer2_mm.push(Table2_arr_mm.map((x) => x.map(x => x.answer)));
                    answer3_mm.push(Table3_arr_mm.map((x) => x.map(x => x.answer)));

                    setAnswer_mm({
                        answer1: answer1_mm, answer2: answer2_mm, answer3: answer3_mm,
                    })

                    let arr_mm = [...answer1_mm, ...answer2_mm, ...answer3_mm];

                    arr_mm.forEach(x => {
                        for (let i = 0; i < x.length; i++) {
                            All_MM_Ans.push(...x[i]);
                        }
                    });
                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 1
                    Table1_arr1_mm.push(Table1_arr_mm.map((x) => x.map(x => x.ques_num1)))
                    Table1_arr2_mm.push(Table1_arr_mm.map((x) => x.map(x => x.ques_num2)))
                    Table1_arr3_mm.push(Table1_arr_mm.map((x) => x.map(x => x.ques_num3)))
                    Table1_arr4_mm.push(Table1_arr_mm.map((x) => x.map(x => x.ques_num4)))
                    Table1_arr5_mm.push(Table1_arr_mm.map((x) => x.map(x => x.ques_num5)))

                    // Now storing the above 3 arrays into One array named "arr1" 
                    arr1_mm = [...Table1_arr1_mm, ...Table1_arr2_mm, ...(Table1_arr3_mm.every(isEmpty) ? [] : Table1_arr3_mm), ...(Table1_arr4_mm.every(isEmpty) ? [] : Table1_arr4_mm), ...(Table1_arr5_mm.every(isEmpty) ? [] : Table1_arr5_mm)];
                    /*----------------------------------------------------------------------*/

                    // Storing Values for table 2
                    Table2_arr1_mm.push(Table2_arr_mm.map((x) => x.map(x => x.ques_num1)))
                    Table2_arr2_mm.push(Table2_arr_mm.map((x) => x.map(x => x.ques_num2)))
                    Table2_arr3_mm.push(Table2_arr_mm.map((x) => x.map(x => x.ques_num3)))
                    Table2_arr4_mm.push(Table2_arr_mm.map((x) => x.map(x => x.ques_num4)))
                    Table2_arr5_mm.push(Table2_arr_mm.map((x) => x.map(x => x.ques_num5)))

                    // Now storing the above 4 arrays into One array named "arr2" 
                    arr2_mm = [...Table2_arr1_mm, ...Table2_arr2_mm, ...(Table2_arr3_mm.every(isEmpty) ? [] : Table2_arr3_mm), ...(Table2_arr4_mm.every(isEmpty) ? [] : Table2_arr4_mm), ...(Table2_arr5_mm.every(isEmpty) ? [] : Table2_arr5_mm)];
                    /*----------------------------------------------------------------------*/

                    // Storing Values for table 3
                    Table3_arr1_mm.push(Table3_arr_mm.map((x) => x.map(x => x.ques_num1)))
                    Table3_arr2_mm.push(Table3_arr_mm.map((x) => x.map(x => x.ques_num2)))
                    Table3_arr3_mm.push(Table3_arr_mm.map((x) => x.map(x => x.ques_num3)))
                    Table3_arr4_mm.push(Table3_arr_mm.map((x) => x.map(x => x.ques_num4)))
                    Table3_arr5_mm.push(Table3_arr_mm.map((x) => x.map(x => x.ques_num5)))

                    // Now storing the above 4 arrays into One array named "arr3" 
                    arr3_mm = [...Table3_arr1_mm, ...Table3_arr2_mm, ...(Table3_arr3_mm.every(isEmpty) ? [] : Table3_arr3_mm), ...(Table3_arr4_mm.every(isEmpty) ? [] : Table3_arr4_mm), ...(Table3_arr5_mm.every(isEmpty) ? [] : Table3_arr5_mm)];

                    /*----------------------------------------------------------------------*/

                    setData_mm({
                        main_arr1: arr1_mm, main_arr2: arr2_mm, main_arr3: arr3_mm,
                    })
                } else {
                    alert("Unfortunately, the preview is not available at the moment. Please try again later");
                    window.close();
                    setData_mm([]);
                }
            }).catch((err) => console.log(err));


        // ======================================== NOW FETCHING ABACUS QUESTIONS ======================================================
        // =============================================================================================================================


        // const obj_ab = {uname:sessionStorage.getItem("username"), level_name:level_name ,  test_type:test_type,category_name:"Abacus"}

        const obj_ab = { uname: sessionStorage.getItem("username"), test_id: id, category_name: "Abacus" }

        await axios.post(url + "php/questions/questions_level.php", obj_ab)
            .then((response) => {
                // console.log(response);
                if (response.data.success) {
                    var a = JSON.parse(response.data.data);
                    length_ab = a.length;  // storing in variable ,so that we can create header id till the length
                    // console.log(length_ab);

                    Table1_arr_ab.push((a.splice(0, 10)));
                    Table2_arr_ab.push((a.splice(0, 10)));
                    Table3_arr_ab.push((a.splice(0, 10)));
                    Table4_arr_ab.push((a.splice(0, 10)));
                    Table5_arr_ab.push((a.splice(0, 10)));
                    Table6_arr_ab.push((a.splice(0, 10)));
                    Table7_arr_ab.push((a.splice(0, 10)));

                    //Storing header values for the 10 tables
                    var x = [...Array(length_ab).keys()];
                    x = x.map(x => x + 1);
                    // console.log(x)
                    Table1_id_ab.push((x.splice(0, 10)));
                    Table2_id_ab.push((x.splice(0, 10)));
                    Table3_id_ab.push((x.splice(0, 10)));
                    Table4_id_ab.push((x.splice(0, 10)));
                    Table5_id_ab.push((x.splice(0, 10)));
                    Table6_id_ab.push((x.splice(0, 10)));
                    Table7_id_ab.push((x.splice(0, 10)));

                    setHead_id_ab({
                        table1: Table1_id_ab, table2: Table2_id_ab, table3: Table3_id_ab, table4: Table4_id_ab, table5: Table5_id_ab,
                        table6: Table6_id_ab, table7: Table7_id_ab
                    })

                    //Storing Answer of each questions for the 3 Mind Math tables
                    answer1_ab.push(Table1_arr_ab.map((x) => x.map(x => x.answer)));
                    answer2_ab.push(Table2_arr_ab.map((x) => x.map(x => x.answer)));
                    answer3_ab.push(Table3_arr_ab.map((x) => x.map(x => x.answer)));
                    answer4_ab.push(Table4_arr_ab.map((x) => x.map(x => x.answer)));
                    answer5_ab.push(Table5_arr_ab.map((x) => x.map(x => x.answer)));
                    answer6_ab.push(Table6_arr_ab.map((x) => x.map(x => x.answer)));
                    answer7_ab.push(Table7_arr_ab.map((x) => x.map(x => x.answer)));

                    setAnswer_ab({
                        answer1: answer1_ab, answer2: answer2_ab, answer3: answer3_ab, answer4: answer4_ab, answer5: answer5_ab,
                        answer6: answer6_ab, answer7: answer7_ab
                    })

                    let arr_ab = [...answer1_ab, ...answer2_ab, ...answer3_ab, ...answer4_ab, ...answer5_ab,
                    ...answer6_ab, ...answer7_ab];

                    arr_ab.forEach(x => {
                        for (let i = 0; i < x.length; i++) {
                            All_Abacus_Ans.push(...x[i]);
                        }
                    });

                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 1
                    Table1_arr1_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table1_arr2_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table1_arr3_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table1_arr4_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table1_arr5_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table1_arr6_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table1_arr7_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 3 arrays into One array named "arr1" 
                    arr1_ab = [...Table1_arr1_ab, ...Table1_arr2_ab, ...(Table1_arr3_ab.every(isEmpty) ? [] : Table1_arr3_ab), ...(Table1_arr4_ab.every(isEmpty) ? [] : Table1_arr4_ab), ...(Table1_arr5_ab.every(isEmpty) ? [] : Table1_arr5_ab), ...(Table1_arr6_ab.every(isEmpty) ? [] : Table1_arr6_ab), ...(Table1_arr7_ab.every(isEmpty) ? [] : Table1_arr7_ab)];
                    /*----------------------------------------------------------------------*/

                    // Storing Values for table 2
                    Table2_arr1_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table2_arr2_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table2_arr3_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table2_arr4_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table2_arr5_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table2_arr6_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table2_arr7_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 4 arrays into One array named "arr2" 
                    arr2_ab = [...Table2_arr1_ab, ...Table2_arr2_ab, ...(Table2_arr3_ab.every(isEmpty) ? [] : Table2_arr3_ab), ...(Table2_arr4_ab.every(isEmpty) ? [] : Table2_arr4_ab), ...(Table2_arr5_ab.every(isEmpty) ? [] : Table2_arr5_ab), ...(Table2_arr6_ab.every(isEmpty) ? [] : Table2_arr6_ab), ...(Table2_arr7_ab.every(isEmpty) ? [] : Table2_arr7_ab)];
                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 3
                    Table3_arr1_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table3_arr2_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table3_arr3_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table3_arr4_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table3_arr5_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table3_arr6_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table3_arr7_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 4 arrays into One array named "arr3" 
                    arr3_ab = [...Table3_arr1_ab, ...Table3_arr2_ab, ...(Table3_arr3_ab.every(isEmpty) ? [] : Table3_arr3_ab), ...(Table3_arr4_ab.every(isEmpty) ? [] : Table3_arr4_ab), ...(Table3_arr5_ab.every(isEmpty) ? [] : Table3_arr5_ab), ...(Table3_arr6_ab.every(isEmpty) ? [] : Table3_arr6_ab), ...(Table3_arr7_ab.every(isEmpty) ? [] : Table3_arr7_ab)];

                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 4
                    Table4_arr1_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table4_arr2_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table4_arr3_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table4_arr4_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table4_arr5_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table4_arr6_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table4_arr7_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 3 arrays into One array named "arr1" 
                    arr4_ab = [...Table4_arr1_ab, ...Table4_arr2_ab, ...(Table4_arr3_ab.every(isEmpty) ? [] : Table4_arr3_ab), ...(Table4_arr4_ab.every(isEmpty) ? [] : Table4_arr4_ab), ...(Table4_arr5_ab.every(isEmpty) ? [] : Table4_arr5_ab), ...(Table4_arr6_ab.every(isEmpty) ? [] : Table4_arr6_ab), ...(Table4_arr7_ab.every(isEmpty) ? [] : Table4_arr7_ab)];
                    /*----------------------------------------------------------------------*/

                    // Storing Values for table 5
                    Table5_arr1_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table5_arr2_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table5_arr3_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table5_arr4_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table5_arr5_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table5_arr6_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table5_arr7_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 4 arrays into One array named "arr2" 
                    arr5_ab = [...Table5_arr1_ab, ...Table5_arr2_ab, ...(Table5_arr3_ab.every(isEmpty) ? [] : Table5_arr3_ab), ...(Table5_arr4_ab.every(isEmpty) ? [] : Table5_arr4_ab), ...(Table5_arr5_ab.every(isEmpty) ? [] : Table5_arr5_ab), ...(Table5_arr6_ab.every(isEmpty) ? [] : Table5_arr6_ab), ...(Table5_arr7_ab.every(isEmpty) ? [] : Table5_arr7_ab)];
                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 6
                    Table6_arr1_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table6_arr2_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table6_arr3_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table6_arr4_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table6_arr5_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table6_arr6_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table6_arr7_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 4 arrays into One array named "arr3" 
                    arr6_ab = [...Table6_arr1_ab, ...Table6_arr2_ab, ...(Table6_arr3_ab.every(isEmpty) ? [] : Table6_arr3_ab), ...(Table6_arr4_ab.every(isEmpty) ? [] : Table6_arr4_ab), ...(Table6_arr5_ab.every(isEmpty) ? [] : Table6_arr5_ab), ...(Table6_arr6_ab.every(isEmpty) ? [] : Table6_arr6_ab), ...(Table6_arr7_ab.every(isEmpty) ? [] : Table6_arr7_ab)];

                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 7
                    Table7_arr1_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table7_arr2_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table7_arr3_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table7_arr4_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table7_arr5_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table7_arr6_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table7_arr7_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 3 arrays into One array named "arr1" 
                    arr7_ab = [...Table7_arr1_ab, ...Table7_arr2_ab, ...(Table7_arr3_ab.every(isEmpty) ? [] : Table7_arr3_ab), ...(Table7_arr4_ab.every(isEmpty) ? [] : Table7_arr4_ab), ...(Table7_arr5_ab.every(isEmpty) ? [] : Table7_arr5_ab), ...(Table7_arr6_ab.every(isEmpty) ? [] : Table7_arr6_ab), ...(Table7_arr7_ab.every(isEmpty) ? [] : Table7_arr7_ab)];
                    /*----------------------------------------------------------------------*/

                    // console.log(arr1_ab);console.log(arr2_ab);console.log(arr3_ab);console.log(arr4_ab);console.log(arr5_ab);
                    /*----------------------------------------------------------------------*/

                    setData_ab({
                        main_arr1: arr1_ab, main_arr2: arr2_ab, main_arr3: arr3_ab, main_arr4: arr4_ab, main_arr5: arr5_ab,
                        main_arr6: arr6_ab, main_arr7: arr7_ab
                    })
                } else {
                    alert("Unfortunately, the preview is not available at the moment. Please try again later");
                    window.close();
                    setData_ab([]);
                }
            }).then(() => {
                if (stu_ans === "0")
                    setLoad(false);
                else if (stu_ans === "1") {

                    var obj_ans = { uname: st_uname, id: id, test_date_time: date_time };

                    axios.post(url + "php/questions/test_answers.php", obj_ans)
                        .then((response) => {
                            // console.log(response);
                            if (response.data.message === "User Not Login") {
                                alert("Student has been removed from portal, and corresponding answer records has also been removed.");
                                window.close();
                            } else {

                                var a = JSON.parse(response.data.data);
                                // console.log(a);

                                if (!a) {   // if a equals null or undefined 
                                    alert("The test answers are not present.");
                                    window.close();
                                }

                                var mind_math = [], abacus = [];

                                a.forEach(e => {
                                    if (e.category === "Mind Math") {                     // This will filter Mind-math and Abacus Answers
                                        mind_math.push(e.ans);
                                    } else {
                                        abacus.push(e.ans);
                                    }
                                });

                                for (let i = 0; i < mind_math.length; i++) {            //This will check whether Student's answers are right or wrong
                                    if (mind_math[i] === "") {
                                        mind_math[i] = mind_math[i] + "--";
                                    }
                                    else if (equal(mind_math[i], All_MM_Ans[i])) {
                                        let diff = "_";
                                        mind_math[i] = mind_math[i] + diff;              // diff will add an underscore after right answers
                                    }
                                }


                                for (let i = 0; i < abacus.length; i++) {           //This will check whether Student's answers are right or wrong
                                    if (abacus[i] === "") {
                                        abacus[i] = abacus[i] + "--";
                                    }
                                    else if (equal(abacus[i], All_Abacus_Ans[i])) {
                                        let diff = "_";
                                        abacus[i] = abacus[i] + diff;            // diff will add an underscore after right answers
                                    }
                                }


                                setStu_ans_mm({
                                    answer1: mind_math.splice(0, 10), answer2: mind_math.splice(0, 10), answer3: mind_math.splice(0, 10)
                                })

                                setStu_ans_ab({
                                    answer1: abacus.splice(0, 10), answer2: abacus.splice(0, 10), answer3: abacus.splice(0, 10), answer4: abacus.splice(0, 10), answer5: abacus.splice(0, 10),
                                    answer6: abacus.splice(0, 10), answer7: abacus.splice(0, 10)
                                })
                            }
                        })
                        .then(() => setLoad(false))
                        .catch((err) => console.log(err));
                }
            })
            .catch((err) => console.log(err));
    }

    const GetDate = () => {
        let date = new Date(date_time).toLocaleString();
        return date;
    }


    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>


                {stu_ans === "1" ?
                    <Heading name={`${test_name} - [ ${GetDate()} ]`} /> :
                    <Heading name={`Test Preview : ${test_name}`} />
                }


                {/* ======================================================================== Test-Table --> Start here ====================================================================================== */}
                {load ? <LoadingSpinner />
                    :
                    <div className="container-fluid">

                        {stu_ans === "1" &&
                            <div className="row ">
                                <div className="col-12 mx-auto ">
                                    <h6 ><FiberManualRecordIcon style={{ color: "green" }} /> = Right Answer</h6>
                                    <h6 ><FiberManualRecordIcon style={{ color: "red" }} /> = Wrong Answer</h6>
                                    <h6><span className="fw-bolder ms-2 me-1">--</span> = Not Answered</h6>
                                </div><hr className="w-100" />
                            </div>
                        }

                        {/* ====================================================================================================== */}
                        {/* ======================================== Mind - Math Part ============================================ */}

                        <div className="col-12 mx-auto ">
                            <h5><LabelImportantIcon /> Mind-Math</h5>
                            <hr />
                        </div>

                        <div className="row">
                            <div className="col-12 mx-auto ">
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>{head_id_mm.table1.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}</TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data_mm.main_arr1.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {stu_ans_mm.answer1.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }

                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer_mm.answer1.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">
                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>{head_id_mm.table2.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}</TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data_mm.main_arr2.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {stu_ans_mm.answer2.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }

                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer_mm.answer2.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">
                            <div className="col-12 mx-auto">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>

                                                {head_id_mm.table3.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data_mm.main_arr3.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {stu_ans_mm.answer3.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }

                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer_mm.answer3.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>



                        {/* ====================================================================================================== */}
                        {/* ============================================= Abacus Part ============================================ */}

                        <div className="col-12 mx-auto ">
                            <h5><LabelImportantIcon /> Abacus</h5>
                            <hr />
                        </div>

                        <div className="row mb-5">
                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>
                                                {head_id_ab.table1.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data_ab.main_arr1.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {stu_ans_ab.answer1.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }

                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer_ab.answer1.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">
                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>
                                                {head_id_ab.table2.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data_ab.main_arr2.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {stu_ans_ab.answer2.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }

                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer_ab.answer2.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">
                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>
                                                {head_id_ab.table3.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data_ab.main_arr3.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {stu_ans_ab.answer3.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }
                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer_ab.answer3.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">
                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>
                                                {head_id_ab.table4.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data_ab.main_arr4.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}
                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {stu_ans_ab.answer4.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }

                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer_ab.answer4.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">
                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>
                                                {head_id_ab.table5.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data_ab.main_arr5.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {stu_ans_ab.answer5.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }
                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer_ab.answer5.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">
                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>
                                                {head_id_ab.table6.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data_ab.main_arr6.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}
                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {stu_ans_ab.answer6.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }

                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer_ab.answer6.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="row my-5">
                            <div className="col-12 mx-auto ">

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>
                                                {head_id_ab.table7.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {data_ab.main_arr7.map(x => x.map((x, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                    </TableRow>
                                                )
                                            }))}

                                            {stu_ans === "1" &&
                                                <TableRow>
                                                    {stu_ans_ab.answer7.map((x, i) => <TableCell key={i} style={{ color: x.includes("_") ? "green" : (x.includes("--") ? "black" : "red") }} className="fw-bolder text-center">{x.replace("_", "")}</TableCell>)}
                                                </TableRow>
                                            }
                                            <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                {answer_ab.answer7.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                    </div>}
            </div>
        </>

    )
}

export default TestPreviewComp;