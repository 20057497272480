import React from "react"
import Navbar from "../../../Common/Navbar"
import Footer from "../../../Common/Footer"
import Sidebar from "../../../Sidebar/Sidebar"
import ViewQuestionComp from "./Component/ViewQuestionComp"


const ViewQuestion =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <ViewQuestionComp />
        </div>
        <Footer />
        </>
    )
}

export default ViewQuestion