import React, { useState, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Heading from "../../../../Common/Heading"
// import Input from "../../../Setup/Student/Component/Input"
// import axios from "axios"
// import url from "../../../../Common/URL"
import { useReactToPrint } from 'react-to-print';
import Template from '../certificate_template.png';
import Text from '../certificate_text.png';
import Logo from "../logo.png";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const DownloadCertificateComp = () => {

    const [state, setState] = useState({
        name: "", medal_category: "", date: "", level: "", medal_color: "gold"
    })

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: state.name,
    });

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'} style={{ overflowX: 'auto' }}>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Students</li>
                        <li className="breadcrumb-item active" aria-current="page">Download Certificate</li>
                    </ol>
                </nav>
                <Heading name="Download Certificate" />

                <div className="container-fluid">
                    <div className="row mb-5">
                        <div className="col-12 d-flex justify-content-end mx-auto">
                            <Button variant="contained" className="ms-auto" onClick={handlePrint}>Download Certificate</Button>
                        </div>
                    </div>

                    <div ref={componentRef} style={{
                        backgroundImage: `url(${Template})`,
                        backgroundRepeat: 'no-repeat',
                        height: '99rem',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        position: 'relative',
                        display: 'inline-block',

                    }}>
                        {/* Below Image tag is used to fix the width problem of printable div */}
                        <img style={{ height: '99rem', visibility: 'hidden' }} src={`${Template}`} alt="certificate"></img>

                        <p className="text-muted" style={{ position: 'absolute', top: '20%', left: '10%', fontWeight: "500" }}>DATE &nbsp;&nbsp;_____________________</p>

                        <img src={`${Text}`} className="position-absolute"
                            style={{ bottom: '60%', width: "70%", left: '50%', marginLeft: "-35%" }}
                            alt="Certificate Text"></img>

                        <hr className="position-absolute border-0" style={{ height: '.1em', backgroundColor: " #e9992260", bottom: '52%', width: "70%", left: '50%', marginLeft: "-35%" }} ></hr>
                        <p className="position-absolute text-center fw-bolder" style={{ color: "#124974", bottom: '42%', width: "70%", left: '50%', marginLeft: "-35%", fontSize: '1.5em' }}
                        >For Successfully Completing The Guruscool <br /> Examination Level <span className="fw-bold" style={{ color: "#e99922" }}> _________________</span></p>


                        <p className="position-absolute text-center fw-bolder" style={{ color: "#124974", bottom: '35%', width: "70%", left: '50%', marginLeft: "-35%", fontSize: '1.5em' }}
                        >Medal Category : <span style={{ color: "#e99922" }}><i style={{ color: state.medal_color }} className="fa fa-star mx-1"></i> GOLD CHAMPION</span></p>

                        <img src={`${Logo}`} className="position-absolute"
                            style={{ bottom: '20%', width: "34%", left: '50%', marginLeft: "-17%" }}
                            alt="Certificate Logo"></img>

                        <div className="px-2 text-center" style={{ position: 'absolute', width: '28%', bottom: '6%', right: "20%" }}>
                            <hr className="border-0" style={{ height: '.2em', backgroundColor: "#80808050" }} />
                            <h6 className="fw-bold" style={{ color: "#124974", fontSize: '1.2em' }}>SREEJA CHEDAYATH</h6>
                            <p className="text-muted" style={{ color: '#80808020', fontWeight: '500' }}>Director Guruscool</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DownloadCertificateComp