// This Function Convert 24 hour Time into 12 Hour time 
const StringToTime = (props) => {
    const timeString12hr = new Date('1970-01-01T' + props + 'Z')   // Here you can use any Date, it will not 
        .toLocaleTimeString({},                                            // affect the code , the purpose of code is 
            { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }   //Just to return the time
        );
    return timeString12hr;
}

// This Function Convert Date time(24 hr) into Date time(12 hr).
const FormatDateTime = (props) => {
    const timeString12hr = new Date(props).toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });
    // console.log(timeString12hr);
    return timeString12hr;
}


const CurrentDate = () => {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    var newdate = year + "-" + month + "-" + day;
    return newdate;
}

const ModifyDate = (date) => {
    var dateObj = new Date(date.toString());
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    var newdate = year + "-" + month + "-" + day;
    return newdate;
}


export default StringToTime;
export { FormatDateTime, CurrentDate, ModifyDate };