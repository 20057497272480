import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../Student/Component/Input";
import Heading from "../../../../Common/Heading";
import { toast } from "react-toastify";
import axios from "axios";
import url from "../../../../Common/URL";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Button } from "@mui/material";
import { useSelector } from "react-redux";


const AddLocationComp = () => {
    let navigate = useNavigate();
    const [usr, setUsr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [field, setField] = useState({
        uname: sessionStorage.getItem("username"),
        name: "", city: "", state: "", country: "", addressline1: "", addressline2: "", calender_link: "",
        zip: "", phone: "", location_type: "", manager_name: "", created_by: "", created_date: "",
        location_picture: null, fname: "", lname: ""
    });

    useEffect(() => {
        loadUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------------------This function will Fetch all the Existing Users-------------------------------------
    const loadUser = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        axios.post(url + "php/users.php", obj)
            .then((response) => {
                //   console.log(response)
                if (response.data.success) {
                    let a = JSON.parse(response.data.data);
                    // console.log(a);
                    var user = a.filter(x => x.role === "Teacher" || x.role === "Manager");
                    // console.log(user)
                    setUsr(user);
                } else {
                    setUsr([]);
                }

            })
            .catch((err) => console.log(err));
    };
    //   --------------------------------------OnChange function for all Inputs-------------------------------
    const onChange = (e) => {

        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        setField((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        });
    };

    // ------------------- -------------------functions for Submitting Country and state- ------------------- -------------------
    const selectCountry = (val) => {

        setField((preValue) => {
            return {
                ...preValue,
                country: val
            };
        });
    };
    const selectRegion = (val) => {

        setField((preValue) => {
            return {
                ...preValue,
                state: val
            };
        });
    };

    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData();
        data.append('image', field.location_picture);

        /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;

        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            uname: field.uname, fname: field.fname, lname: field.lname,
            name: field.name, city: field.city, state: field.state, country: field.country, addressline1: field.addressline1,
            addressline2: field.addressline2, zip: field.zip, phone: field.phone, location_type: field.location_type,
            manager_name: field.manager_name, created_by: field.uname, created_date: newdate, calender_link: field.calender_link
        };

        axios.post(url + "php/locations/add_location.php", obj)
            .then((res) => {
                // console.log(res) 
                if (res.data.success) {
                    toast.success("Location Created successfully!");
                    navigate("/location");
                } else {
                    alert("Location Creation Failed!");
                }
            }).catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/location">Location List</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Add Location</li>
                    </ol>
                </nav>

                <Heading name="Add Location" />

                <div className="container-fluid">

                    <form onSubmit={onSubmit}>

                        <div className="row mb-4">
                            <div className="col-12 d-flex justify-content-between ">
                                <h5>Location Information</h5>
                                <div>
                                    <Button variant="contained" onClick={() => navigate("/location")} >Cancel</Button>
                                    <Button variant="contained" type="submit" disabled={loading} className="ms-3 ">Save</Button>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-11 col-md-6 mx-auto">
                                {/* <Input field="Profile Picture" name="location_picture" type="file" onChange={handleInputChange} className="form-control-file" value={field.location_picture}/> */}

                                {/* <div className="mb-3 row">
                            <label className="col-sm-5 col-form-label">Location ID</label>
                            <div className="col-sm-7 col-md-7 ">
                            <input type="text" readonly className="form-control-plaintext" value="<Auto generated>"  />
                            </div>
                        </div> */}

                                <Input field="Location Name" type="text" placeholder="Add text" name="name" className="form-control" value={field.name} onChange={onChange} required={true} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Address Line 1</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea className="form-control" placeholder="Add text" name="addressline1" value={field.addressline1} onChange={onChange} required></textarea>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Address Line 2</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea className="form-control" placeholder="Add text" name="addressline2" value={field.addressline2} onChange={onChange} ></textarea>
                                    </div>
                                </div>

                                <Input field="City" type="text" placeholder="Add text" name="city" className="form-control" value={field.city} onChange={onChange} required={true} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Country</label>
                                    <div className="col-sm-7 col-md-7 ">


                                        <CountryDropdown
                                            className="form-select"
                                            name="country"
                                            value={field.country}
                                            onChange={(val) => selectCountry(val)} />

                                    </div>
                                </div>


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Province</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        {/* <select name="country" value={state.country} onChange={onChange} className="form-select">
                                    <option value="1">Country 1</option><option value="country-2">Country 2</option><option value="country-3">Country 3</option>
                                </select> */}

                                        <RegionDropdown
                                            className="form-select"
                                            name="state"
                                            country={field.country}
                                            value={field.state}
                                            onChange={(val) => selectRegion(val)} />
                                    </div>
                                </div>
                                <Input field="Zip" type="text" placeholder="Add text" name="zip" className="form-control" value={field.zip} onChange={onChange} />

                            </div>

                            <div className="col-11 col-md-6 mx-auto">


                                <Input name="phone" onChange={onChange} field="Location Contact No." type="text" placeholder="Add Number" className="form-control" value={field.phone} required={false} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Location Manager</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select name="manager_name" value={`${field.manager_name}^${field.fname}^${field.lname}`} className="form-select"
                                            onChange={(e) => {
                                                let d1 = e.target.value;
                                                setField({ ...field, manager_name: d1.split("^")[0], fname: d1.split("^")[1], lname: d1.split("^")[2] });
                                            }}
                                        >
                                            <option value="">Choose</option>
                                            {usr.map((data, i) => (<option key={i} value={`${data.username}^${data.first_name}^${data.last_name}`}>{`${data.first_name} ${data.last_name}`}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Location Type</label>
                                    <div className="form-check form-check-inline w-auto ms-3">
                                        <input className="form-check-input"
                                            type="radio"
                                            name="location_type"
                                            id="inlineRadio1"
                                            value="1"
                                            checked={field.location_type === "1"}
                                            onChange={onChange}
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio1">Direct</label>
                                    </div>
                                    <div className="form-check form-check-inline w-auto">
                                        <input className="form-check-input"
                                            type="radio"
                                            name="location_type"
                                            id="inlineRadio2"
                                            value="0"
                                            checked={field.location_type === "0"}
                                            onChange={onChange}
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio2">Franchise</label>
                                    </div>
                                </div>
                                {/* 
                        <Input field="Calendar Link" type="text" placeholder="Add Link" className="form-control" value={field.calender_link}  name="calender_link" onChange={onChange} />

                        <div className="mb-3 row ">
                            <label className="col-sm-5 col-form-label">Batch Calendar</label>
                            <div className="col-sm-7 col-md-7 d-flex align-items-center">
                                <a href="/404" download >Batch Calendar PDF &nbsp;<i className="fa fa-download"></i></a>
                            </div>
                        </div>  */}

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AddLocationComp;