import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../../../Common/Heading";

import axios from "axios";
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
import url from "../../../../Common/URL";


const LocationStudentTableComp = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
  })


  const Columns = useMemo(
    () => [
      { header: 'First Name', accessorKey: 'fname' },
      { header: 'Last Name', accessorKey: 'lname' },
      { header: 'Location', accessorKey: 'location_name' },
      { header: 'Batch', accessorKey: 'batch_name' },
      { header: 'Level', accessorKey: 'level_name' },
      { header: 'Join Date', accessorKey: 'join_date' }
    ],
    [],
  );


  // ------------------------Fetching Student table's data-------------------------------------
  useEffect(() => {
    loadStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStudents = () => {

    var location_name_arr = [], batch_name_arr = [], level_name_arr = []; // Variables declared for filtering data process, for teacher

    //================== Getting the location , level , batch for Teacher ===============
    if (sessionStorage.getItem("role") === "Teacher") {

      axios.post(url + "php/teachers/select_teacher_params.php", { uname: state.uname })
        .then((res) => {
          // console.log(res)
          if (res.data.success) {
            batch_name_arr = JSON.parse(res.data.batch_data).map(a => a.batch_name.toLowerCase());
            location_name_arr = JSON.parse(res.data.location_data).map(a => a.location_name.toLowerCase());
            level_name_arr = JSON.parse(res.data.level_data).map(a => a.level_name.toLowerCase());

            // console.log(batch_name_arr);console.log(location_name_arr);console.log(level_name_arr);
          } else {
            alert("An error occurred, Location , Level & Batch can't be fetched.")
          }
        }).catch(res => console.log(res))

    }
    //====================== Fetching all the Students ============================

    axios.post(url + "php/students/students.php", { uname: state.uname })
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = JSON.parse(response.data.data);

          //========== Filtering Students according to Teacher's -> location , level , batch  ===========
          if (sessionStorage.getItem("role") === "Teacher") {
            let filterData = a.filter(stu => {
              return location_name_arr.indexOf(stu.location_name.toLowerCase()) >= 0 &&
                batch_name_arr.indexOf(stu.batch_name.toLowerCase()) >= 0 &&
                level_name_arr.indexOf(stu.level_name.toLowerCase()) >= 0
            })
            filterData === null ? setData([]) : setData(filterData);
            setLoading(false)

            //======== Assigning all the students if the role is not equals to Location Manager  =======
          } else if (sessionStorage.getItem("role") === "Location Manager") {
            let locManagerLocations = sessionStorage.getItem("location").toLowerCase().split(',');


            let filterData = a.filter(x => {

              return locManagerLocations.indexOf(x.location_name.toLowerCase()) >= 0
            })
            filterData === null ? setData([]) : setData(filterData);
            setLoading(false);

            //======== Assigning all the students if the role is not equals to Teacher  =======
          } else {
            a === null ? setData([]) : setData(a);
            setLoading(false)
          }
        } else {
          setData([]);
          setLoading(false)
        }
      })
      .catch((err) => console.log(err));

  }

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Students</li>
            <li className="breadcrumb-item active" aria-current="page">Students List</li>
          </ol>
        </nav>
        <Heading name="Students List" />
        <div className="container-fluid">

          <div className="row my-5">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{ isLoading: loading }}
                columns={Columns}
                data={data}
                enableRowNumbers
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                
                getRowId={(originalRow) => originalRow.stud_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    const win = window.open("/student-detail/" + row.id, "_blank")
                    win.focus();
                  },
                  sx: { cursor: 'pointer' },
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LocationStudentTableComp;
