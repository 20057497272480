import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Input from "../../../Setup/Student/Component/Input"
import Heading from "../../../../Common/Heading"
import axios from "axios";
import url from "../../../../Common/URL"
import { Button } from "@mui/material";
import { useSelector } from "react-redux"

const AddFeeToAllComp = () => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        stud_id: [], stu_uname: [], fname: [], lname: [],
        fee: "", duration: "",
    })

    useEffect(() => {
        loadStudents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Students--------------------
    const loadStudents = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };
        var stu_id = [], u_name = [], fn = [], ln = [];
        await axios.post(url + "php/students/students.php", obj)
            .then((response) => {
                let a = JSON.parse(response.data.data);
                //   console.log(a);
                a.forEach(x => {
                    stu_id.push(x.stud_id);
                    u_name.push(x.user_name);
                    fn.push(x.fname);
                    ln.push(x.lname);
                })
                setState({ ...state, stud_id: stu_id, stu_uname: u_name, fname: fn, lname: ln });
            })
            .catch((err) => console.log(err));
    }

    //---------------One onChange function is used for setting the state of various types of input fields ---------------     

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }
    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        // ------------Storing form data in "obj" object fetched from various input fields-------------
        const obj = {
            uname: sessionStorage.getItem("username"),
            fee: state.fee, duration: state.duration,
            created_by: sessionStorage.getItem("username"),
        }

        const formData = new FormData();
        Object.keys(obj).forEach(key => formData.append(key, obj[key]));

        for (let i = 0; i < state.stud_id.length; i++) {
            formData.append('stud_id[]', state.stud_id[i]);
        }

        for (let i = 0; i < state.stu_uname.length; i++) {
            formData.append('stu_uname[]', state.stu_uname[i]);
        }

        for (let i = 0; i < state.fname.length; i++) {
            formData.append('fname[]', state.fname[i]);
        }

        for (let i = 0; i < state.lname.length; i++) {
            formData.append('lname[]', state.lname[i]);
        }


        // ------------Sending form data stored in "obj" object to the add_student.php file uusing AXIOS-------------

        axios.post(url + "php/student_fee/add_all_acc_fees.php", formData)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("Fee added successfully.");
                    navigate("/act-fee");
                } else {
                    alert("Unable to create student fee")
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(true);
            })

    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'} >
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Accounts</li>
                        <li className="breadcrumb-item "><Link to="/act-fee">Accounts Fees</Link></li>
                        <li className="breadcrumb-item active">Add Fee To All Students</li>
                    </ol>
                </nav>
                <Heading name="Add Fee To All Students" />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                <form onSubmit={onSubmit} id="myform">
                    <div className="container-fluid mt-3">
                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-end mx-auto">
                                <Button variant="contained" disabled={loading} type="submit" >Save</Button>
                            </div>
                        </div>

                        <div className="row">

                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto mx-md-0">

                                <h5 className="mb-4">Fee Information</h5>


                                <Input name="fee" onChange={onChange} field="Fee" type="text" className="form-control" value={state.fee} required={true} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Duration</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="duration" value={state.duration} onChange={onChange} className="form-select">
                                            <option value="" >Choose</option>
                                            <option value="Monthly" >Monthly</option><option value="Yearly" >Yearly</option><option value="Lump Sum" >Lump Sum</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default AddFeeToAllComp