import React, { useState, useEffect, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import Navbar from "../../../Common/Navbar";
import Footer from "../../../Common/Footer";
import Sidebar from "../../../Sidebar/Sidebar";
import Heading from "../../../Common/Heading"

import { toast } from "react-toastify"
import LoadingSpinner from "../../../Common/LoadingSpinner";

import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';

import axios from "axios";
import url from "../../../Common/URL";
import { useConfirm } from 'material-ui-confirm';

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";

const User = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
  })

  const Columns = useMemo(
    () => [
      {
        accessorKey: 'first_name', //access nested data with dot notation
        header: 'Name',
      },
      {
        accessorKey: 'username', //normal accessorKey
        header: 'UserName',
      },
      {
        accessorKey: 'role',
        header: 'Role',
      },
      {
        accessorKey: 'created_on',
        header: 'Created On',
      },
      {
        accessorFn: (row) => row.active === '1' ? 'Active' : 'Inactive',
        id: 'active',
        header: 'Status',
      },
    ],
    [],
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ------------------------Fetching users table data-------------------------------------
  const loadUsers = async () => {
    const obj = { uname: state.uname }
    axios.post(url + "php/users.php", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = JSON.parse(response.data.data);
        //  console.log(a);
          setData(a);
          setLoading(false)
        } else {
          setData([]);
          setLoading(false)
        }
      }).catch((err) => console.log(err));
  }

  // ---------------------------------deleting a User from the Users Table---------------------------
  function onDelete(id, resolve) {
    const delObj = {
      id: id,
      uname: state.uname,
      tokenId: 123,
    };
    axios.post(url + "php/user_delete.php", delObj)
      .then((res) => {
        // console.log(res);
        resolve();
      })
      .catch((err) => console.log(err));
  }


  return (
    <>
      <Navbar />
      <div className="d-flex mainContentArea">
        <Sidebar />
        <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-light py-1 mt-2 align-items-center">
              <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
              <li className="breadcrumb-item">Setup</li>
              <li className="breadcrumb-item active" aria-current="page">Users</li>
            </ol>
          </nav>

          <Heading name="Users" />

          <div className="container-fluid">

            <div className="row my-4">
              <div className="col-12 mx-auto d-flex justify-content-end">
                <Button variant="contained" onClick={() => navigate("/add-user")} >Add User <Add /></Button>
              </div>
            </div>
            <div className="row ">
              <div className="col-12 mx-auto mb-5">
                <MaterialReactTable
                  state={{ isLoading: loading }}

                  columns={Columns}
                  data={data}
                  enableRowNumbers
                  enableRowSelection
                  initialState={{ density: 'compact' }}
                  enableDensityToggle={false}
                  
                  getRowId={(originalRow) => originalRow.id}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => navigate("/view-user/" + row.id),
                    sx: { cursor: 'pointer' },
                  })}

                  renderTopToolbarCustomActions={({ table }) => (
                    <IconButton
                      disabled={table.getSelectedRowModel().rows.length ? false : true}
                      onClick={() => {
                        confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                          .then(() => {
                            let selectedRows = table.getSelectedRowModel().rows;
                            // console.log(selectedRows);
                            const delPromise = selectedRows.map((row) => {
                              return new Promise((resolve, reject) => {
                                onDelete(row.id, resolve);
                              })
                            })
                            Promise.all(delPromise).then(() => {
                              loadUsers();
                              table.resetRowSelection();
                              toast.error(selectedRows.length + " Row(s) Deleted!")
                            });
                          })
                      }}
                    ><DeleteOutline />
                    </IconButton>
                  )}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default User

