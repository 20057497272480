import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import React, { useEffect, useState } from "react";
import Countdown from 'react-countdown';
import { toast } from "react-toastify";

// import { makeStyles } from '@mui/styles/makeStyles';
// import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
// import Fade from '@mui/material/Fade';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Heading from "../../../../Common/Heading";
import url from "../../../../Common/URL";

import Abacus from "../../../../Common/Abacus";

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useConfirm } from 'material-ui-confirm';
import GenerateInputRow from "../../../../Common/GenerateInputRow";
import AlertBlockForQuestionPapers from '../../../../Common/AlertBlockForQuestionPapers';

const TestSheetComp = () => {
    const confirm = useConfirm();
    const navigate = useNavigate();
    const location = useLocation();

    // ====================== Code to confirm page reload ==============================

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };
        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    // ================== Code to confirm Route-Change / Back-Press ====================

    useEffect(() => {
        console.log(location);
    }, []);

    // =================================================================================


    const [backdrop, setBackdrop] = useState(false);
    const ans = {};  // This is used to store answers in obj , which is sent in axios post while submitting the Test

    const [mindMathAutoSubmit, setMindMathAutoSubmit] = useState(false);  //This  wil check whether the mind-math part is auto submitted or not

    var total_test_duration_mm = "null", difference_mm,  // this variables are used to calculate the time bettween 
        total_test_duration_ab = "null", difference_ab;  // start and end of the test for mind math and abacus.

    const [testStartTime_mm, setTestStartTime_mm] = useState("")
    const [testEndTime_mm, setTestEndTime_mm] = useState("")

    const [testStartTime_ab, setTestStartTime_ab] = useState("")
    var testEndTime_ab;

    const { test_name, level_name, yt_url, test_type, id } = useParams();
    // const classes = useStyles();


    // const [name, setName] = useState({
    //     fname:"" , lname:""
    // })

    // Function will show generated test in table and make the submit button appear  
    const generateTest = () => {
        setBackdrop(true);
        setData_mm({
            main_arr1: [], main_arr2: [], main_arr3: []
        })

        setData_ab({
            main_arr1: [], main_arr2: [], main_arr3: [], main_arr4: [], main_arr5: [],
            main_arr6: [], main_arr7: []
        })

        setField1({
            val1: "", val2: "", val3: "", val4: "", val5: "", val6: "", val7: "", val8: "", val9: "", val10: "",
            val11: "", val12: "", val13: "", val14: "", val15: "", val16: "", val17: "", val18: "", val19: "", val20: "",
            val21: "", val22: "", val23: "", val24: "", val25: "", val26: "", val27: "", val28: "", val29: "", val30: ""
        })

        setField2({
            val1: "", val2: "", val3: "", val4: "", val5: "", val6: "", val7: "", val8: "", val9: "", val10: "",
            val11: "", val12: "", val13: "", val14: "", val15: "", val16: "", val17: "", val18: "", val19: "", val20: "",
        })

        setField3({
            val21: "", val22: "", val23: "", val24: "", val25: "", val26: "", val27: "", val28: "", val29: "", val30: "",
            val31: "", val32: "", val33: "", val34: "", val35: "", val36: "", val37: "", val38: "", val39: "", val40: "",
        })

        setField4({
            val41: "", val42: "", val43: "", val44: "", val45: "", val46: "", val47: "", val48: "", val49: "",
            val50: "", val51: "", val52: "", val53: "", val54: "", val55: "", val56: "", val57: "", val58: "", val59: "", val60: "",
        })

        setField5({
            val61: "", val62: "", val63: "", val64: "", val65: "", val66: "", val67: "", val68: "", val69: "", val70: "",
        })

        setIsSubmitted(false);
        loadQuestions();
        // setResult(false);
    }


    useEffect(() => {
        generateTest();
        // loadUser_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const HandleNextStep = () => {
        setToggle_mm_ab(false);
        setDate((Date.now() + 2400000));
        setTestEndTime_mm(new Date().getTime());
        setTestStartTime_ab(new Date().getTime());
        window.scrollTo({
            top: 0,
        });
    }

    // ------------------ For Iframe video modal ------------------------------------------------

    const [open_iframe, setOpen_iframe] = useState(false);

    //----------------- head_id_mm = head id for Mind math -----------------------

    const [head_id_mm, setHead_id_mm] = useState({
        table1: [], table2: [], table3: [],
    });

    //----------------- head_id_ab = head id for Abacus -----------------------

    const [head_id_ab, setHead_id_ab] = useState({
        table1: [], table2: [], table3: [], table4: [], table5: [], table6: [], table7: []
    });

    //----------------- answer_mm = answer for Mind math -----------------------

    const [answer_mm, setAnswer_mm] = useState({
        answer1: [], answer2: [], answer3: [],
    });

    //----------------- answer_ab = answer for Abacus -----------------------

    const [answer_ab, setAnswer_ab] = useState({
        answer1: [], answer2: [], answer3: [], answer4: [], answer5: [], answer6: [], answer7: [],
    });

    //----------------- data_mm = data for mindmath table ------------------
    const [data_mm, setData_mm] = useState({
        main_arr1: [], main_arr2: [], main_arr3: [],
    });

    //----------------- data_ab = data for Abacus table ------------------
    const [data_ab, setData_ab] = useState({
        main_arr1: [], main_arr2: [], main_arr3: [], main_arr4: [], main_arr5: [],
        main_arr6: [], main_arr7: []
    });

    const [field1, setField1] = useState({
        val1: "", val2: "", val3: "", val4: "", val5: "", val6: "", val7: "", val8: "", val9: "", val10: "",
        val11: "", val12: "", val13: "", val14: "", val15: "", val16: "", val17: "", val18: "", val19: "", val20: "",
        val21: "", val22: "", val23: "", val24: "", val25: "", val26: "", val27: "", val28: "", val29: "", val30: ""
    })

    const [field2, setField2] = useState({
        val1: "", val2: "", val3: "", val4: "", val5: "", val6: "", val7: "", val8: "", val9: "", val10: "",
        val11: "", val12: "", val13: "", val14: "", val15: "", val16: "", val17: "", val18: "", val19: "", val20: "",
    })

    const [field3, setField3] = useState({
        val21: "", val22: "", val23: "", val24: "", val25: "", val26: "", val27: "", val28: "", val29: "", val30: "",
        val31: "", val32: "", val33: "", val34: "", val35: "", val36: "", val37: "", val38: "", val39: "", val40: "",
    })

    const [field4, setField4] = useState({
        val41: "", val42: "", val43: "", val44: "", val45: "", val46: "", val47: "", val48: "", val49: "", val50: "",
        val51: "", val52: "", val53: "", val54: "", val55: "", val56: "", val57: "", val58: "", val59: "", val60: "",
    })

    const [field5, setField5] = useState({
        val61: "", val62: "", val63: "", val64: "", val65: "", val66: "", val67: "", val68: "", val69: "", val70: "",
    })

    //  This function is used to make normal Inputs into Controlled inputs and storing values into state variables
    const onChange1 = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setField1((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    //  This function is used to make normal Inputs into Controlled inputs and storing values into state variables
    const onChange2 = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setField2((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }
    //  This function is used to make normal Inputs into Controlled inputs and storing values into state variables
    const onChange3 = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setField3((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    //  This function is used to make normal Inputs into Controlled inputs and storing values into state variables
    const onChange4 = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setField4((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    //  This function is used to make normal Inputs into Controlled inputs and storing values into state variables
    const onChange5 = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setField5((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    const [value, setValue] = useState({
        count: "", attemptedpercentage: "", notans: "", overallpercentage: "", wrongans: "",
    })

    // ==========================This Function Removes the empty bottom rows of any Table =====================
    const isEmpty = x => x[0].filter(Boolean).length === 0 ? true : false;

    // ====================================================================
    const [isSubmitted, setIsSubmitted] = useState(false);   // this will do toggle between question and Answer parts
    const [toggle_mm_ab, setToggle_mm_ab] = useState(true);  // this will toggle Mind-Math part into Abacus part 
    const [show_mm, setShow_mm] = useState(false);     // this will hide Mind-Math questions till the starting video popup is closed
    const [result, setResult] = useState(false);      // THIS  will show the result after test is submitted

    const [date, setDate] = useState();         // this will start the timer 

    var answer1_mm = [], answer2_mm = [], answer3_mm = [];

    var answer1_ab = [], answer2_ab = [], answer3_ab = [], answer4_ab = [], answer5_ab = [],
        answer6_ab = [], answer7_ab = [];

    var length_mm, length_ab;
    const [length_MM, setLength_MM] = useState("");
    const [length_AB, setLength_AB] = useState("");

    const arr_MM = [
        field1.val1, field1.val2, field1.val3, field1.val4, field1.val5, field1.val6, field1.val7, field1.val8, field1.val9, field1.val10,
        field1.val11, field1.val12, field1.val13, field1.val14, field1.val15, field1.val16, field1.val17, field1.val18, field1.val19, field1.val20,
        field1.val21, field1.val22, field1.val23, field1.val24, field1.val25, field1.val26, field1.val27, field1.val28, field1.val29, field1.val30,
    ]

    const arr_AB = [
        field2.val1, field2.val2, field2.val3, field2.val4, field2.val5, field2.val6, field2.val7, field2.val8, field2.val9, field2.val10,
        field2.val11, field2.val12, field2.val13, field2.val14, field2.val15, field2.val16, field2.val17, field2.val18, field2.val19, field2.val20,
        field3.val21, field3.val22, field3.val23, field3.val24, field3.val25, field3.val26, field3.val27, field3.val28, field3.val29, field3.val30,
        field3.val31, field3.val32, field3.val33, field3.val34, field3.val35, field3.val36, field3.val37, field3.val38, field3.val39, field3.val40,
        field4.val41, field4.val42, field4.val43, field4.val44, field4.val45, field4.val46, field4.val47, field4.val48, field4.val49, field4.val50,
        field4.val51, field4.val52, field4.val53, field4.val54, field4.val55, field4.val56, field4.val57, field4.val58, field4.val59, field4.val60,
        field5.val61, field5.val62, field5.val63, field5.val64, field5.val65, field5.val66, field5.val67, field5.val68, field5.val69, field5.val70,
    ]

    const loadQuestions = async () => {


        // ================  For Mind-Math
        var Table1_id_mm = [], Table1_arr1_mm = [], Table1_arr2_mm = [], Table1_arr3_mm = [], Table1_arr4_mm = [], Table1_arr5_mm = [],
            Table2_id_mm = [], Table2_arr1_mm = [], Table2_arr2_mm = [], Table2_arr3_mm = [], Table2_arr4_mm = [], Table2_arr5_mm = [],
            Table3_id_mm = [], Table3_arr1_mm = [], Table3_arr2_mm = [], Table3_arr3_mm = [], Table3_arr4_mm = [], Table3_arr5_mm = [];
        // ================= For Abacus
        var Table1_id_ab = [], Table1_arr1_ab = [], Table1_arr2_ab = [], Table1_arr3_ab = [], Table1_arr4_ab = [], Table1_arr5_ab = [], Table1_arr6_ab = [], Table1_arr7_ab = [],
            Table2_id_ab = [], Table2_arr1_ab = [], Table2_arr2_ab = [], Table2_arr3_ab = [], Table2_arr4_ab = [], Table2_arr5_ab = [], Table2_arr6_ab = [], Table2_arr7_ab = [],
            Table3_id_ab = [], Table3_arr1_ab = [], Table3_arr2_ab = [], Table3_arr3_ab = [], Table3_arr4_ab = [], Table3_arr5_ab = [], Table3_arr6_ab = [], Table3_arr7_ab = [],
            Table4_id_ab = [], Table4_arr1_ab = [], Table4_arr2_ab = [], Table4_arr3_ab = [], Table4_arr4_ab = [], Table4_arr5_ab = [], Table4_arr6_ab = [], Table4_arr7_ab = [],
            Table5_id_ab = [], Table5_arr1_ab = [], Table5_arr2_ab = [], Table5_arr3_ab = [], Table5_arr4_ab = [], Table5_arr5_ab = [], Table5_arr6_ab = [], Table5_arr7_ab = [],
            Table6_id_ab = [], Table6_arr1_ab = [], Table6_arr2_ab = [], Table6_arr3_ab = [], Table6_arr4_ab = [], Table6_arr5_ab = [], Table6_arr6_ab = [], Table6_arr7_ab = [],
            Table7_id_ab = [], Table7_arr1_ab = [], Table7_arr2_ab = [], Table7_arr3_ab = [], Table7_arr4_ab = [], Table7_arr5_ab = [], Table7_arr6_ab = [], Table7_arr7_ab = [];

        var Table1_arr_mm = [], Table2_arr_mm = [], Table3_arr_mm = []; // For Mind-Math

        var Table1_arr_ab = [], Table2_arr_ab = [], Table3_arr_ab = [], Table4_arr_ab = [], Table5_arr_ab = [],
            Table6_arr_ab = [], Table7_arr_ab = []; //For Abacus

        var arr1_mm = [], arr2_mm = [], arr3_mm = []; //For Mind-Math

        var arr1_ab = [], arr2_ab = [], arr3_ab = [], arr4_ab = [], arr5_ab = [],
            arr6_ab = [], arr7_ab = []; // For Abacus


        // ======================================== FETCHING MIND- MATH QUESTIONS ======================================================
        // =============================================================================================================================


        const obj_mm = { uname: sessionStorage.getItem("username"), test_id: id, category_name: "Mind Math" }

        await axios.post(url + "php/questions/questions_level.php", obj_mm)
            .then((response) => {
                // console.log(response);
                if (response.data.success) {
                    var a = JSON.parse(response.data.data);
                    length_mm = a.length;  // storing in variable ,so that we can create header id till the length
                    setLength_MM(length_mm);
                    // console.log(length_mm);

                    Table1_arr_mm.push((a.splice(0, 10)));
                    Table2_arr_mm.push((a.splice(0, 10)));
                    Table3_arr_mm.push((a.splice(0, 10)));


                    //Storing header values for the 3 tables
                    var x = [...Array(length_mm).keys()];
                    x = x.map(x => x + 1);
                    // console.log(x)
                    Table1_id_mm.push((x.splice(0, 10)));
                    Table2_id_mm.push((x.splice(0, 10)));
                    Table3_id_mm.push((x.splice(0, 10)));

                    setHead_id_mm({
                        table1: Table1_id_mm, table2: Table2_id_mm, table3: Table3_id_mm,
                    })

                    //Storing Answer of each questions for the 3 Mind Math tables
                    answer1_mm.push(Table1_arr_mm.map((x) => x.map(x => x.answer)));
                    answer2_mm.push(Table2_arr_mm.map((x) => x.map(x => x.answer)));
                    answer3_mm.push(Table3_arr_mm.map((x) => x.map(x => x.answer)));

                    setAnswer_mm({
                        answer1: answer1_mm, answer2: answer2_mm, answer3: answer3_mm,
                    })

                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 1
                    Table1_arr1_mm.push(Table1_arr_mm.map((x) => x.map(x => x.ques_num1)))
                    Table1_arr2_mm.push(Table1_arr_mm.map((x) => x.map(x => x.ques_num2)))
                    Table1_arr3_mm.push(Table1_arr_mm.map((x) => x.map(x => x.ques_num3)))
                    Table1_arr4_mm.push(Table1_arr_mm.map((x) => x.map(x => x.ques_num4)))
                    Table1_arr5_mm.push(Table1_arr_mm.map((x) => x.map(x => x.ques_num5)))

                    // Now storing the above 4 arrays into One array named "arr1_mm" 
                    arr1_mm = [...Table1_arr1_mm, ...Table1_arr2_mm, ...(Table1_arr3_mm.every(isEmpty) ? [] : Table1_arr3_mm), ...(Table1_arr4_mm.every(isEmpty) ? [] : Table1_arr4_mm), ...(Table1_arr5_mm.every(isEmpty) ? [] : Table1_arr5_mm)];
                    /*----------------------------------------------------------------------*/

                    // Storing Values for table 2
                    Table2_arr1_mm.push(Table2_arr_mm.map((x) => x.map(x => x.ques_num1)))
                    Table2_arr2_mm.push(Table2_arr_mm.map((x) => x.map(x => x.ques_num2)))
                    Table2_arr3_mm.push(Table2_arr_mm.map((x) => x.map(x => x.ques_num3)))
                    Table2_arr4_mm.push(Table2_arr_mm.map((x) => x.map(x => x.ques_num4)))
                    Table2_arr5_mm.push(Table2_arr_mm.map((x) => x.map(x => x.ques_num5)))

                    // Now storing the above 4 arrays into One array named "arr2_mm" 
                    arr2_mm = [...Table2_arr1_mm, ...Table2_arr2_mm, ...(Table2_arr3_mm.every(isEmpty) ? [] : Table2_arr3_mm), ...(Table2_arr4_mm.every(isEmpty) ? [] : Table2_arr4_mm), ...(Table2_arr5_mm.every(isEmpty) ? [] : Table2_arr5_mm)];
                    /*----------------------------------------------------------------------*/

                    // Storing Values for table 3
                    Table3_arr1_mm.push(Table3_arr_mm.map((x) => x.map(x => x.ques_num1)))
                    Table3_arr2_mm.push(Table3_arr_mm.map((x) => x.map(x => x.ques_num2)))
                    Table3_arr3_mm.push(Table3_arr_mm.map((x) => x.map(x => x.ques_num3)))
                    Table3_arr4_mm.push(Table3_arr_mm.map((x) => x.map(x => x.ques_num4)))
                    Table3_arr5_mm.push(Table3_arr_mm.map((x) => x.map(x => x.ques_num5)))

                    // Now storing the above 4 arrays into One array named "arr3_mm" 
                    arr3_mm = [...Table3_arr1_mm, ...Table3_arr2_mm, ...(Table3_arr3_mm.every(isEmpty) ? [] : Table3_arr3_mm), ...(Table3_arr4_mm.every(isEmpty) ? [] : Table3_arr4_mm), ...(Table3_arr5_mm.every(isEmpty) ? [] : Table3_arr5_mm)];

                    /*----------------------------------------------------------------------*/

                    setData_mm({
                        main_arr1: arr1_mm, main_arr2: arr2_mm, main_arr3: arr3_mm,
                    })
                } else {
                    setData_mm([]);

                }
            }).catch((err) => {
                console.log(err);

            });


        // ======================================== NOW FETCHING ABACUS QUESTIONS ======================================================
        // =============================================================================================================================


        const obj_ab = { uname: sessionStorage.getItem("username"), test_id: id, category_name: "Abacus" }

        await axios.post(url + "php/questions/questions_level.php", obj_ab)
            .then((response) => {
                // console.log(response);
                if (response.data.success) {
                    var a = JSON.parse(response.data.data);
                    length_ab = a.length;  // storing in variable ,so that we can create header id till the length
                    setLength_AB(length_ab);
                    // console.log(length_ab);

                    Table1_arr_ab.push((a.splice(0, 10)));
                    Table2_arr_ab.push((a.splice(0, 10)));
                    Table3_arr_ab.push((a.splice(0, 10)));
                    Table4_arr_ab.push((a.splice(0, 10)));
                    Table5_arr_ab.push((a.splice(0, 10)));
                    Table6_arr_ab.push((a.splice(0, 10)));
                    Table7_arr_ab.push((a.splice(0, 10)));

                    //Storing header values for the 10 tables
                    var x = [...Array(length_ab).keys()];
                    x = x.map(x => x + 1);
                    // console.log(x)
                    Table1_id_ab.push((x.splice(0, 10)));
                    Table2_id_ab.push((x.splice(0, 10)));
                    Table3_id_ab.push((x.splice(0, 10)));
                    Table4_id_ab.push((x.splice(0, 10)));
                    Table5_id_ab.push((x.splice(0, 10)));
                    Table6_id_ab.push((x.splice(0, 10)));
                    Table7_id_ab.push((x.splice(0, 10)));

                    setHead_id_ab({
                        table1: Table1_id_ab, table2: Table2_id_ab, table3: Table3_id_ab, table4: Table4_id_ab, table5: Table5_id_ab,
                        table6: Table6_id_ab, table7: Table7_id_ab
                    })

                    //Storing Answer of each questions for the 3 Mind Math tables
                    answer1_ab.push(Table1_arr_ab.map((x) => x.map(x => x.answer)));
                    answer2_ab.push(Table2_arr_ab.map((x) => x.map(x => x.answer)));
                    answer3_ab.push(Table3_arr_ab.map((x) => x.map(x => x.answer)));
                    answer4_ab.push(Table4_arr_ab.map((x) => x.map(x => x.answer)));
                    answer5_ab.push(Table5_arr_ab.map((x) => x.map(x => x.answer)));
                    answer6_ab.push(Table6_arr_ab.map((x) => x.map(x => x.answer)));
                    answer7_ab.push(Table7_arr_ab.map((x) => x.map(x => x.answer)));

                    setAnswer_ab({
                        answer1: answer1_ab, answer2: answer2_ab, answer3: answer3_ab, answer4: answer4_ab, answer5: answer5_ab,
                        answer6: answer6_ab, answer7: answer7_ab
                    })

                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 1
                    Table1_arr1_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table1_arr2_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table1_arr3_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table1_arr4_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table1_arr5_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table1_arr6_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table1_arr7_ab.push(Table1_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr1_ab" 
                    arr1_ab = [...Table1_arr1_ab, ...Table1_arr2_ab, ...(Table1_arr3_ab.every(isEmpty) ? [] : Table1_arr3_ab), ...(Table1_arr4_ab.every(isEmpty) ? [] : Table1_arr4_ab), ...(Table1_arr5_ab.every(isEmpty) ? [] : Table1_arr5_ab), ...(Table1_arr6_ab.every(isEmpty) ? [] : Table1_arr6_ab), ...(Table1_arr7_ab.every(isEmpty) ? [] : Table1_arr7_ab)];
                    /*----------------------------------------------------------------------*/

                    // Storing Values for table 2
                    Table2_arr1_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table2_arr2_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table2_arr3_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table2_arr4_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table2_arr5_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table2_arr6_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table2_arr7_ab.push(Table2_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr2_ab" 
                    arr2_ab = [...Table2_arr1_ab, ...Table2_arr2_ab, ...(Table2_arr3_ab.every(isEmpty) ? [] : Table2_arr3_ab), ...(Table2_arr4_ab.every(isEmpty) ? [] : Table2_arr4_ab), ...(Table2_arr5_ab.every(isEmpty) ? [] : Table2_arr5_ab), ...(Table2_arr6_ab.every(isEmpty) ? [] : Table2_arr6_ab), ...(Table2_arr7_ab.every(isEmpty) ? [] : Table2_arr7_ab)];
                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 3
                    Table3_arr1_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table3_arr2_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table3_arr3_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table3_arr4_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table3_arr5_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table3_arr6_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table3_arr7_ab.push(Table3_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr3_ab" 
                    arr3_ab = [...Table3_arr1_ab, ...Table3_arr2_ab, ...(Table3_arr3_ab.every(isEmpty) ? [] : Table3_arr3_ab), ...(Table3_arr4_ab.every(isEmpty) ? [] : Table3_arr4_ab), ...(Table3_arr5_ab.every(isEmpty) ? [] : Table3_arr5_ab), ...(Table3_arr6_ab.every(isEmpty) ? [] : Table3_arr6_ab), ...(Table3_arr7_ab.every(isEmpty) ? [] : Table3_arr7_ab)];

                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 4
                    Table4_arr1_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table4_arr2_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table4_arr3_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table4_arr4_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table4_arr5_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table4_arr6_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table4_arr7_ab.push(Table4_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr1_ab" 
                    arr4_ab = [...Table4_arr1_ab, ...Table4_arr2_ab, ...(Table4_arr3_ab.every(isEmpty) ? [] : Table4_arr3_ab), ...(Table4_arr4_ab.every(isEmpty) ? [] : Table4_arr4_ab), ...(Table4_arr5_ab.every(isEmpty) ? [] : Table4_arr5_ab), ...(Table4_arr6_ab.every(isEmpty) ? [] : Table4_arr6_ab), ...(Table4_arr7_ab.every(isEmpty) ? [] : Table4_arr7_ab)];
                    /*----------------------------------------------------------------------*/

                    // Storing Values for table 5
                    Table5_arr1_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table5_arr2_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table5_arr3_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table5_arr4_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table5_arr5_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table5_arr6_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table5_arr7_ab.push(Table5_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr2_ab" 
                    arr5_ab = [...Table5_arr1_ab, ...Table5_arr2_ab, ...(Table5_arr3_ab.every(isEmpty) ? [] : Table5_arr3_ab), ...(Table5_arr4_ab.every(isEmpty) ? [] : Table5_arr4_ab), ...(Table5_arr5_ab.every(isEmpty) ? [] : Table5_arr5_ab), ...(Table5_arr6_ab.every(isEmpty) ? [] : Table5_arr6_ab), ...(Table5_arr7_ab.every(isEmpty) ? [] : Table5_arr7_ab)];
                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 6
                    Table6_arr1_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table6_arr2_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table6_arr3_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table6_arr4_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table6_arr5_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table6_arr6_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table6_arr7_ab.push(Table6_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr3_ab" 
                    arr6_ab = [...Table6_arr1_ab, ...Table6_arr2_ab, ...(Table6_arr3_ab.every(isEmpty) ? [] : Table6_arr3_ab), ...(Table6_arr4_ab.every(isEmpty) ? [] : Table6_arr4_ab), ...(Table6_arr5_ab.every(isEmpty) ? [] : Table6_arr5_ab), ...(Table6_arr6_ab.every(isEmpty) ? [] : Table6_arr6_ab), ...(Table6_arr7_ab.every(isEmpty) ? [] : Table6_arr7_ab)];

                    /*----------------------------------------------------------------------*/
                    // Storing Values for table 7
                    Table7_arr1_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num1)))
                    Table7_arr2_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num2)))
                    Table7_arr3_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num3)))
                    Table7_arr4_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num4)))
                    Table7_arr5_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num5)))
                    Table7_arr6_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num6)))
                    Table7_arr7_ab.push(Table7_arr_ab.map((x) => x.map(x => x.ques_num7)))

                    // Now storing the above 7 arrays into One array named "arr1_ab" 
                    arr7_ab = [...Table7_arr1_ab, ...Table7_arr2_ab, ...(Table7_arr3_ab.every(isEmpty) ? [] : Table7_arr3_ab), ...(Table7_arr4_ab.every(isEmpty) ? [] : Table7_arr4_ab), ...(Table7_arr5_ab.every(isEmpty) ? [] : Table7_arr5_ab), ...(Table7_arr6_ab.every(isEmpty) ? [] : Table7_arr6_ab), ...(Table7_arr7_ab.every(isEmpty) ? [] : Table7_arr7_ab)];
                    /*----------------------------------------------------------------------*/

                    // console.log(arr1_ab);console.log(arr2_ab);console.log(arr3_ab);console.log(arr4_ab);console.log(arr5_ab);
                    /*----------------------------------------------------------------------*/


                    setData_ab({
                        main_arr1: arr1_ab, main_arr2: arr2_ab, main_arr3: arr3_ab, main_arr4: arr4_ab, main_arr5: arr5_ab,
                        main_arr6: arr6_ab, main_arr7: arr7_ab
                    })
                } else {
                    setData_ab([]);
                    setBackdrop(false);
                }
            }).catch((err) => {
                console.log(err)
                setBackdrop(false);
            });

        if (yt_url !== "null") {
            setOpen_iframe(true);
        } else {
            setShow_mm(true);
            toast.success("Test loaded successfully");
            setDate((Date.now() + 1200000));
            setTestStartTime_mm(new Date().getTime());
        }
        setBackdrop(false);
    }

    // -------Function to load countdown Timer -------------------------

    const timer_style = {
        border: "2px solid #174873", letterSpacing: "2px", fontWeight: "600", color: "#174873", width: "6.5em", height: "2em"
    }

    // This Timer is used  because we dont want to submit test if timer completes in Mind-math part , rather we want to switch to Abacus part
    const TimerMM = () => {
        return (
            <div className="rounded d-flex justify-content-center align-items-center" style={timer_style}>
                <Countdown
                    date={date}
                    renderer={({ minutes, seconds }) => <span >{minutes}:{seconds}</span>}
                    onComplete={() => {
                        setToggle_mm_ab(false);
                        setDate((Date.now() + 2400000));
                        setMindMathAutoSubmit(true);          //This will tell us whether the Mindmath part is auto submitted or submitted manually
                        setTestStartTime_ab(new Date().getTime());
                        toast.error("Time over for the Mind-Math part, please now continue with the Abacus part.")
                    }
                    }
                />
            </div>
        )
    }

    const Timer = () => {
        return (
            <div className="rounded d-flex justify-content-center align-items-center" style={timer_style}>
                <Countdown
                    date={date}
                    renderer={({ minutes, seconds }) => <span >{minutes}:{seconds}</span>}
                    onComplete={AutoSubmit}
                />
            </div>
        )
    }

    // This function will generate test time in Minutes:Seconds Format ---------
    const millisToMinutesAndSeconds = (millis) => {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return (seconds == 60 ? (minutes + 1) + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
    }

    // This function runs when The countdown timer reaches zero and makes the test submit automatically
    const AutoSubmit = () => {

        // *********************************************************

        var role = sessionStorage.getItem("role");
        var location_name;
        var batch_name;

        if (role === "Student") {
            location_name = sessionStorage.getItem("location");
            batch_name = sessionStorage.getItem("batch");
        } else {
            location_name = "";
            batch_name = "";
        }
        // *********************************************************

        if (mindMathAutoSubmit) {
            total_test_duration_mm = "20:00"
        } else {
            difference_mm = testEndTime_mm - testStartTime_mm; //Calculating time taken for mind math test 
            total_test_duration_mm = millisToMinutesAndSeconds(difference_mm);
        }

        // *********************************************************

        for (var i = 0; i < length_MM; i++) {
            ans[`val${i + 1}`] = arr_MM[i]
        }
        for (var j = 0; j < length_AB; j++) {
            ans[`val${j + length_MM + 1}`] = arr_AB[j]
        }

        // ********************************************************* 

        const obj = {
            uname: sessionStorage.getItem("username"), location_name: location_name, batch_name: batch_name,
            level_name: level_name, fname: sessionStorage.getItem("userFname"), mname: "", lname: sessionStorage.getItem("userLname"),
            test_name: test_name, test_type: test_type, time_taken_abacus: "40:00", time_taken_mm: total_test_duration_mm,
            total_que: length_MM + length_AB, total_mm_que: length_MM, total_ab_que: length_AB, page: "", test_id: id,
            ...ans
        }

        axios.post(url + "php/questions/match_stud_ans.php", obj)
            .then((res) => {
                // console.log(res) 
                if (res.data.success) {
                    toast.error("Time over, test submitted successfully")
                    setValue({
                        count: res.data.count, attemptedpercentage: res.data.attemptedpercentage, notans: res.data.notans, overallpercentage: res.data.overallpercentage, wrongans: res.data.wrongans,
                    })
                    setResult(true);
                    setIsSubmitted(true);
                } else {
                    alert("Test not submitted")
                }
            })
            .catch((err) => console.log(err));

    }

    // This function runs when the Final  Submit button is Clicked
    const onSubmitTest = () => {
        // e.preventDefault();
        setBackdrop(true);
        // *********************************************************

        var role = sessionStorage.getItem("role");
        var location_name;
        var batch_name;

        if (role === "Student") {
            location_name = sessionStorage.getItem("location");
            batch_name = sessionStorage.getItem("batch");
        } else {
            location_name = "";
            batch_name = "";
        }

        // *********************************************************

        testEndTime_ab = new Date().getTime(); // Setting end time for abacus test  

        if (mindMathAutoSubmit) {
            total_test_duration_mm = "20:00"
        } else {
            difference_mm = testEndTime_mm - testStartTime_mm; //Calculating time taken for mind math test 
            total_test_duration_mm = millisToMinutesAndSeconds(difference_mm);
        }

        difference_ab = testEndTime_ab - testStartTime_ab; //Calculating time taken for Abacus test
        total_test_duration_ab = millisToMinutesAndSeconds(difference_ab);

        // *********************************************************

        for (var i = 0; i < length_MM; i++) {
            ans[`val${i + 1}`] = arr_MM[i]
        }
        for (var j = 0; j < length_AB; j++) {
            ans[`val${j + length_MM + 1}`] = arr_AB[j]
        }

        // *********************************************************

        const obj = {
            uname: sessionStorage.getItem("username"), location_name: location_name, batch_name: batch_name,
            level_name: level_name, fname: sessionStorage.getItem("userFname"), mname: "", lname: sessionStorage.getItem("userLname"),
            test_name: test_name, test_type: test_type, time_taken_abacus: total_test_duration_ab, time_taken_mm: total_test_duration_mm,
            total_que: length_MM + length_AB, total_mm_que: length_MM, total_ab_que: length_AB, page: "", test_id: id,
            ...ans
        }
        console.log(obj);

        axios.post(url + "php/questions/match_stud_ans.php", obj)
            .then((res) => {
                // console.log(res) 
                if (res.data.success) {
                    toast.success("Test Submitted Successfully")
                    setValue({
                        count: res.data.count, attemptedpercentage: res.data.attemptedpercentage, notans: res.data.notans, overallpercentage: res.data.overallpercentage, wrongans: res.data.wrongans,
                    })
                    setResult(true);
                    setIsSubmitted(true);
                    setBackdrop(false);
                } else {
                    alert("Test not submitted")
                }
            })
            .catch((err) => console.log(err));

    }

    // This function will show the Result in place of Submit button on test Submit -----
    function ShowResult() {
        return (
            <div className="row mb-4 ">
                <div className="col-12 text-center d-flex flex-column justify-content-center align-items-center bg-light py-1">
                    <h6>{"You have entered " + value.count + " correct answer(s)"}</h6>
                    <h6>{"You have entered " + value.wrongans + " Wrong answer(s)"}</h6>
                    <h6>{"Not answered " + value.notans + " questions"}</h6>
                    <h6>{"Attempt Percentage " + parseFloat(value.attemptedpercentage).toFixed(1).replace(/\.0$/, '') + "%"}</h6>
                    <h6>{"Overall Percentage " + parseFloat(value.overallpercentage).toFixed(1).replace(/\.0$/, '') + "%"}</h6>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item">Tests</li>
                        <li className="breadcrumb-item">My Tests</li>
                        <li className="breadcrumb-item active" aria-current="page">Test</li>
                    </ol>
                </nav>

                <Heading name={`( Test ) - ${test_name}`} />

                {/* ---------------Modal popup for video iframe on click of test generate button----------------------- */}

                <Dialog open={open_iframe}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                            // Set 'open' to false, however you would do that with your particular code.
                            setOpen_iframe(false);
                        }
                    }} maxWidth='md' fullWidth>
                    <DialogContent >
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item" title="1"
                                src={`https://www.youtube.com/embed/${yt_url}`}
                                frameBorder="0" allow="autoplay;encrypted-media;"
                                allowFullScreen></iframe>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setOpen_iframe(false);
                            setShow_mm(true);
                            toast.success("Test loaded successfully");
                            setDate((Date.now() + 1200000));
                            setTestStartTime_mm(new Date().getTime());
                        }} color='primary'>Close</Button>
                    </DialogActions>
                </Dialog>

                {/* ======================================================================== Test-Table --> Start here ====================================================================================== */}

                <div className="container-fluid ">
                    <div >

                        {!isSubmitted ? <div>

                            <div className="row">
                                <div className="col-12 mx-auto ">

                                    {/* ----------------------------------------Accordion Tab to show Iframe video--------------------------- */}
                                    {yt_url !== "null" && <>
                                        <div className='w-100'>
                                            <Accordion>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content"  >
                                                    <h5 className="my-auto" style={{ color: "#174873" }}>{`${test_name} Video Help`}</h5></AccordionSummary>
                                                <AccordionDetails className="d-flex justify-content-center align-items-center">
                                                    <div >
                                                        <iframe width="600" height="300" title="2"
                                                            src={`https://www.youtube.com/embed/${yt_url}`}
                                                            frameBorder="0" allow="autoplay;encrypted-media;"
                                                            allowFullScreen></iframe>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                        <hr className="p-0 mt-4 mx-auto" style={{ border: "none", borderBottom: ".4em dotted #174873", width: "10%" }} />
                                    </>}
                                    {/* ------------------------------------------------------------------------------------------------------------- */}
                                </div>
                            </div>

                            {toggle_mm_ab ? <>

                                {/* ====================================================================================================== */}
                                {/* ======================================== Mind - Math Part ============================================ */}

                                <div className="row d-flex justify-content-between align-items-end mb-3">
                                    <AlertBlockForQuestionPapers />
                                    <div className="col-auto ">
                                        {show_mm && <TimerMM />}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 mx-auto ">
                                        <h5>Mind-Math</h5>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>{head_id_mm.table1.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}</TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {show_mm && data_mm.main_arr1.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}

                                                    <GenerateInputRow readOnly={false} from={1} to={10} length={length_MM} onChange={onChange1} field={field1} />

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>

                                <div className="row my-5">
                                    <div className="col-12 mx-auto ">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>{head_id_mm.table2.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}</TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {show_mm && data_mm.main_arr2.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}

                                                    <GenerateInputRow readOnly={false} from={11} to={20} length={length_MM} onChange={onChange1} field={field1} />
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-12 mx-auto">

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                    <TableRow>

                                                        {head_id_mm.table3.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {show_mm && data_mm.main_arr3.map(x => x.map((x, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                            </TableRow>
                                                        )
                                                    }))}

                                                    <GenerateInputRow readOnly={false} from={21} to={30} length={length_MM} onChange={onChange1} field={field1} />

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>

                                {show_mm && <div className="row my-5">
                                    <div className="col-12 mx-auto d-flex justify-content-center align-items-center ">

                                        <h6 className="m-0 ">Submit and Proceed to Abacus Part <DoubleArrowIcon /> </h6>
                                        <Button variant="contained" onClick={() => {
                                            confirm({
                                                title: "Submit Confirmation",
                                                description: "Are you sure, you want to submit and proceed to abacus part?",
                                            }).then(() => HandleNextStep());

                                        }}
                                            className=" ms-2">Submit </Button></div>
                                </div>}
                            </>

                                : <>
                                    {/* ====================================================================================================== */}
                                    {/* ============================================= Abacus Part ============================================ */}
                                    <Abacus />

                                    {/* ============================== */}


                                    <div className="row d-flex justify-content-between align-items-end mb-3">
                                        <AlertBlockForQuestionPapers />
                                        <div className="col-auto ">
                                            {show_mm && <Timer />}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 mx-auto ">
                                            <h5>Abacus</h5>
                                        </div>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-12 mx-auto ">

                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                        <TableRow>
                                                            {head_id_ab.table1.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {data_ab.main_arr1.map(x => x.map((x, i) => {
                                                            return (
                                                                <TableRow key={i}>
                                                                    {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                                </TableRow>
                                                            )
                                                        }))}

                                                        <GenerateInputRow readOnly={false} from={1} to={10} length={length_AB} onChange={onChange2} field={field2} />

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>

                                    <div className="row my-5">
                                        <div className="col-12 mx-auto ">

                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                        <TableRow>
                                                            {head_id_ab.table2.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {data_ab.main_arr2.map(x => x.map((x, i) => {
                                                            return (
                                                                <TableRow key={i}>
                                                                    {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                                </TableRow>
                                                            )
                                                        }))}

                                                        <GenerateInputRow readOnly={false} from={11} to={20} length={length_AB} onChange={onChange2} field={field2} />

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>

                                    <div className="row my-5">
                                        <div className="col-12 mx-auto ">

                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                        <TableRow>
                                                            {head_id_ab.table3.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {data_ab.main_arr3.map(x => x.map((x, i) => {
                                                            return (
                                                                <TableRow key={i}>
                                                                    {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                                </TableRow>
                                                            )
                                                        }))}

                                                        <GenerateInputRow readOnly={false} from={21} to={30} length={length_AB} onChange={onChange3} field={field3} />

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>

                                    <div className="row my-5">
                                        <div className="col-12 mx-auto ">

                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                        <TableRow>
                                                            {head_id_ab.table4.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {data_ab.main_arr4.map(x => x.map((x, i) => {
                                                            return (
                                                                <TableRow key={i}>
                                                                    {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                                </TableRow>
                                                            )
                                                        }))}

                                                        <GenerateInputRow readOnly={false} from={31} to={40} length={length_AB} onChange={onChange3} field={field3} />

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>

                                    <div className="row my-5">
                                        <div className="col-12 mx-auto ">

                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                        <TableRow>
                                                            {head_id_ab.table5.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {data_ab.main_arr5.map(x => x.map((x, i) => {
                                                            return (
                                                                <TableRow key={i}>
                                                                    {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                                </TableRow>
                                                            )
                                                        }))}

                                                        <GenerateInputRow readOnly={false} from={41} to={50} length={length_AB} onChange={onChange4} field={field4} />

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>

                                    <div className="row my-5">
                                        <div className="col-12 mx-auto ">

                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                        <TableRow>
                                                            {head_id_ab.table6.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {data_ab.main_arr6.map(x => x.map((x, i) => {
                                                            return (
                                                                <TableRow key={i}>
                                                                    {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                                </TableRow>
                                                            )
                                                        }))}

                                                        <GenerateInputRow readOnly={false} from={51} to={60} length={length_AB} onChange={onChange4} field={field4} />

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>

                                    <div className="row mt-5">
                                        <div className="col-12 mx-auto ">

                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                        <TableRow>
                                                            {head_id_ab.table7.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {data_ab.main_arr7.map(x => x.map((x, i) => {
                                                            return (
                                                                <TableRow key={i}>
                                                                    {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                                </TableRow>
                                                            )
                                                        }))}


                                                        <GenerateInputRow readOnly={false} from={61} to={70} length={length_AB} onChange={onChange5} field={field5} />

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>

                                    <div className="row my-4">
                                        <div className="col-12 mx-auto d-flex justify-content-end ">
                                            <Button variant="contained" onClick={() => {
                                                if (sessionStorage.getItem("role") === "Student") {
                                                    confirm({
                                                        title: "Submit Confirmation",
                                                        description: "Are you sure, you want to submit the Test?",
                                                    }).then(() => onSubmitTest());
                                                } else toast.error("Only students can submit the test");

                                            }} className="btn btn-success ">Submit </Button>
                                        </div>
                                    </div>

                                </>
                            }

                        </div> : <div>
                            {/* ==================================================== After Submit test Part ===============================================================*/}
                            {/* ==================================================== After Submit test Part ===============================================================*/}

                            <div className="row my-4">
                                <div className="col-12 ">
                                    <Button variant="contained" onClick={() => navigate("/all-tests")} >Back To My Tests</Button>
                                </div>
                            </div>

                            {/* ----------------------- */}
                            {/* ----------------------- */}

                            <div className="row">
                                <div className="col-12 mx-auto ">
                                    <h5>Mind-Math</h5>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 mx-auto ">

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>{head_id_mm.table1.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}</TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {show_mm && data_mm.main_arr1.map(x => x.map((x, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                        </TableRow>
                                                    )
                                                }))}

                                                <GenerateInputRow readOnly={true} from={1} to={10} length={length_MM} field={field1} answer={answer_mm.answer1} />

                                                <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                    {answer_mm.answer1.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>

                            <div className="row my-5">
                                <div className="col-12 mx-auto ">

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>{head_id_mm.table2.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}</TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {show_mm && data_mm.main_arr2.map(x => x.map((x, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                        </TableRow>
                                                    )
                                                }))}

                                                <GenerateInputRow readOnly={true} from={11} to={20} length={length_MM} field={field1} answer={answer_mm.answer2} />

                                                <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                    {answer_mm.answer2.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>

                            <div className="row my-5">
                                <div className="col-12 mx-auto">

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>

                                                    {head_id_mm.table3.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {show_mm && data_mm.main_arr3.map(x => x.map((x, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                        </TableRow>
                                                    )
                                                }))}

                                                <GenerateInputRow readOnly={true} from={21} to={30} length={length_MM} field={field1} answer={answer_mm.answer3} />

                                                <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                    {answer_mm.answer3.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>

                            {/* ----------------------- */}
                            {/* ----------------------- */}

                            <hr className="p-0 mt-4 mx-auto" style={{ border: "none", borderBottom: ".4em dotted #174873", width: "10%" }} />
                            <div className="row">
                                <div className="col-12 mx-auto ">
                                    <h5>Abacus</h5>
                                </div>
                            </div>

                            <div className="row mb-5">
                                <div className="col-12 mx-auto ">

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {head_id_ab.table1.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {data_ab.main_arr1.map(x => x.map((x, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                        </TableRow>
                                                    )
                                                }))}

                                                <GenerateInputRow readOnly={true} from={1} to={10} length={length_AB} field={field2} answer={answer_ab.answer1} />

                                                <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                    {answer_ab.answer1.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>

                            <div className="row my-5">
                                <div className="col-12 mx-auto ">

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {head_id_ab.table2.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {data_ab.main_arr2.map(x => x.map((x, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                        </TableRow>
                                                    )
                                                }))}

                                                <GenerateInputRow readOnly={true} from={11} to={20} length={length_AB} field={field2} answer={answer_ab.answer2} />

                                                <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                    {answer_ab.answer2.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>

                            <div className="row my-5">
                                <div className="col-12 mx-auto ">

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {head_id_ab.table3.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {data_ab.main_arr3.map(x => x.map((x, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                        </TableRow>
                                                    )
                                                }))}

                                                <GenerateInputRow readOnly={true} from={21} to={30} length={length_AB} field={field3} answer={answer_ab.answer3} />

                                                <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                    {answer_ab.answer3.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>

                            <div className="row my-5">
                                <div className="col-12 mx-auto ">

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {head_id_ab.table4.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {data_ab.main_arr4.map(x => x.map((x, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                        </TableRow>
                                                    )
                                                }))}
                                                <GenerateInputRow readOnly={true} from={31} to={40} length={length_AB} field={field3} answer={answer_ab.answer4} />

                                                <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                    {answer_ab.answer4.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>

                            <div className="row my-5">
                                <div className="col-12 mx-auto ">

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {head_id_ab.table5.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {data_ab.main_arr5.map(x => x.map((x, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                        </TableRow>
                                                    )
                                                }))}
                                                <GenerateInputRow readOnly={true} from={41} to={50} length={length_AB} field={field4} answer={answer_ab.answer5} />

                                                <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                    {answer_ab.answer5.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>

                            <div className="row my-5">
                                <div className="col-12 mx-auto ">

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {head_id_ab.table6.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {data_ab.main_arr6.map(x => x.map((x, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                        </TableRow>
                                                    )
                                                }))}
                                                <GenerateInputRow readOnly={true} from={51} to={60} length={length_AB} field={field4} answer={answer_ab.answer6} />

                                                <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                    {answer_ab.answer6.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>

                            <div className="row my-5">
                                <div className="col-12 mx-auto ">

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {head_id_ab.table7.map(x => x.map(x => <TableCell style={{ backgroundColor: "#416997", color: "#fff", fontWeight: "bolder" }} key={x} className="text-center">{x}</TableCell>))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {data_ab.main_arr7.map(x => x.map((x, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            {x.map((x, i) => (x !== "") ? <TableCell key={i} style={{ fontWeight: ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.")) ? "600" : "400", color: ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black" }} className="text-center">{x}</TableCell> : <TableCell key={i}>&nbsp;</TableCell>)}
                                                        </TableRow>
                                                    )
                                                }))}
                                                <GenerateInputRow readOnly={true} from={61} to={70} length={length_AB} field={field5} answer={answer_ab.answer7} />

                                                <TableRow style={{ backgroundColor: "#DF9816" }}>
                                                    {answer_ab.answer7.map(x => x[0].map((x, i) => <TableCell key={i} style={{ color: "#000", fontWeight: "bolder" }} className="text-center">{x}</TableCell>))}
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>

                        </div>}

                        {/* after clicking  submit button below  Result will be shown on the screen  */}
                        {result && (<ShowResult />)}
                    </div>
                </div>
            </div>
            <Backdrop sx={{ zIndex: 3001, color: '#fff' }} open={backdrop}><CircularProgress style={{ color: "#fff" }} /></Backdrop>
        </>

    )
}

export default TestSheetComp;