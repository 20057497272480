import RestorePageIcon from '@mui/icons-material/RestorePage';
import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Heading from "../../../../Common/Heading";
import LoadingSpinner from "../../../../Common/LoadingSpinner";

import CloudOffIcon from '@mui/icons-material/CloudOff';
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import url from "../../../../Common/URL";
import { QuestionTypeFullName } from "../../Mind Math/Component/LevelData";

import { IconButton } from "@mui/material";
import { Box, Stack } from "@mui/system";
import MaterialReactTable from 'material-react-table';
import { useSelector } from 'react-redux';

const AssignmentHistoryComp = () => {

    const [loading, setLoading] = useState(true);

    const [score, setScore] = useState({
        exam_test: [], assignment: []
    });

    useEffect(() => {
        let pro1 = new Promise((resolve, reject) => {
            loadScores(resolve);
        })
        pro1.then(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Columns = useMemo(
        () => [
            { header: 'Level', accessorKey: 'level_name' },
            { header: 'Test Name', id: "test_name", accessorFn: rowData => rowData.test_mode === "offline" ? (<span className="d-flex align-items-center">{rowData.test_name}&nbsp;<CloudOffIcon style={{ color: "#810000", fontSize: "95%" }} /></span>) : rowData.test_name },
            { header: 'Date', accessorKey: 'created_date' },
        ],
        [],
    );

    const loadScores = (res) => {
        const obj = { uname: sessionStorage.getItem("username") }
        axios.post(url + "php/questions/select_score.php", obj)
            .then((response) => {
                // console.log(response)
                if (response.data.success) {
                    let b = JSON.parse(response.data.data);
                    let a = b !== null ? b.reverse() : b;
                    // console.log(a);
                    if (a !== null) {
                        var assignment_arr = a.filter(x => x.test_type === "Assignment")
                        setScore({ ...score, assignment: assignment_arr.length === 0 ? [] : assignment_arr })
                    } else {
                        setScore({ ...score, assignment: [], exam_test: [] });
                    }

                } else { setScore({ ...score, assignment: [], exam_test: [] }) }
                res();
            })
            .catch((err) => console.log(err));
    }

    // ########################################################### Mind-math History  ##################################################

    // For Mind-Math Table

    const [mmHistoryFetchSpinner, setMmHistoryFetchSpinner] = useState(false);

    const calculateAccuracyPercentage = (x) => `${((x.total_correct / x.questions_attempted) * 100).toFixed(2)} %`;

    const [dateRange, setDateRange] = useState({
        from: "", to: ""
    })
    const mmColumns = useMemo(
        () => [
            { header: 'Question Level', id: 'que_level', accessorFn: rowData => `Level ${rowData.question_level}` },
            { header: 'Type', id: 'type', accessorFn: rowData => QuestionTypeFullName[rowData.question_type] },
            { header: 'Specific', accessorKey: 'question_specific' },
            { header: 'Total Attempted', accessorKey: 'questions_attempted' },
            { header: 'Total Correct', accessorKey: 'total_correct' },
            { header: 'Accuracy Percentage', id: 'acc_per', accessorFn: rowData => calculateAccuracyPercentage(rowData) },
        ],
        [],
    );
    const [mm_data, setMm_data] = useState([]);


    const fetchMmHistory = (e) => {
        e.preventDefault();
        if (dateRange.from > dateRange.to) {
            toast.error("This date range is incorrect, make the 'To' field larger than the 'From' field");
            return;
        }
        setMmHistoryFetchSpinner(true);
        const obj = { uname: sessionStorage.getItem('username'), from: dateRange.from, to: dateRange.to };
        console.log(obj);
        axios.post(url + "php/mindmath/select_mm.php", obj)
            .then((response) => {
                console.log(response)
                if (response.data.success) {
                    let a = JSON.parse(response.data.data);
                  //  console.log(a);
                    a === null ? setMm_data([]) : setMm_data(a);
                } else {
                    setMm_data([]);
                }
                setMmHistoryFetchSpinner(false);
            })
            .catch((err) => console.log(err));
    }


    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Tests</li>
                        <li className="breadcrumb-item active">Tests</li>
                        {/* <li className="breadcrumb-item active" aria-current="page">Test History</li> */}
                    </ol>
                </nav>

                <Heading name="Tests" />
                {loading ? <LoadingSpinner /> :

                    <div className="container-fluid">

                        <div className="row mb-4">
                            <nav className="col-12 mx-auto ">
                                <div className="nav border-bottom" id="nav-tab" role="tablist">
                                    <Link className="nav_tab nav-link" to="/all-tests" role="tab">My Tests</Link>
                                    <Link className="nav_tab nav-link " to="/test-history" role="tab" >Exam & Test History</Link>
                                    <Link className="nav_tab nav-link active" to="/tasks-history" role="tab" >Mind-Math & Assignment History</Link>
                                </div>
                            </nav>
                        </div>

                        <h5 >Mind Math History</h5>
                        <div className="row mt-3">
                            <div className="col-12 mb-4">
                                <form onSubmit={fetchMmHistory} >
                                    <h6 className="form-label">Select Date Range</h6>
                                    <Stack direction='row' gap={2} className="align-items-center">
                                        <div className="form-floating ">
                                            <input className="form-control" type="date" onChange={(e) => setDateRange({ ...dateRange, from: e.target.value })} required></input>
                                            <label htmlFor="floatingInput">From</label>
                                        </div>

                                        <div className="form-floating ">
                                            <input className="form-control" type="date" onChange={(e) => setDateRange({ ...dateRange, to: e.target.value })} required></input>
                                            <label htmlFor="floatingInput">To</label>
                                        </div>

                                        {mmHistoryFetchSpinner ?
                                            <CircularProgress size={20} className='ms-4' /> :
                                            <Button variant="contained" type="submit">Proceed</Button>
                                        }

                                    </Stack>
                                </form>

                            </div>
                            <div className="col-12 mx-auto">
                                <MaterialReactTable
                                    columns={mmColumns}
                                    data={mm_data}
                                    enableRowNumbers
                                    initialState={{ density: 'compact' }}
                                    enableDensityToggle={false}

                                />
                            </div>
                        </div>

                        <h5 className="mt-4 mb-3">Assignment History</h5>

                        <div className="row mb-5">
                            <div className="col-12 mx-auto">

                                <MaterialReactTable
                                    columns={Columns}
                                    data={score.assignment}
                                    enableRowNumbers
                                    initialState={{ density: 'compact' }}
                                    enableDensityToggle={false}

                                    getRowId={(originalRow) => originalRow.id}
                                    positionActionsColumn='last'
                                    enableRowActions
                                    renderRowActions={(row, index) => (
                                        <Box>
                                            {row.row.original.test_mode !== "offline" &&
                                                <Tooltip title='View Answers'>
                                                    <IconButton onClick={() => {
                                                        let rowData = row.row.original;
                                                        var test_name = rowData.test_name;
                                                        var test_id = rowData.test_id;
                                                        var st_uname = rowData.user_name;
                                                        var date_time = rowData.created_date;
                                                        window.open(`/assignment-preview/${test_name}/${test_id}/${1}/${date_time}/${st_uname}`, "_blank");
                                                    }}>
                                                        <RestorePageIcon />
                                                    </IconButton>
                                                </Tooltip>}
                                        </Box>
                                    )}
                                />
                            </div>
                        </div>
                    </div>}
            </div>
        </>
    )
}
export default AssignmentHistoryComp

