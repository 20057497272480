import React from "react"
import Navbar from "../../../../Common/Navbar"
import Footer from "../../../../Common/Footer"
import Sidebar from "../../../../Sidebar/Sidebar"
import AdmissionDetailsComp from "./Component/AdmissionDetailsComp"

const AdmissionDetails = () => {
    return (
        <>
            <Navbar />
            <div className="d-flex mainContentArea">
                <Sidebar />
                <AdmissionDetailsComp />
            </div>
            <Footer />
        </>
    )
}

export default AdmissionDetails