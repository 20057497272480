import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Input, { ReadonlyInput } from "../../../Setup/Student/Component/Input";
import Heading from "../../../../Common/Heading";
import axios from "axios";
import url from "../../../../Common/URL";
import { useConfirm } from "material-ui-confirm";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";


const EditAccountFeeComp = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [stu, setStu] = useState([]);
  const { id } = useParams();

  const [state, setState] = useState({
    stud_id: "",
    fee: "",
    duration: "",
    fname: "",
    lname: "",
    stu_uname: "",
    valid_from: "",
    valid_to: "",
    fees_mode: "",
    paid: "",
  });

  useEffect(() => {
    loadFee();
    loadStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----------This function will Fetch all the Existing Locations--------------------
  const loadFee = async () => {
    const obj = {
      id: id,
      uname: sessionStorage.getItem("username"),
    };

    await axios
      .post(url + "php/student_fee/select_fee.php", obj)
      .then((response) => {
        let a = JSON.parse(response.data.data);
        // console.log(a);
        setState({
          stud_id: a[0].stud_id,
          fname: a[0].fname,
          lname: a[0].lname,
          fee: a[0].fee,
          duration: a[0].duration,
          stu_uname: a[0].user_name,
          valid_from: a[0].valid_from,
          valid_to: a[0].valid_to,
          fees_mode: a[0].fees_mode,
          paid: a[0].paid,
        });
      })
      .catch((err) => console.log(err));
  };

  // -----------This function will Fetch all the Existing Students--------------------
  const loadStudents = async () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
    };

    await axios
      .post(url + "php/students/students.php", obj)
      .then((response) => {
        let a = JSON.parse(response.data.data);
        // console.log(a);
        setStu(a);
      })
      .catch((err) => console.log(err));
  };
  //---------------One onChange function is used for setting the state of various types of input fields ---------------

  const onChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.type === "checkbox" ? input.checked : input.value;
    setState((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };
  // ------------------------Deleting Fee table's data-------------------------------------

  const onDelete = (e) => {
    e.preventDefault();
    confirm({ title: "Delete Confirmation", description: "This will delete this log permanently." }).then(() => {
      const delobj = {
        id: id,
        uname: sessionStorage.getItem("username"),
        tokenId: 123,
      };
      axios
        .post(url + "php/student_fee/delete_fee.php", delobj)
        .then((res) => {
          // console.log(res);
          toast.error("Log Deleted");
          navigate("/act-fee");
        })
        .catch((err) => console.log(err));
    });
  };

  //-------------------------- onSubmit function for Submitting the  Forms---------------------------

  const onSubmit = (e) => {
    e.preventDefault();

    // ------------Storing form data in "obj" object fetched from various input fields-------------
    const obj = {
      uname: sessionStorage.getItem("username"),
      stud_id: state.stud_id,
      id: id,
      updated_by: sessionStorage.getItem("username"),
      fname: state.fname,
      fee: state.fee,
      duration: state.duration,
      lname: state.lname,
      user_name: state.stu_uname,
      valid_from: state.valid_from,
      valid_to: state.valid_to,
      fees_mode: state.fees_mode,
      paid: state.paid,
    };
    // ------------Sending form data stored in "obj" object to the add_student.php file uusing AXIOS-------------

    axios
      .post(url + "php/student_fee/edit_fee.php", obj)
      .then((res) => {
        // console.log(res)
        if (res.data.success) {
          toast.success("Accounts transaction updated");
        } else {
          alert("Unable to update log");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        {/*-----------------------Bread crumb------------------------- */}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item">
              <Link to="/bulletin">Home</Link>
            </li>
            <li className="breadcrumb-item">Accounts
            </li>
            <li className="breadcrumb-item ">
              <Link to="/act-fee">Accounts Fees</Link>
            </li>
            <li className="breadcrumb-item active">Update Fee</li>
          </ol>
        </nav>
        <Heading name="Edit Fee" />
        {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
        <form onSubmit={onSubmit}>
          <div className="container-fluid mt-3">
            <div className="row my-4">
              <div className="col-12 d-flex justify-content-end mx-auto">
                <Button variant="contained" onClick={onDelete} >
                  Delete
                </Button>
                <Button variant="contained" type="submit" className="ms-3">
                  Save
                </Button>
              </div>
            </div>

            <div className="row">
              {/*-----------------------Column-1 for Inputs------------------------- */}
              <div className="col-11 col-md-6 mx-auto mx-md-0">
                <h5 className="mb-4">Fee Information</h5>

                {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label red-asterisk">Student</label>
                        <div className="col-sm-7 col-md-7 ">
                        <select required name="stud_id" value={`${state.stud_id}#${state.fname}#${state.lname}#${state.stu_uname}`}  
                                onChange={(e)=>{
                                    let val = e.target.value;
                                    let id = val.split("#")[0] ;
                                    let fn = val.split("#")[1];
                                    let ln = val.split("#")[2];
                                    let un = val.split("#")[3];
                                    setState({...state , stud_id:id , fname:fn , lname:ln , stu_uname:un});
                                }}                          
                            className="form-select">
                            <option value="" >Choose</option>
                            {stu.map((data , i)=>(<option key={i} value={`${data.stud_id}#${data.fname}#${data.lname}#${data.user_name}`}>{`${data.fname} ${data.lname}`}</option>)) } 
                            </select>
                        </div>
                    </div> */}

                <ReadonlyInput
                  value={`${state.fname} ${state.lname}`}
                  field="Student"
                  type="text"
                  
                />

                <Input
                  name="fee"
                  onChange={onChange}
                  field="Fee"
                  type="text"
                  className="form-control"
                  value={state.fee}
                  required={true}
                />

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label red-asterisk">Duration</label>
                  <div className="col-sm-7 col-md-7 ">
                    <select
                      required
                      name="duration"
                      value={state.duration}
                      onChange={onChange}
                      className="form-select">
                      <option value="">Choose</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Yearly">Yearly</option>
                      <option value="Lump Sum">Lump Sum</option>
                    </select>
                  </div>
                </div>
                <Input
                  name="valid_from"
                  onChange={onChange}
                  field="Valid From"
                  type="date"
                  className="form-control"
                  value={state.valid_from}
                  required={true}
                />
                <Input
                  name="valid_to"
                  onChange={onChange}
                  field="Valid To"
                  type="date"
                  className="form-control"
                  value={state.valid_to}
                  required={true}
                />

                <div className="mb-3 row">
                  <label className="col-sm-5 col-form-label ">Paid</label>
                  <div className="col-sm-7 col-md-7 align-items-center d-flex ">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="paid"
                        id="inlineRadio1"
                        value=""
                        checked={state.paid === ""}
                        onChange={onChange}
                      />

                      <label className="form-check-label" htmlFor="inlineRadio1">
                        Unpaid
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="paid"
                        id="inlineRadio2"
                        value="1"
                        checked={state.paid === "1"}
                        onChange={onChange}
                      />

                      <label className="form-check-label" htmlFor="inlineRadio2">
                        Paid
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default EditAccountFeeComp;
