import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../../../Common/Heading";
// import {toast} from "react-toastify"
import LoadingSpinner from "../../../../Common/LoadingSpinner";

import AssessmentIcon from '@mui/icons-material/Assessment';
import RestorePageIcon from '@mui/icons-material/RestorePage';


import axios from "axios";
import url from "../../../../Common/URL";
// import { toast } from "react-toastify";
import { Summarize } from "@mui/icons-material";
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { IconButton, Stack, Tooltip } from "@mui/material";
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";


const ExamAndTestHistoryComp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const role = sessionStorage.getItem("role");
  const [state] = useState({
    uname: sessionStorage.getItem("username"),
    role: sessionStorage.getItem("role"),
  })


  const Columns = useMemo(() => ((state.role === "Superadmin") || (state.role === "Systemadmin") || (state.role === "Location Manager") || (state.role === "Teacher") || (state.role === "Admin")) ?
    [
      { header: 'Name', id: 'name', accessorFn: rowData => `${rowData.fname} ${rowData.lname}` },
      { header: 'Level', accessorKey: 'level_name' },
      { header: 'Type', accessorKey: 'test_type' },
      { header: 'Test Name', id: 'test_name', accessorFn: rowData => rowData.test_mode === "offline" ? (<span className="d-flex align-items-center">{rowData.test_name}&nbsp;<CloudOffIcon style={{ color: "#810000", fontSize: "95%" }} /></span>) : rowData.test_name },
      { header: 'Date', accessorKey: "created_date", type: "date" },
    ] :
    [
      { header: 'Level', accessorKey: 'level_name' },
      { header: 'Type', accessorKey: 'test_type' },
      { header: 'Test Name', sorting: false, id: 'test_name', accessorFn: rowData => rowData.test_mode === "offline" ? (<span className="d-flex align-items-center">{rowData.test_name}&nbsp;<CloudOffIcon style={{ color: "#810000", fontSize: "95%" }} /></span>) : rowData.test_name },
      { header: 'Date', accessorKey: "created_date", type: "date" },

    ], [],
  );



  const [data, setData] = useState([]);


  // ------------------------Fetching Scores table data-------------------------------------
  const loadScores = () => {
    const obj = { uname: state.uname }
    if (state.role === "Student") {
      axios.post(url + "php/questions/select_score.php", obj)
        .then((response) => {
          // console.log(response)
          if (response.data.success) {
            let b = JSON.parse(response.data.data);
            let remove_assignment_scores = b !== null ? b.filter(x => x.test_type !== "Assignment") : b;
            let a = remove_assignment_scores !== null ? remove_assignment_scores.reverse() : remove_assignment_scores;
            console.log(a);
            setLoading(false);
            a !== null ? setData(a) : setData([]);
          } else {
            setLoading(false);
            setData([]);
          }
        })
        .catch((err) => console.log(err));
    }
    else {
      axios.post(url + "php/questions/scores.php", obj)
        .then((response) => {
          // console.log(response)
          if (response.data.success) {
            let a = JSON.parse(response.data.data);
            // let remove_assignment_scores =  a !== null ?  a.filter(x => x.test_type !== "Assignment") : a;
            // let reverse = remove_assignment_scores !== null ? remove_assignment_scores.reverse() : remove_assignment_scores;
            let reverse = a !== null ? a.reverse() : a;

            // console.log(a);
            setLoading(false);
            reverse !== null ? setData(reverse) : setData([]);
          } else {
            setLoading(false);
            setData([]);
          }
        })
        .catch((err) => console.log(err));
    }

  }

  useEffect(loadScores, []);


  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Tests</li>
            <li className="breadcrumb-item active">Tests</li>
            {/* <li className="breadcrumb-item active" aria-current="page">Test History</li> */}
          </ol>
        </nav>

        <Heading name="Tests" />
        {loading ? <LoadingSpinner /> :

          <div className="container-fluid">

            <div className="row mb-4">
              <nav className="col-12 mx-auto ">
                {role === "Student" ?
                  <div className="nav border-bottom" id="nav-tab" role="tablist">
                    <Link className="nav_tab nav-link" to="/all-tests" role="tab">My Tests</Link>
                    <Link className="nav_tab nav-link active" to="/test-history" role="tab" >Exam & Test History</Link>
                    <Link className="nav_tab nav-link " to="/tasks-history" role="tab" >Mind-Math & Assignment History</Link>
                  </div> :
                  // Role other that Student (Admin, SuperAdmin, SystemAdmin)
                  <div className="nav border-bottom" id="nav-tab" role="tablist">
                    <Link className="nav_tab nav-link active" to="/test-history" role="tab" >Exam, Test & Assignment History</Link>
                  </div>
                }
              </nav>
            </div>


            <div className="row mt-4 mb-3 d-flex justify-content-end">
              <div className="col-12 ">
                <div className="d-flex flex-column ">
                  <p style={{ fontWeight: "500" }}><AssessmentIcon style={{ color: "#4BB543" }} />&nbsp; Reports Approved & Available To Download -- Reports are generated for Test and Exam only --</p>
                  {/* <p style={{fontWeight:"500"}}><AssessmentIcon />&nbsp; Report Not Available To Download</p> */}
                  <p style={{ fontWeight: "500" }}><CloudOffIcon style={{ color: "#810000" }} />&nbsp; Test given Offline</p>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 ">

                <MaterialReactTable
                  columns={Columns}
                  data={data}
                  enableRowNumbers
                  initialState={{ density: 'compact' }}
                  enableDensityToggle={false}

                  positionActionsColumn='last'
                  enableRowActions
                  renderRowActions={(row, index) => (
                    <Stack direction="row" >
                      {row.row.original.test_mode !== "offline" &&
                        <Tooltip title="View Your Answers">
                          <IconButton onClick={() => {
                            let rowData = row.row.original;
                            var level_name = rowData.level_name;
                            var test_type = rowData.test_type;
                            var test_name = rowData.test_name;
                            var test_id = rowData.test_id;
                            var st_uname = rowData.user_name;
                            var date_time = rowData.created_date;

                            if (rowData.generator_test === '1') {
                              const win = window.open(`/modern-test-exam-preview/${rowData.test_id}/${1}/${rowData.user_ans_id}`, "_blank");
                              win.focus();
                            }
                            else if (test_type === "Exam") {
                              const win = window.open(`/exam-preview/${test_name}/${test_id}/${1}/${date_time}/${st_uname}`, "_blank");
                              win.focus();
                            } else if (test_type === "Assignment") {
                              const win = window.open(`/assignment-preview/${test_name}/${test_id}/${1}/${date_time}/${st_uname}`, "_blank");
                              win.focus();
                            } else {
                              const win = window.open(`/test-preview/${test_name}/${test_id}/${1}/${date_time}/${st_uname}`, "_blank");
                              win.focus();
                            }
                          }}>
                            <RestorePageIcon />
                          </IconButton></Tooltip>}

                      {row.row.original.test_type !== "Assignment" &&
                        <Tooltip title='View Report'>
                          <span>
                            <IconButton
                              style={{ color: row.row.original.approved === "1" && "#4BB543" }}
                              disabled={role !== "Student" ? false : (row.row.original.approved === "1" ? false : true)}
                              onClick={() => navigate(`/view-test-history/${row.row.original.user_ans_id}`, { state: { from: 'TestHistoryPage', description: "No id needed here, because we don't need to move back to student account" } })}>
                              <Summarize />
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                    </Stack>
                  )}
                />
              </div>
            </div>

          </div>}
      </div>
    </>
  )
}
export default ExamAndTestHistoryComp

