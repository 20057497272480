import React from "react"

const AlertBlockForQuestionPapers = () => {


    return (
        <>
            <div className='col-auto'>
                <div className="alert alert-danger py-1 px-2 mb-3" role="alert">
                    Do not click the <strong>Back</strong> button or <strong>Reload</strong> the browser, otherwise you will lose all your progress.
                </div>
                <div className="alert alert-warning py-1 px-2" role="alert">
                    <strong>Division & Multiplication</strong> questions with <strong>decimal answers</strong> should have answers up to <strong>two decimal places without rounding</strong>.
                    <br /><strong>If your answer is less than 0 </strong>, like <strong>(0.0016, 0.008, 0.056), don't stop at 2 decimal places, reach up to 2 non-zero decimal places (means up to 3 or 4 decimal places)</strong>.
                </div>
            </div>


        </>
    )
}
export default AlertBlockForQuestionPapers