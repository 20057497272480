import React from "react"
import Navbar from "../../../Common/Navbar"
import Footer from "../../../Common/Footer"
import Sidebar from "../../../Sidebar/Sidebar"
import EditActTransComp from "./Component/EditActTransComp"


const EditActTrans =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <EditActTransComp />
        </div>
        <Footer />
        </>
    )
}

export default EditActTrans