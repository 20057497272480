import React from "react"
import Navbar from "../Common/Navbar"
// import Footer from "../Common/Footer"
import Forget_pass_comp from "./Component/Forget_pass_comp"


const ForgetPass =()=>{
    return(
        <>
       
        <Navbar />
        <div className="d-flex ">
            <Forget_pass_comp />
        </div>
        </>
    )
}

export default ForgetPass