import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Sidebar from "../../Sidebar/Sidebar";
import ProfileComp from "./Component/ProfileComp";


const Profile =()=>{

    return(

        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <ProfileComp />
        </div>
        <Footer />
        </>
    )
}
export default Profile

