import { Button } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Heading from "../../../../Common/Heading"
import { CurrentDate } from "../../../../Common/StringDateTime"
import url from "../../../../Common/URL"
import Input from "../../Student/Component/Input"

import { useSelector } from "react-redux"
import Select from 'react-select'


const AddManagerComp = () => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // This are used to display Values in Select options 
    const [loc, setLoc] = useState([]);

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"),
        // ------------normal input fields--------------
        fname: "", mname: "", lname: "", city: "", zip: "", mobile_number: "", home_number: "", email: "",
        join_date: "", leave_date: "", registration_number: "", approved_date: "", kvk_number: "",
        contract_period: "", contract_renewal_date: "", probation_period: "",
        // ------------Textarea input fields--------------
        address_line1: "", address_line2: "",

        // ------------Checkbox input fields--------------
        active: true, photo_consent: "", disclaimer_signed: "",

        // ------------Select inputs fields--------------
        province: "", country: "",
        location_name: [], batch_name: [], level_name: [], user_name: "",
        picture: "",
    })

    useEffect(() => {
        loadLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        axios.post(url + "php/locations/locations.php", obj)
            .then((response) => {
                let a = JSON.parse(response.data.data);
                //   console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    }

    //---------------One onChange function is used for setting the state of various types of input fields ---------------     

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    // ------------------- -------------------functions for Submitting Country and state- ------------------- -------------------
    const selectCountry = (val) => {

        setState((preValue) => {
            return {
                ...preValue,
                country: val
            }
        })
    }
    const selectRegion = (val) => {

        setState((preValue) => {
            return {
                ...preValue,
                province: val
            }
        })
    }
    // ------------------- -----------------handleInputChange function for Submitting the profile-pic------------------- -------------------
    // const handleInputChange = (e) =>{
    //     setState({ picture: e.target.files[0] })

    // }
    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        let locationString = state.location_name.map(({ value }) => value).join();

        // ------------Storing form data in "obj" object fetched from various input fields-------------
        const obj = {
            fname: state.fname, mname: state.mname, lname: state.lname, uname: sessionStorage.getItem("username"),
            created_by: sessionStorage.getItem("username"),
            city: state.city, zip: state.zip, mobile_number: state.mobile_number,
            home_number: state.home_number, email: state.email,
            created_date: CurrentDate(),

            address_line1: state.address_line1, address_line2: state.address_line2, kvk_number: state.kvk_number,
            contract_period: state.contract_period, contract_renewal_date: state.contract_renewal_date,
            probation_period: state.probation_period, join_date: state.join_date, leave_date: state.leave_date,

            active: state.active, photo_consent: state.photo_consent, disclaimer_signed: state.disclaimer_signed,
            user_name: state.user_name,
            province: state.province, country: state.country,

            location_name: locationString,


        }

        const formData = new FormData();
        Object.keys(obj).forEach(key => formData.append(key, obj[key]));


        // ------------Sending form data stored in "obj" object to the add_student.php file using AXIOS-------------
        // console.log(...formData)

        axios.post(url + "php/manager/add_manager.php", formData)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    if (res.data.mail_sent === 1) {
                        toast.success("New manager added");
                    } else toast.warning("New manager added, but mail was not sent due to an error");
                    navigate("/manager");
                } else if (res.data.message === "Email Already exist...") {
                    toast.error("Email already exist, manager can't be created.")
                }
                else {
                    alert("Location Manager not created, pLease try again!")
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                {/*----------------------- Bread crumb ------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/manager">Managers List</Link></li>
                        <li className="breadcrumb-item active">Add Manager</li>
                    </ol>
                </nav>
                <Heading name="Add Manager" />
                {/* ------------------------------------------------- Two Columns for Inputs --------------------------------------- */}
                <form onSubmit={onSubmit}>

                    <div className="container-fluid mt-3">

                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-end mx-auto">
                                <Button variant="contained" disabled={loading} type="submit" >Save</Button>
                            </div>
                        </div>

                        <div className="row">

                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Personal Information</h5>


                                {/* <Input field="Profile Picture" type="file" className="form-control-file" onChange={handleInputChange} name="picture" /> */}

                                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Teacher ID</label>
                                <div className="col-sm-7 col-md-6 ">
                                <input type="text" readonly className="form-control-plaintext" value="<Auto generated>" />
                                </div>
                            </div> */}

                                <Input name="fname" onChange={onChange} field="First Name" type="text" placeholder="Add text" className="form-control" value={state.fname} required={true} />

                                <Input name="mname" onChange={onChange} field="Middle Name" type="text" placeholder="Add text" className="form-control" value={state.mname} required={false} />

                                <Input name="lname" onChange={onChange} field="Last Name" type="text" placeholder="Add text" className="form-control" value={state.lname} required={true} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Address Line 1</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea required name="address_line1" onChange={onChange} value={state.address_line1} className="form-control" placeholder="Add text" ></textarea>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Address Line 2</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea name="address_line2" onChange={onChange} value={state.address_line2} className="form-control" placeholder="Add text" ></textarea>
                                    </div>
                                </div>

                                <Input name="city" onChange={onChange} field="City" type="text" placeholder="Add text" className="form-control" value={state.city} required={true} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Country</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        {/* <select name="state" value={state.state} onChange={onChange} className="form-select">
                                    <option value="province-1">Province 1</option><option value="province-2">Province 2</option><option value="province-3">Province 3</option>
                                </select> */}

                                        <CountryDropdown
                                            className="form-select"
                                            name="country"
                                            value={state.country}
                                            onChange={(val) => selectCountry(val)} />

                                    </div>
                                </div>


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Province</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        {/* <select name="country" value={state.country} onChange={onChange} className="form-select">
                                    <option value="1">Country 1</option><option value="country-2">Country 2</option><option value="country-3">Country 3</option>
                                </select> */}

                                        <RegionDropdown
                                            className="form-select"
                                            name="province"
                                            country={state.country}
                                            value={state.province}
                                            onChange={(val) => selectRegion(val)} />
                                    </div>
                                </div>

                                <Input name="zip" onChange={onChange} field="Zip" type="text" placeholder="Add text" className="form-control" value={state.zip} required={true} />

                                <Input name="mobile_number" onChange={onChange} field="Mobile Number" type="text" placeholder="Add text" className="form-control" value={state.mobile_number} required={true} />

                                <Input name="home_number" onChange={onChange} field="Home Phone" type="text" placeholder="Add text" className="form-control" value={state.home_number} required={false} />

                                <Input name="email" onChange={onChange} field="Email" type="email" placeholder="Add text" className="form-control" value={state.email} required={true} />

                                <Input name="kvk_number" onChange={onChange} field="KVK Number" type="text" placeholder="Add text" className="form-control" value={state.kvk_number} required={true} />

                                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Contract Document</label>
                                <div className="col-sm-7 col-md-7 d-flex align-items-center">
                                   <a href="/404" download >View Contract Document &nbsp;<i className="fa fa-download"></i></a>
                                </div>
                            </div> */}

                            </div>

                            {/*-----------------------Column-2 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Location Information</h5>


                                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label red-asterisk">Location Name</label>
                                <div className="col-sm-7 col-md-7 ">
                                   <select className="form-control" onChange={onChange} value={state.location_name} name="location_name" required>
                                    <option value="">Choose</option>
                                        {loc.map((x,i)=><option key={i} value={x.name}>{x.name}</option>)}
                                   </select>
                                </div>
                            </div> */}
                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Location Name</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <Select
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            isClearable={true}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={loc.map(x => ({ label: x.name, value: x.name }))}
                                            value={state.location_name}
                                            onChange={(e) => setState({ ...state, location_name: e })}
                                        />
                                    </div>
                                </div>
                                

                                <h5 className="mt-5 mb-4">Account Information</h5>

                                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">User</label>
                                <div className="col-sm-7 col-md-6 ">
                                    <select  name="user_name" value={state.user_name} onChange={onChange} className="form-select">
                                    <option >Choose</option>
                                    {usr.map((data , i)=>(<option key={i} value={data.username}>{data.username}</option>)) } 
                                    </select>
                                </div>
                            </div> */}

                                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Active Account</label>
                                <div className="col-sm-7 col-md-6 ps-4">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="active" checked={state.active} onChange={onChange} />
                                    </div>
                                </div>
                            </div> */}

                                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Photography Consent Provided?</label>
                                <div className="col-sm-7 col-md-6 ps-4">
                                <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="photo_consent" checked={state.photo_consent} onChange={onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Disclaimer Signed?</label>
                                <div className="col-sm-7 col-md-6 ps-4">
                                <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="disclaimer_signed" checked={state.disclaimer_signed} onChange={onChange} />
                                    </div>
                                </div>
                            </div> */}

                                <Input name="join_date" onChange={onChange} field="Join Date" type="date" placeholder="Add text" className="form-control" value={state.join_date} required={true} />

                                <Input name="probation_period" onChange={onChange} value={state.probation_period} field="Probation Period" type="number" placeholder="In Months" className="form-control" />

                                <Input name="contract_renewal_date" onChange={onChange} value={state.contract_renewal_date} field="Contract Renewal Date" type="date" placeholder="Add text" className="form-control" />

                                <Input name="contract_period" onChange={onChange} value={state.contract_period} field="Contract Period" type="number" placeholder="In Months" className="form-control" />

                                <Input name="leave_date" onChange={onChange} value={state.leave_date} field="Leave Date" type="date" placeholder="Add text" className="form-control" />

                            </div>

                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}
export default AddManagerComp;