import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import Heading from "../../../../Common/Heading";
import { toast } from "react-toastify"
import LoadingSpinner from "../../../../Common/LoadingSpinner";

import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';

import axios from "axios";
import url from "../../../../Common/URL"

import { useConfirm } from 'material-ui-confirm';
import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";

const QuestionTable = () => {

  const confirm = useConfirm();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [lev, setLev] = useState([]);

  const [search, setSearch] = useState({
    level: "", type: "", page: ""
  })

  const Columns = useMemo(
    () => [

      { header: 'Level', accessorKey: 'level_name' },
      { header: 'Type', accessorKey: 'que_type' },
      {
        header: 'Question', id: 'ques_num1', accessorFn: rowData => {
          return ` ${rowData.ques_num1} ${rowData.ques_num2} ${rowData.ques_num3} ${rowData.ques_num4} ${rowData.ques_num5} ${rowData.ques_num6} ${rowData.ques_num7} ${rowData.ques_num8} ${rowData.ques_num9} ${rowData.ques_num10} ${rowData.ques_num11} ${rowData.ques_num12} ${rowData.ques_num13} ${rowData.ques_num14} ${rowData.ques_num15} ${rowData.ques_num16}`
        }
      },
      { header: 'Answer', accessorKey: 'answer' },
    ],
    [],
  );

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  const GenerateLevelOption = () => {
    var option = [];

    for (let i = 1; i <= 45; i++) {
      option.push(<option key={i} value={`P${i}`}>{`Page ${i}`}</option>);
    }


    return option;
  }


  // ------------------------Fetching Student table's data-------------------------------------
  useEffect(() => {

    loadLevel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //  ------------------------------------------------
  const loadQuestions = async () => {
    setLoading(true)
    const obj = {
      uname: sessionStorage.getItem("username"), level_name: search.level, que_type: search.type,
      page: search.type === "Assignment" ? search.page : ""
    }
    await axios.post(url + "php/questions/questions_level_type.php", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = JSON.parse(response.data.data);
          // console.log(a);
          a === null ? setData([]) : setData(a);
          setLoading(false)
        } else {
          setData([]);
          setLoading(false)
        }
      })
      .catch((err) => console.log(err));
  }

  // -----------This function will Fetch all the Existing Levels--------------------
  const loadLevel = () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
    };

    axios.post(url + "php/levels/levels.php", obj)
      .then((response) => {
        let a = JSON.parse(response.data.data);
        //   console.log(a);
        setLev(a);
      })
      .catch((err) => console.log(err));
  }
  // ------------------------Deleting Student table's data-------------------------------------

  const onDelete = (id, resolve) => {
    //alert(id);
    const delobj = {
      id: id,
      uname: sessionStorage.getItem("username"),
      tokenId: 123,
    };
    axios.post(url + "php/questions/delete_questions.php", delobj)
      .then((res) => {
        // console.log(res);
        resolve();
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item active" aria-current="page">Question List</li>
          </ol>
        </nav>

        <Heading name="Question List" />

        <div className="row col-12" >

          <div className=" col-md-3 ">

            <select required name="level_name" value={search.level}
              onChange={(e) => setSearch({ ...search, level: e.target.value })} className="form-select">
              <option value="">Choose Level</option>
              {lev.map((data, i) => (<option key={i} value={data.level_name}>{data.level_name}</option>))}
            </select>

          </div>

          <div className="col-md-3 ">

            <select required name="level_name" value={search.type}
              onChange={(e) => setSearch({ ...search, type: e.target.value })} className="form-select">
              <option value="">Choose Question Type</option> <option value="Test">Test</option>
              <option value="Exam">Exam</option> <option value="Assignment">Assignment</option>
            </select>

          </div>

          {search.type === "Assignment" &&
            <div className="col-md-3 ">

              <select required name="level_name" value={search.page}
                onChange={(e) => setSearch({ ...search, page: e.target.value })} className="form-select">
                <option value="">Choose Page</option>
                {GenerateLevelOption()}
              </select>

            </div>}


          <Button className="col-auto ms-auto" variant="contained" disabled={(search.level === "" || search.type === "") ? true : (search.type === "Assignment" && search.page === "" ? true : false)} onClick={loadQuestions}>Search</Button>

        </div>
        <hr className="px-3" />



        <div className="container-fluid">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end">
              <Button variant="contained" onClick={() => navigate("/add-question")} >Add Question<Add /></Button>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mx-auto mb-5">

              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                
                getRowId={(originalRow) => originalRow.ques_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/view-question/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <IconButton
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          // console.log(selectedRows);
                          const delPromise = selectedRows.map((row) => {
                            return new Promise((resolve, reject) => {
                              onDelete(row.id, resolve);
                            })
                          })
                          Promise.all(delPromise).then(() => {
                            loadQuestions();
                            table.resetRowSelection();
                            toast.error(selectedRows.length + " Row(s) Deleted!")
                          });
                        })
                    }}
                  ><DeleteOutline />
                  </IconButton>
                )}
              />
            </div>
          </div>

        </div>
      </div>

    </>
  )

}
export default QuestionTable




