import React from "react"
import Navbar from "../../../Common/Navbar"
import Footer from "../../../Common/Footer"
// import Sidebar from "../../../Sidebar/Sidebar"
import NewExamSheetComp from "./Component/NewExamSheetComp"

const NewExamSheet = () => {
    return (
        <>
            <Navbar />
            <div className="d-flex mainContentArea">
                {/* <Sidebar /> */}
                <NewExamSheetComp />
            </div>
            <Footer />
        </>
    )
}

export default NewExamSheet