import React from "react"
import Navbar from "../../../Common/Navbar"
import Footer from "../../../Common/Footer"
import Sidebar from "../../../Sidebar/Sidebar"
import GeneratorComp from "./GeneratorComp"

const Generator =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
             <GeneratorComp/>
        </div>
        <Footer />
        </>
    )
}

export default Generator;