import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import TextField from '@mui/material/TextField';
import Heading from "../../../Common/Heading";

import Select from 'react-select';

import axios from "axios";
import LoadingSpinner from "../../../Common/LoadingSpinner";
import url from "../../../Common/URL";

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const GlobalSummaryComp = () => {

  const [loading, setLoading] = useState(false);


  const [originalData, setOriginalData] = useState([]);

  const [searchfield, setSearchfield] = useState({
    location: [], batch: [], level: [], test_type: [], startDate: "", endDate: ""
  })

  const [loc_name, setLoc_name] = useState([]);
  const [batch_name, setBatch_name] = useState([]);
  const [level_name, setLevel_name] = useState([]);


  const [data, setData] = useState([]);

  const Columns = useMemo(
    () => [
      {
        header: "Teacher's Name",
        id: 'name',
        accessorFn: (rowData) => `${rowData.fname} ${rowData.lname}`
      },
      {
        header: "Location",
        id: 'location',
        accessorFn: (rowData) => rowData.location.split(",").join(", "),
      },
      {
        header: "Batch",
        id: 'batch',
        accessorFn: (rowData) => rowData.batch.split(",").join(", "),
      },
      {
        header: "Level",
        id: 'level',
        accessorFn: (rowData) => rowData.level.split(",").join(", "),
      },
      { header: 'Total Work Hours', accessorKey: 'total_workhour', },
    ],
    [],
  );



  const load_SummaryLocLevBatch = async () => {
    setLoading(true);


    const obj = {
      uname: sessionStorage.getItem("username"), start_date: searchfield.startDate, end_date: searchfield.endDate
    };

    await axios
      .post(url + "php/timesheet/timesheet_filter.php", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = JSON.parse(response.data.data);
          // console.log(a);
          //  ====================== Below part if role  equals to Location Manager ===================
          // if(sessionStorage.getItem("role") === "Location Manager"){
          // var filterData = a.filter(stu =>{
          //     return  location_name_arr.indexOf(stu.location_name) >= 0

          // })
          // setLoading(false);
          // filterData.length > 0 ? setOriginalData(filterData):setOriginalData([]);
          // filterData.length > 0 ? setData(filterData):setData([]);
          // ====================== Below part if role not equals to Location Manager ===================
          // }else{
          a !== null ? setOriginalData(a) : setOriginalData([]);
          a !== null ? setData(a) : setData([]);  // Storing Data for Admin and SuperAdmin
          setLoading(false);
          // }
        } else {
          setLoading(false);
          setData([]);
        }
      }).catch((err) => console.log(err));


    // ======================== Fetching Locations, levels, batches for the Search Field ================================

    // ----- Location -----
    axios.post(url + "php/locations/locations.php", { uname: sessionStorage.getItem("username") })
      .then((response) => {
        let a = JSON.parse(response.data.data);
        // console.log(a);

        let locations = a.map(a => a.name);
        // if(sessionStorage.getItem("role")==="Teacher"){
        //   // console.log(location_name_arr);
        //   setLoc_name(location_name_arr);
        // }else{
        setLoc_name(locations);
        // }

      })
      .catch((err) => console.log(err));

    // ----- Batch -----
    axios.post(url + "php/batches/batches.php", { uname: sessionStorage.getItem("username") })
      .then((response) => {
        let a = JSON.parse(response.data.data);
        // console.log(a);

        let batches = a.map(a => a.batch_name);
        // if(sessionStorage.getItem("role")==="Teacher"){
        //   // console.log(batch_name_arr);
        //   setBatch_name(batch_name_arr);
        // }else{
        setBatch_name(batches);
        // }
      })
      .catch((err) => console.log(err));

    // ---- Level ------   
    axios.post(url + "php/levels/levels.php", { uname: sessionStorage.getItem("username") })
      .then((response) => {
        let a = JSON.parse(response.data.data);
        // console.log(a);

        let levels = a.map(a => a.level_name);
        // if(sessionStorage.getItem("role")==="Teacher"){
        //   // console.log(level_name_arr);
        //   setLevel_name(level_name_arr);
        // }else{
        setLevel_name(levels);
        // }
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    SearchSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchfield])

  // ========================================================================================

  const SearchSubmit = () => {

    var location = searchfield.location.map(a => a.value);
    var batch = searchfield.batch.map(a => a.value);
    var level = searchfield.level.map(a => a.value);

    let filter_1 = location.length === 0 ? originalData : originalData.filter(t_sheet => {
      let arr = t_sheet.location.split(',');
      return location.every(x => arr.includes(x))
    });

    let filter_2 = batch.length === 0 ? filter_1 : filter_1.filter(t_sheet => {
      let arr = t_sheet.batch.split(',');
      return batch.every(x => arr.includes(x))
    });

    let filter_3 = level.length === 0 ? filter_2 : filter_2.filter(t_sheet => {
      let arr = t_sheet.level.split(',');
      return level.every(x => arr.includes(x))
    });


    filter_3.length > 0 ? setData(filter_3) : setData([]);

  }

  const ClearFilter = () => {
    setSearchfield({
      location: [], batch: [], level: [], test_type: [], startDate: "", endDate: ""
    })
    setData(originalData)
  }


  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% Modal Open Close Logic %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item ">
              Timesheet</li>
            <li className="breadcrumb-item"><Link to="/timesheet">Timesheet</Link></li>
            <li className="breadcrumb-item active">
              Global Summary</li>
            {/* <li className="breadcrumb-item active" aria-current="page">Time Sheet</li> */}
          </ol>
        </nav>

        <Heading name="Global Summary" />
        <div className="container-fluid">
          <div className="row">

            <div className="col-md-3 ">
              <TextField
                label="From"
                type="date"
                value={searchfield.startDate}
                className="form-control mb-4"
                onChange={e => {
                  setSearchfield(pre => {
                    return { ...pre, startDate: e.target.value }
                  })
                }
                }
                InputLabelProps={{ shrink: true, }}
              />
            </div>

            <div className=" col-md-3 ">
              <TextField
                label="To"
                type="date"
                value={searchfield.endDate}
                className="form-control mb-4"
                onChange={e => {
                  setSearchfield(pre => {
                    return { ...pre, endDate: e.target.value }
                  })
                }
                }
                InputLabelProps={{ shrink: true, }}
              />
            </div>

            <div className=" col-md-3 d-flex align-items-center justify-content-center ">
              <Button variant="contained"
                disabled={(searchfield.startDate === "" || searchfield.endDate === "") ? true : false}
                onClick={() => {
                  if (searchfield.endDate < searchfield.startDate) {
                    toast.error("End date should be greater that Start date.")
                  } else {
                    load_SummaryLocLevBatch();
                  }
                }}>Search</Button>
            </div>

          </div>


        </div>
        {loading ? <LoadingSpinner /> :
          <div className="container-fluid mb-5">
            <hr className="mt-5"></hr>
            <h5 className="mb-4 ">Advanced Filter</h5>
            <div className="row " >
              <div className=" col-md-3">
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isClearable={true}
                  isMulti
                  closeMenuOnSelect={false}
                  options={loc_name.map(x => ({ label: x, value: x }))}
                  value={searchfield.location}
                  placeholder="Select Location"
                  onChange={e => setSearchfield({ ...searchfield, location: e })}
                />
              </div>

              <div className=" col-md-3">
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isClearable={true}
                  isMulti
                  closeMenuOnSelect={false}
                  options={level_name.map(x => ({ label: x, value: x }))}
                  value={searchfield.level}
                  placeholder="Select Level Name"
                  onChange={e => setSearchfield({ ...searchfield, level: e })}
                />
              </div>

              <div className=" col-md-3">
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isClearable={true}
                  isMulti
                  closeMenuOnSelect={false}
                  options={batch_name.map(x => ({ label: x, value: x }))}
                  value={searchfield.batch}
                  placeholder="Select Batch Name"
                  onChange={e => setSearchfield({ ...searchfield, batch: e })}
                />
              </div>
            </div>

            <hr className="w-25 ms-auto" />

            <div className="d-flex my-3">
              <Button variant="contained" className="btn btn-outline-dark shadow-sm    ms-auto"
                style={{ border: 0, borderLeft: "2px solid", borderRight: "2px solid" }}
                onClick={ClearFilter}>Clear Filter</Button>

            </div>

            <hr />
            <div className="row">
              <div className="col-12 mx-auto">
                <MaterialReactTable
                  columns={Columns}
                  data={data}
                  enableRowNumbers
                  initialState={{ density: 'compact' }}
                  enableDensityToggle={false}

                  getRowId={(originalRow) => originalRow.id}
                />
              </div>
            </div>
          </div>}
      </div>
    </>
  );
};
export default GlobalSummaryComp;
