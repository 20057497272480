import React, { useState, useEffect } from "react"
import Input from "../../Student/Component/Input"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom";
import Heading from "../../../../Common/Heading"
import axios from "axios"
import url from "../../../../Common/URL"
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const EditLevelComp = () => {
    const navigate = useNavigate();

    let message = "";
    const { id } = useParams();

    const [disable, setDisable] = useState(true);
    const [form_data, setForm_data] = useState(true); //Used to compare if any update is done in form data.

    const [field, setField] = useState({
        uname: sessionStorage.getItem("username"),
        level_id: "",
        level_name: "",
        level_desc: "",
        level_status: ""
    })

    useEffect(() => {
        allLevels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const allLevels = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        }
        axios.post(url + "php/levels/select_level.php", obj)
            .then((response) => {
                let resData = JSON.parse(response.data.data);
                // console.log(resData);
                setForm_data(resData);       //THis will help in checking any update done in form, so we can enable or disable the save form button
                setField({
                    level_id: resData[0].level_id,
                    level_name: resData[0].level_name,
                    level_desc: resData[0].level_desc,
                    level_status: resData[0].level_status
                })
            })
            .catch((err) => console.log(err));
        setDisable(true);
    }

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;


        if (form_data[0][name] !== value) {
            setDisable(false);
        } else {
            setDisable(true);
        }

        setField((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    const onSubmit = (e) => {

        e.preventDefault();
        setDisable(true);
        /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;

        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            uname: sessionStorage.getItem("username"),
            level_name: field.level_name, updated_by: sessionStorage.getItem("username"), updated_date: newdate,
            level_desc: field.level_desc, level_status: field.level_status, id: id
        }

        axios.post(url + "php/levels/edit_level.php", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    allLevels();
                    toast.success("Updated successfully!")
                } else {
                    message = res.data.message;
                    alert(message)
                }
            })
            .catch((err) => console.log(err));

    }
    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/level">Levels</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Edit Level</li>
                    </ol>
                </nav>

                <Heading name={`Edit Level -  ${field.level_name}`} />

                <div className="container-fluid">

                    <form onSubmit={onSubmit}>

                        <div className="row mb-4">
                            <div className="col-12 d-flex justify-content-between  align-items-end ">
                                <h5>Level Information</h5>
                                <div>
                                    <Button variant="contained" onClick={() => navigate("/view-level/" + field.level_id)} >Back</Button>
                                    <Button variant="contained" type="submit" disabled={disable} className="ms-3">Save</Button>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-11 col-md-8 mx-auto mx-md-0">

                                {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Level ID</label>
                        <div className="col-md-7 col-sm-7"> */}
                                {/* {field.level_id} */}
                                {/* <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={field.level_id} />
                        </div>
                    </div> */}

                                <Input field="Level Name"
                                    name="level_name"
                                    type="text" placeholder="Add text" className="form-control" value={field.level_name} onChange={onChange} required={true} />

                                <div className="mb-3 row">
                                    <label className=" col-sm-5 col-form-label">Description</label>
                                    <div className="col-sm-7 col-md-7">
                                        <textarea type="text" name="level_desc" className="form-control" rows="2"
                                            placeholder="Add remarks here..." value={field.level_desc} onChange={onChange}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Status</label>
                                    <div className="col-sm-7 col-md-7 align-items-center d-flex ">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="level_status"
                                                id="inlineRadio1"
                                                value="Active"
                                                checked={field.level_status === "Active"}
                                                onChange={onChange}
                                            />


                                            <label className="form-check-label" htmlFor="inlineRadio1">Active</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="level_status"
                                                id="inlineRadio2"
                                                value="Inactive"
                                                checked={field.level_status === "Inactive"}
                                                onChange={onChange}
                                            />

                                            <label className="form-check-label" htmlFor="inlineRadio2">Inactive</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

export default EditLevelComp