import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Heading from "../../../../Common/Heading"

const LocationManagerProfileComp = () => {
    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">My Profile</li>
                        <li className="breadcrumb-item active" aria-current="page">Location Manager's Profile</li>
                    </ol>
                </nav>

                <Heading name="Location Manager's Profile" />
            </div>
        </>
    )
}
export default LocationManagerProfileComp