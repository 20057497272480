import React, {useState} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import url from "./URL";
import { Button } from "@mui/material";


const Abacus = () =>{

    const [abacus_rails , setAbacus_rails] = useState(17);

    return(
        <Accordion className="mb-3 position-sticky " style={{zIndex:1050, top:55, border:"3px solid #e6e6e6"}} >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    <h5 className="p-0 m-0" >Virtual Abacus</h5>
                </AccordionSummary>

                <div className="row "> 
                    <div className="col-12 position-relative" >

                        <div className=" d-flex" style={{position:"absolute" , top:0, left :"80px", zIndex:1}}>
                        <Button variant="contained"  className=" mx-3 btn-outline-info border-0 shadow-sm" onClick={(e)=>{
                                    e.preventDefault();
                                    setAbacus_rails(17)
                                }} >Rails 17</Button>
                        <Button variant="contained"  className=" btn-outline-info border-0 shadow-sm" onClick={(e)=>{
                                    e.preventDefault();
                                    setAbacus_rails(22)
                                }} >Rails 22</Button>
                        </div>
                    {abacus_rails === 17 ?
                        <div className="embed-responsive embed-responsive-16by9" style={{width: "100%", height: "335px", overflow: "hidden"}}>
                        <iframe src={`${url}abacus/`} style={{width: "100%", height: "600px", scroll: "none"}} title="Iframe Example"></iframe>
                        </div>
                        :
                        <div className="embed-responsive embed-responsive-16by9" style={{width: "100%", height: "335px", overflow: "hidden"}}>
                        <iframe src={`${url}abacus2/`} style={{width: "100%", height: "600px", scroll: "none"}}  title="Iframe Example"></iframe>
                        </div>
                    }
                    </div>
                </div> 
  
        </Accordion>
    )
}

export default Abacus;
