import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Heading from "../../../../Common/Heading";

import LoadingSpinner from "../../../../Common/LoadingSpinner";

import SaveAlt from '@mui/icons-material/SaveAlt';

import axios from "axios";
import url from "../../../../Common/URL"

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";

const HistoryTableComp = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const Columns = useMemo(
    () => [
      { header: 'First Name', accessorKey: 'fname', },
      { header: 'Last Name', accessorKey: 'lname', },
      { header: 'Location', accessorKey: 'location_name', },
      { header: 'Batch', accessorKey: 'batch_name', },
      { header: 'Level', accessorKey: 'level_name', },
      { header: 'Updated By', accessorKey: 'updated_by', },
      { header: 'Updated Date', accessorKey: 'updated_date' },
    ],
    [],
  );

  const loadHistory = () => {
    const obj = { uname: sessionStorage.getItem("username"), id: id }
    axios.post(url + "php/students/student_history.php", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = JSON.parse(response.data.data);
          // console.log(a);
          a === null ? setData([]) : setData(a.reverse());
          setLoading(false)
        } else {
          setData([]);
          setLoading(false)
        }
      })
      .catch((err) => console.log(err));
  }

  // ------------------------Fetching Student table's data-------------------------------------
  useEffect(loadHistory, []);

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item" ><Link to="/student">Students list</Link></li>
            <li className="breadcrumb-item"><Link to={"/view-student/" + id}>View Student</Link></li>
            <li className="breadcrumb-item active" aria-current="page">History Log</li>
          </ol>
        </nav>

        <Heading name="History log" />

        <div className="container-fluid mb-5">
          {/* <div className="row my-4">
                <div className="col-12 mx-auto d-flex justify-content-end ">
                    <Link to="/add-student" >Add Student <Add /></Link>
                </div>
            </div> */}
          <div className="row">

            <div className="col-12 mx-auto">

              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                
                getRowId={(originalRow) => originalRow.stud_history_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/stu-history-data/" + row.id),
                  sx: { cursor: 'pointer' },
                })}
              />
            </div>

          </div>
        </div>
      </div>

    </>
  )

}
export default HistoryTableComp




