import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import Input from "../../Student/Component/Input"
import { Link } from "react-router-dom"
import Heading from "../../../../Common/Heading"
import { toast } from "react-toastify"
import axios from "axios";
import url from "../../../../Common/URL"
import { Button } from "@mui/material";
import { useSelector } from "react-redux"

const AddLevelComp = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [field, setField] = useState({
        uname: sessionStorage.getItem("username"),
        level_name: "",
        level_desc: "",
        level_status: ""

    })
    const onChange = (e) => {

        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        setField((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;

        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            level_name: field.level_name, created_by: field.uname, created_date: newdate,
            level_desc: field.level_desc, level_status: field.level_status, uname: field.uname
        }
        axios.post(url + "php/levels/add_level.php", obj)
            .then((res) => {
                // console.log(res) 
                if (res.data.success) {
                    toast.success("Level created successfully!")
                    navigate("/level");
                } else {
                    alert("Level not created, please try again!")
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })

    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/level">Levels</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Add Level</li>
                    </ol>
                </nav>
                <Heading name="Add Level" />

                <div className="container-fluid">

                    <form onSubmit={onSubmit}>

                        <div className="row mb-4">
                            <div className="col-12 d-flex justify-content-between ">
                                <h5>Level Information</h5>
                                <div>
                                    <Button variant="contained" onClick={() => navigate("/level")} >Cancel</Button>
                                    <Button variant="contained" type="submit" disabled={loading} className="ms-3">Save</Button>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-11 col-md-8 mx-auto mx-md-0">

                                {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Level ID</label>
                        <div className="col-md-6 col-sm-7">
                        <input type="text" readonly className="form-control-plaintext" value="<Auto generated>"  />
                        </div>
                    </div> */}

                                <Input field="Level Name"
                                    name="level_name"
                                    type="text" placeholder="Add text" className="form-control" value={field.level_name} onChange={onChange} required={true} />

                                <div className="mb-3 row">
                                    <label className=" col-sm-5 col-form-label">Description</label>
                                    <div className="col-sm-7 col-md-7">
                                        <textarea type="text" name="level_desc" className="form-control" rows="2"
                                            placeholder="Add remarks here..." value={field.level_desc} onChange={onChange}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Status</label>
                                    <div className="col-sm-7 col-md-7 align-items-center d-flex ">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="level_status"
                                                id="inlineRadio1"
                                                value="Active"
                                                checked={field.level_status === "Active"}
                                                onChange={onChange}

                                            />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Active</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="level_status"
                                                id="inlineRadio2"
                                                value="Inactive"
                                                checked={field.level_status === "Inactive"}
                                                onChange={onChange}
                                            />

                                            <label className="form-check-label" htmlFor="inlineRadio2">Inactive</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

export default AddLevelComp