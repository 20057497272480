import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Input from "../../../Setup/Student/Component/Input"
import Heading from "../../../../Common/Heading"
import axios from "axios";
import url from "../../../../Common/URL"
import { Button } from "@mui/material";
import { useSelector } from "react-redux"

const AddAccountFeeComp = () => {

    let navigate = useNavigate();
    const [stu, setStu] = useState([]);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        stud_id: "",
        fee: "", duration: "", fname: "", lname: "", stu_uname: "",
        valid_from: "", valid_to: "",
    })


    useEffect(() => {
        loadStudents();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Students--------------------
    const loadStudents = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "php/students/students.php", obj)
            .then((response) => {
                let a = JSON.parse(response.data.data);
                //   console.log(a);
                setStu(a);
            })
            .catch((err) => console.log(err));
    }

    //---------------One onChange function is used for setting the state of various types of input fields ---------------     

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }
    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        // ------------Storing form data in "obj" object fetched from various input fields-------------
        const obj = {
            uname: sessionStorage.getItem("username"), stud_id: state.stud_id,
            fee: state.fee, duration: state.duration, fname: state.fname,
            lname: state.lname, user_name: state.stu_uname,
            valid_from: state.valid_from, valid_to: state.valid_to,
            creator_fname: sessionStorage.getItem("userFname"), creator_lname: sessionStorage.getItem("userLname"),
            created_by: sessionStorage.getItem("username"),
        }
        // ------------Sending form data stored in "obj" object to the add_student.php file uusing AXIOS-------------

        axios.post(url + "php/student_fee/add_fee.php", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("Fee added successfully.");
                    navigate("/act-fee");
                } else {
                    alert("Unable to create student fee")
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })

    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'} >
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Accounts</li>
                        <li className="breadcrumb-item "><Link to="/act-fee">Accounts Fees</Link></li>
                        <li className="breadcrumb-item active">Add Fee</li>
                    </ol>
                </nav>
                <Heading name="Add Fee" />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                <form onSubmit={onSubmit} id="myform">
                    <div className="container-fluid mt-3">
                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-end mx-auto">
                                <Button variant="contained" disabled={loading} type="submit" >Save</Button>
                            </div>
                        </div>

                        <div className="row">

                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto mx-md-0">

                                <h5 className="mb-4">Fee Information</h5>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Student</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="stud_id" value={`${state.stud_id}#${state.fname}#${state.lname}#${state.stu_uname}`}
                                            onChange={(e) => {
                                                let val = e.target.value;
                                                let id = val.split("#")[0];
                                                let fn = val.split("#")[1];
                                                let ln = val.split("#")[2];
                                                let un = val.split("#")[3];
                                                setState({ ...state, stud_id: id, fname: fn, lname: ln, stu_uname: un });
                                            }}
                                            className="form-select">
                                            <option value="" >Choose</option>
                                            {stu.map((data, i) => (<option key={i} value={`${data.stud_id}#${data.fname}#${data.lname}#${data.user_name}`}>{`${data.fname} ${data.lname}`}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <Input name="fee" onChange={onChange} field="Fee" type="text" className="form-control" value={state.fee} required={true} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Duration</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="duration" value={state.duration} onChange={onChange} className="form-select">
                                            <option value="" >Choose</option>
                                            <option value="Monthly" >Monthly</option><option value="Yearly" >Yearly</option><option value="Lump Sum" >Lump Sum</option>
                                        </select>
                                    </div>
                                </div>
                                {/* <Input name="duration" onChange={onChange} field="Duuration" placeholder="In Months" type="number" className="form-control" value={state.duration} required={true} /> */}
                                <Input name="valid_from" onChange={onChange} field="Valid From" type="date" className="form-control" value={state.valid_from} required={true} />
                                <Input name="valid_to" onChange={onChange} field="Valid To" type="date" className="form-control" value={state.valid_to} required={true} />

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default AddAccountFeeComp