import React, { useEffect, useState, useMemo } from "react"
import { Link } from "react-router-dom"

import Heading from "../../../../Common/Heading";
import LoadingSpinner from "../../../../Common/LoadingSpinner";

import Add from '@mui/icons-material/Add';
import SaveAlt from '@mui/icons-material/SaveAlt';

import axios from "axios";
import url from "../../../../Common/URL"

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";

const StudentProfileComp = () => {
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [state] = useState({
    uname: sessionStorage.getItem("username"),
  })

  const Columns = useMemo(
    () => [
      { header: 'ID', accessorKey: 'stud_id' },
      { header: 'First Name', accessorKey: 'fname' },
      { header: 'Last Name', accessorKey: 'lname' },
      { header: 'Email', accessorKey: 'email' },
      { header: 'Location', accessorKey: 'location_name' },
      { header: 'Batch', accessorKey: 'batch_name' },
      { header: 'Level', accessorKey: 'level_name' },
    ],
    [],
  );



  // ------------------------Fetching Student table's data-------------------------------------
  useEffect(() => {
    loadStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStudents = () => {
    if (sessionStorage.getItem("role") !== "Teacher") {

      const obj = { uname: state.uname }
      axios.post(url + "php/students/students.php", obj)
        .then((response) => {
          // console.log(response)
          if (response.data.success) {
            let a = JSON.parse(response.data.data);
            // console.log(a);
            a === null ? setData([]) : setData(a);
            setLoading(false)
          } else {
            setData([]);
            setLoading(false)
          }
        })
        .catch((err) => console.log(err));

    } else {
      const obj = { uname: state.uname, location: sessionStorage.getItem("location") }
      axios.post(url + "php/students/students_location.php", obj)
        .then((response) => {
          // console.log(response)
          if (response.data.success) {
            let a = JSON.parse(response.data.data);
            // console.log(a);
            a === null ? setData([]) : setData(a);
            setLoading(false)
          } else {
            setData([]);
            setLoading(false)
          }
        })
        .catch((err) => console.log(err));
    }
  }
  // ------------------------Deleting Student table's data-------------------------------------

  // const onDelete=(id , email)=> {
  //     //alert(id);
  //   const delobj = {
  //     id: id,
  //     uname: state.uname, stud_email:email,
  //     tokenId: 123,
  //   };
  //     axios.post(url+"/php/students/delete_student.php", delobj)
  //         .then((res) => console.log(res))
  //         .then((res) => loadStudents())
  //         .catch((err) => console.log(err));
  // }
  // ------------------------Custom Theme in table------------------------------------

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">My Profile</li>
            <li className="breadcrumb-item active" aria-current="page">Student's Profile</li>
          </ol>
        </nav>

        <Heading name="Student's Profile" />
        {loading ? <LoadingSpinner /> :
          <div className="container my-3">
            <div className="row mb-5">
              <div className="col-12 mx-auto">
                <nav>
                  <div className="nav mb-4" id="nav-tab" role="tablist">
                    <a className="nav_tab nav-link active" id="nav-home-tab" data-bs-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Student Information</a>
                    <a className="nav_tab nav-link" id="nav-profile-tab" data-bs-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Student History</a>
                  </div>
                </nav>


                {/* ----------------------Personal- Information------------------------------- */}
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                  {/* <h5 className="mt-4 mb-4">Student Information</h5> */}

                  <MaterialReactTable
                    columns={Columns}
                    data={data}
                    enableRowNumbers
                    initialState={{ density: 'compact' }}
                    enableDensityToggle={false}
                    
                  />
                </div>
                {/* ----------------------Personal-Information-End------------------------------- */}

                {/* ------------------------Notes-tab-Start------------------------------- */}
                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <div className="container-fluid">
                    {/* <h5 className="mt-4 mb-4">Studnet History</h5> */}

                  </div>

                </div>
                {/* --------------------------Notes-Tab-End-------------------------- */}

              </div>
            </div>

          </div>}
      </div>

    </>
  )

}
export default StudentProfileComp