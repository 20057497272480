import React, { useState, useEffect } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Heading from "../../../Common/Heading"
import { ReadonlyInput } from "../../../Tabs/Setup/Student/Component/Input"
import axios from "axios"
import url from "../../../Common/URL"
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";


import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from "react-redux"

const ViewMessageComp = () => {

  const { id } = useParams();

  // ################################################Below Data for 1st Tab ########################################################

  const [field, setField] = useState({
    uname: sessionStorage.getItem("username"), from_email: "", from_role: "",
    mail_id: "", message: "", reciever_name: "", reply_id: "", sender_name: "",
    sent_by: "", sent_date: "", stud_id: "",
    subject: "", to_email: "", to_role: "", reply_message: ""
  })

  const [replyProgress, setReplyProgress] = useState(false)
  const [openReplyDialog, setOpenReplyDialog] = useState(false);

  const [data, setData] = useState([])

  const onReplySubmit = (e) => {
    e.preventDefault();
    setReplyProgress(true)
    /*---------------Creating object to send data through Axios------------------------- */

    const obj = sessionStorage.getItem("role") === "Student" ? {
      uname: sessionStorage.getItem("username"), to_email: field.to_email, from_email: field.from_email,
      subject: field.subject, message: field.reply_message,
      sent_by: field.sent_by, stud_id: field.stud_id, from_role: field.from_role, to_role: field.to_role,
      reply_id: id, sender_name: field.sender_name, reciever_name: field.reciever_name
    } :
      {
        uname: sessionStorage.getItem("username"), to_email: field.from_email, from_email: field.to_email,
        subject: field.subject, message: field.reply_message,
        sent_by: field.to_email, stud_id: field.stud_id, from_role: field.to_role, to_role: field.from_role,
        reply_id: id, sender_name: field.reciever_name, reciever_name: field.sender_name
      }

    // console.log(obj);
    axios.post(url + "/php/mail/message.php", obj)
      .then((res) => {
        // console.log(res)
        if (res.data.success) {

          let loadMessages = new Promise((resolve, reject) => {
            MessageDetail(resolve);
          })

          loadMessages.then(() => {
            toast.success("Message sent successfully!")
          });

          setField({
            ...field,
            reply_message: ""
          })
          setOpenReplyDialog(false);
          setReplyProgress(false);
        } else {
          alert("Mail not sent!")
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    MessageDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ------------------------------
  const MessageDetail = (x) => {
    const obj = {
      uname: field.uname,
      id: id
    }
    axios.post(url + "php/mail/select_update_mail.php", obj)
      .then((response) => {
        let resData = JSON.parse(response.data.data);
        // console.log(resData);

        setData(resData);
        setField({
          from_email: resData[0].from_email, from_role: resData[0].from_role,
          mail_id: resData[0].mail_id, message: resData[0].message,
          reciever_name: resData[0].reciever_name, reply_id: resData[0].reply_id,
          sender_name: resData[0].sender_name,
          sent_by: resData[0].sent_by, sent_date: resData[0].sent_date, stud_id: resData[0].stud_id,
          subject: resData[0].subject, to_email: resData[0].to_email, to_role: resData[0].to_role
        })
        if (x !== undefined) x();
      })
      .catch((err) => console.log(err));
  }

  // --------------------Deleting Student table's data------------=

  // const onDelete=()=> {
  //     confirm({ title : "Delete Confirmation" ,description: 'This will delete the student permanently.' })
  //         .then(() => { 
  //             const delobj = {
  //                 id: id,
  //                 uname:sessionStorage.getItem("username"), stud_email:field.email,
  //                 tokenId: 123,
  //             };
  //             axios.post(url+"php/students/delete_student.php", delobj)
  //                 .then((res) => {

  //                     toast.error("Student deleted ");
  //                     navigate("/student")
  //                 }).catch((err) => console.log(err));
  //          })

  // }


  return (
    <>

      <Dialog fullWidth={true} maxWidth="md" open={openReplyDialog}>
        <DialogTitle id="max-width-dialog-title">
          Reply To This Conversation<hr className="p-0 mt-2" />{" "}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={onReplySubmit} id="myform">
            <div className="row px-3 ">
              <div className="col-11 col-md-12 mx-auto ">

                <div className="mb-3 row ">
                  <label className=" col-sm-4 col-form-label">Enter Message</label>
                  <div className="col-sm-7 col-md-8">
                    <textarea
                      name="reply_message"
                      onChange={(e) => {
                        setField({ ...field, reply_message: e.target.value })
                      }}
                      value={field.reply_message}
                      type="text"
                      className="form-control"
                      rows="5"
                      placeholder="Add Message here..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className="me-4"
            onClick={() => {
              setOpenReplyDialog(false);
              setField({ ...field, reply_message: "" });
            }}
            color="primary">
            Close
          </Button>
          <Button type="submit" color="primary" > {!replyProgress ? "Save" : <CircularProgress size={20} />}</Button>

        </DialogActions>
      </Dialog>


      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        {/*-----------------------Bread crumb------------------------- */}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/message">Messages</Link></li>
            <li className="breadcrumb-item active">View Message</li>
          </ol>
        </nav>
        <Heading name={"Message Details"} />
        {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}

        <div className="container-fluid  mt-3">

          {(sessionStorage.getItem("role") === "Student" || sessionStorage.getItem("role") === "Teacher") && <div className="row">
            <div className="col-12 text-end  ">

              <Button variant="contained"  onClick={() => setOpenReplyDialog(true)} className=" ms-auto ">Reply</Button>
            </div>
          </div>}

          <div className="row mb-5">
            {/*-----------------------Column-1 for Inputs------------------------- */}
            <div className="col-12 mx-auto ">


              <hr />
              <h6 className="mb-4 d-flex align-items-center" ><DoubleArrowIcon style={{ marginRight: ".5em" }} /> Communication is between {field.from_role} & {field.to_role}</h6>
              {data.map((x, i) => <div key={i} className="shadow p-2 mb-2 rounded bg-light" >

                <div className="ms-2" >
                  <p>From <strong>{x.sender_name}</strong> to <strong >{x.reciever_name}</strong> on {x.sent_date}</p>

                </div>
                <hr />
                <h6 className="ms-2">{`Subject : ${x.subject}`}</h6>
                <hr />
                <p className="mx-2 my-4">{`${x.message}`}</p>
              </div>)
              }
            </div>


          </div>

        </div>


      </div>

    </>
  )
}

export default ViewMessageComp

