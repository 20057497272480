import React from "react"
import Navbar from "../../../../Common/Navbar"
import Footer from "../../../../Common/Footer"
import Sidebar from "../../../../Sidebar/Sidebar"
import GeneratedTestExamPreviewComp from "./Component/GeneratedTestExamPreviewComp"

const GeneratedTestExamPreview = () => {
    return (
        <>
            <Navbar />
            <div className="d-flex mainContentArea">
                <Sidebar />
                <GeneratedTestExamPreviewComp />
            </div>
            <Footer />
        </>
    )
}

export default GeneratedTestExamPreview;