import React from "react"
import Navbar from "../../../Common/Navbar"
import Footer from "../../../Common/Footer"
// import Sidebar from "../../../Sidebar/Sidebar"
import NewTestSheetComp from "./Component/NewTestSheetComp"

const NewTestSheet =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            {/* <Sidebar /> */}
             <NewTestSheetComp/>
        </div>
        <Footer />
        </>
    )
}

export default NewTestSheet