import React from "react"
import Navbar from "../../../Common/Navbar"
import Footer from "../../../Common/Footer"
import Sidebar from "../../../Sidebar/Sidebar"
import TeacherProfileComp from "./Component/TeacherProfileComp"

const TeacherProfile =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <TeacherProfileComp />
        </div>
        <Footer />
        </>
    )
}

export default TeacherProfile