import React from "react"
import Navbar from "../../Common/Navbar"
import Footer from "../../Common/Footer"
import Sidebar from "../../Sidebar/Sidebar"
import MessageComp from "./Component/MessageComp"


const Message =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea" >
            <Sidebar />
            <MessageComp />
        </div>
        <Footer />
        </>
    )
}

export default Message