import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../Common/Navbar";
import url from "../Common/URL";
import "./login.css";


import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

import { LinearProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { LOGGED_IN } from '../store/profileNameSlice';


const Login = () => {

  const navigate = useNavigate();
  // const LoggedIn = useSelector(state=>state.LogInReducer);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openPhotoConsent_form, setOpenPhotoConsent_form] = useState(false);
  const [showpass, setShowpass] = useState(false);
  const [spinner, setSpinner] = useState({
    login: false, otp: false
  });

  const [otpform, setOtpform] = useState(false);
  const [state, setState] = useState({
    uname: "", otp: "",
    password: "", photo_consent: "",
  })

  const [id, setId] = useState("");

  const Disclaimer_disagreed = () => {
    setOpen(false);
    if (sessionStorage.removeItem("username")) {
      navigate("/");
    }
  }

  const Disclaimer_agreed = () => {

    const obj = {
      uname: sessionStorage.getItem("username"), role: sessionStorage.getItem("role"),
      id: id, disclaimer_signed: "true", photo_consent: state.photo_consent,
    }
    axios.post(url + "php/students/edit_disclaimer.php", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {

          navigate("/bulletin");
          toast.success("Welcome to GURUSCOOL")
        } else {
          alert("An unexpected error occurred, this is most likely due to a server misconfiguration. Please try to login after some time.")
          navigate("/");
          sessionStorage.removeItem("username");
          window.location.reload(false);
        }
      }).catch((err) => {
        // console.log(err)
      })
  }


  const onChange = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.type === 'checkbox' ? input.checked : input.value;
    setState((pre) => {
      return {
        ...pre, [name]: value,
      }
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setSpinner({ ...spinner, login: true });
    const obj = { uname: state.uname, password: state.password, tokenId: 123 };

    axios.post(url + "php/login.php", obj)
      .then(
        (res) => {
          console.log(res);
          if (res.data.message === "Login Successfully..." && res.data.status === "1") {

            sessionStorage.setItem("role", res.data.role);
            sessionStorage.setItem("username", res.data.username);

            // This will Globally save the First name & Last name of logged In User
            // Which is used in different part of our Application.

            axios.post(url + "php/select_profile.php", { uname: res.data.username })
              .then((response) => {
                let resData = JSON.parse(response.data.data);
                dispatch(LOGGED_IN(`${resData[0].first_name} ${resData[0].last_name}`));
                sessionStorage.setItem("userFname", resData[0].first_name);
                sessionStorage.setItem("userLname", resData[0].last_name);
                sessionStorage.setItem("email", resData[0].email);
              })
              .catch((err) => console.log(err, `First Name & Last Name cant be fetched.`));

            // Below Condition will check for Users Role in application & will do the redirection accordingly. 
            if (res.data.role === "Superadmin" || res.data.role === "Admin" || res.data.role === "Location Manager") {
              setSpinner({ ...spinner, login: false });
              setOtpform(true);
            }
            else if (res.data.role === "Systemadmin") {
              setSpinner({ ...spinner, otp: false });

              navigate("/bulletin");
            }
            else if (res.data.role === "Student" || res.data.role === "Teacher") {
              Disclaimer_signed();
            }
            else {
              navigate("/bulletin");
            }
          }
          else {
            setSpinner({ ...spinner, login: false });
            toast.error("Invalid username or password!")
          }
        }
      )
      .catch((err) => {
        console.log(err);
        alert("Network Error.")
      });

  }

  const OTPSubmit = (e) => {
    e.preventDefault();
    setSpinner({ ...spinner, otp: true });
    const obj = { uname: sessionStorage.getItem("username"), otp_key: state.otp };

    axios.post(url + "php/otp/match_otp.php", obj)
      .then(
        (res) => {
          // console.log(res)
          if (res.data.message === "OTP Matched") {

            if (sessionStorage.getItem("role") === "Systemadmin" || sessionStorage.getItem("role") === "Superadmin" || sessionStorage.getItem("role") === "Admin") {
              setSpinner({ ...spinner, otp: false });

              navigate("/bulletin");
            }
            else {   // For Location Manager
              Disclaimer_signed();
            }
          }
          else {
            if (res.data.message === "OTP date expired!") {
              setState({ ...state, otp: "" });
              setSpinner({ ...spinner, otp: false });
              toast.error("This OTP has been expired.")
            } else {
              setState({ ...state, otp: "" });
              setSpinner({ ...spinner, otp: false });
              toast.error("OTP entered was Incorrect.")
            }

          }
        }
      )
      .catch((err) => {
        console.log(err);
        setSpinner({ ...spinner, otp: false });
        alert("Network Error.")
      });

  }

  // ------ 
  const Disclaimer_signed = () => {
    const obj = {
      uname: sessionStorage.getItem("username"),
      email: sessionStorage.getItem("username"),
      role: sessionStorage.getItem("role"),
    }
    axios.post(url + "php/students/match_student.php", obj)
      .then((response) => {
        // console.log(response);
        let resData = JSON.parse(response.data.data);
        // console.log(resData);
        var role = sessionStorage.getItem("role");
        // Storing Data for further use in LMS -------------- 
        switch (role) {
          case "Student":
            setId(resData[0].stud_id);
            sessionStorage.setItem("level_name", resData[0].level_name);
            sessionStorage.setItem("location", resData[0].location_name);
            sessionStorage.setItem("batch", resData[0].batch_name);
            sessionStorage.setItem("id", resData[0].stud_id);
            break;
          case "Teacher":
            setId(resData[0].teacher_id);
            sessionStorage.setItem("id", resData[0].teacher_id);
            break;
          case "Location Manager":
            setId(resData[0].manager_id);
            sessionStorage.setItem("location", resData[0].location_name);
            sessionStorage.setItem("id", resData[0].manager_id);
            break;
          default:
            break;
        }
        // Normal flow ------------------------
        if (resData[0].disclaimer_signed.toString() === "") {
          setSpinner({ login: false, otp: false });
          setOpen(true);
        } else {
          setSpinner({ login: false, otp: false });

          navigate("/bulletin");
        }

      }).catch((err) => console.log(err));
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setOpen(false);
          }
        }}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Disclaimer: End-User License Agreement</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p><span className="fw-bold">Please read this End-User License Agreement ("Agreement") carefully before clicking the "I Agree" button</span>, downloading or using Guruscool Abacus Learning Management System
              By clicking the "I Agree" button, downloading or using the Application, you are agreeing to be bound by the terms and conditions of this Agreement.
              If you do not agree to the terms of this Agreement, do not click on the "I Agree" button and do not download or use the Application.</p>

            <div className="fw-bold">License</div>
            <p>Guruscool grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the
              Application solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement.</p>

            <div className="fw-bold"> Restrictions</div>
            <p>You agree not to, and you will not permit others to:<br />
              a) license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the
              Application or make the Application available to any third party.</p>

            <div className="fw-bold">Modifications to Application</div>
            <p>Guruscool reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or any service to
              which it connects, with or without notice and without liability to you.</p>

            <div className="fw-bold">Term and Termination</div>
            <p>This Agreement shall remain in effect until terminated by you or Guruscool
              Guruscool  may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or
              without prior notice.This Agreement will terminate immediately, without prior notice from Guruscool, in the event that you
              fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all
              copies thereof from your mobile device or from your desktop.Upon termination of this Agreement, you shall cease all use of the
              Application and delete all copies of the Application from your mobile device or from your desktop.</p>

            <div className="fw-bold">Severability</div>
            <p>If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to
              accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions
              will continue in full force and effect.</p>

            <div className="fw-bold">Amendments to this Agreement</div>
            <p>Guruscool reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material
              we will provide at least 30  days' notice prior to any new terms taking effect. What constitutes a material change will be
              determined at our sole discretion.</p>

            <div className="fw-bold">Contact Information</div>
            <p>If you have any questions about this Agreement, please contact us <a href="https://info@guruscool.com" target="_blank" rel="noopener noreferrer">info@guruscool.com</a>.</p>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={Disclaimer_disagreed} variant="outlined" color="error" >Disagree</Button>
          <Button onClick={() => {
            setOpen(false);
            sessionStorage.getItem("role") === "Student" && setOpenPhotoConsent_form(true);
            sessionStorage.getItem("role") !== "Student" && Disclaimer_agreed();
          }} variant="outlined" >I Agree</Button>
        </DialogActions>
      </Dialog>

      {/* *************************************************************************************************************************** */}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setOpen(false);
          }
        }}
        open={openPhotoConsent_form}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">MINOR (CHILD) PHOTO RELEASE FORM</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <p>I the parent or legal guardian of my child grant Guruscool abacus my permission to use
              the photographs and video for any legal use, including but not limited to: publicity,
              copyright purposes, illustration, advertising, and web content.</p>

            <p>Furthermore, I understand that no royalty, fee or other compensation shall become
              payable to me by reason of such use.</p>

            <div className="mb-3">
              <div className="form-check d-flex align-items-center">
                <input className="form-check-input" type="checkbox" id="gridCheck" name="photo_consent" checked={state.photo_consent} onChange={onChange} />
                <label className="form-check-label" htmlFor="gridCheck">Yes I Agree.</label>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={Disclaimer_agreed} variant="outlined" color="error" >Disagree</Button>
          <Button onClick={Disclaimer_agreed} variant="outlined"  >Submit</Button>
        </DialogActions>
      </Dialog>
      {/* *************************************************************************************************************************** */}

      <Navbar />

      {/* {sessionStorage.getItem("username") ? ( <h2 className="text-center mt-1">Guruscool Abacus Math Training</h2>) :  */}

      {!otpform ?
        <div className="card col-11 col-md-4  py-3 px-4 px-md-5 shadow mx-auto login border-0">
          <h2 className="text-center mb-4 mt-2 head">Login Here</h2>

          <form onSubmit={onSubmit}>

            <div className="mb-3 text-left">
              <Input
                autoFocus
                required
                disableUnderline={true}
                type="text"
                className="form-control shadow-sm border-0"
                id="uname"
                value={state.uname}
                placeholder="Username"
                onChange={onChange}
                autoComplete="off"
                name="uname"
              />
            </div>

            <div className="mb-3 text-left">
              <Input
                required
                disableUnderline={true}
                type={showpass ? "text" : "password"}
                className="form-control shadow-sm border-0"
                id="password"
                value={state.password}
                placeholder="Password"
                onChange={onChange}
                autoComplete="off"
                name="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowpass(() => !showpass)} onMouseDown={(e) => e.preventDefault()}>
                      {showpass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <small className="text-muted medium d-flex align-content-end justify-content-end p-2">
                Forget &nbsp;<span><NavLink to="/forget-pass">{" "}Password ?</NavLink></span>
              </small>
            </div>

            <Button disabled={spinner.login} variant="contained" type="submit" className="mb-4">Login</Button>
            {spinner.login && <LinearProgress />}


          </form>

          {/* <div className="d-flex justify-content-end align-items-center">
                <small className=" text-muted small ">Haven't Registered Yet ?</small>
                <NavLink  className="m-2 py-1 btn-outline-primary" to="/signup">Signup</NavLink>
              </div> */}

        </div>

        :

        <div className="card col-11 col-md-4  py-3 px-4 px-md-5 shadow mx-auto login border-0">
          <h2 className="text-center mt-2 head">Two Factor Authentication</h2><hr />
          <h6 className="text-center mb-5 head">We have sent a single use code on your registered Email, please enter it below to login.</h6>

          <form onSubmit={OTPSubmit}>

            <div className="mb-3 text-left">
              <Input
                required
                disableUnderline={true}
                className="form-control shadow-sm border-0"
                value={state.otp}
                placeholder="Enter OTP"
                onChange={onChange}
                autoComplete="off"
                name="otp"

              />
            </div>
            {spinner.otp ?
              <div className="spinner-grow text-primary mb-4 mt-1 ms-2" role="status" />
              :
              <Button variant="contained" type="submit" className=" mb-4 shadow  ">Submit</Button>
            }
          </form>
        </div>
      }
    </>
  );
}
export default Login;

