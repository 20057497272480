import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Heading from "../../../../Common/Heading";
// import {toast} from "react-toastify"

import Add from '@mui/icons-material/Add';

import axios from "axios";
import url from "../../../../Common/URL";

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';

// import { useConfirm } from 'material-ui-confirm';
const TransactionTableComp = () => {

  // const confirm = useConfirm();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [state] = useState({
    uname: sessionStorage.getItem("username"),
  })

  const Columns = useMemo(
    () => [
      { header: 'Product', accessorKey: 'item_name' },
      { header: 'Quantity', accessorKey: 'quantity' },
      { header: 'Price Per Quantity', accessorKey: 'price' },
      { header: 'Location', accessorKey: 'location' },
      { header: 'Done by', id: 'done_by', accessorFn: rowData => `${rowData.creator_fname} ${rowData.creator_lname}` },
      { header: 'Type', accessorKey: 'transaction_type', sorting: false },
      { header: 'Client', id: 'client', accessorFn: rowData => (rowData.fname === "" && rowData.lname === "") ? "--" : `${rowData.fname} ${rowData.lname}` },
      { header: 'Done on', accessorKey: 'trans_date' },

    ],
    [],
  );


  // ------------------------Fetching Student table's data-------------------------------------
  useEffect(() => {
    loadTransactions();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTransactions = () => {
    const obj = { uname: state.uname }
    axios.post(url + "php/inventory_transaction/inventory_transaction.php", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = JSON.parse(response.data.data);
          // console.log(a);
          if (sessionStorage.getItem("role") === "Location Manager") {
            let locations = sessionStorage.getItem("location").toLowerCase().split(',');
            let filter = a.filter(x => {
              return locations.indexOf(x.location.toLowerCase()) >= 0 || locations.indexOf(x.transfer_to_location.toLowerCase()) >= 0
            });

            filter !== null ? setData(filter.reverse()) : setData([]);
            setLoading(false)

          } else {
            a !== null ? setData(a.reverse()) : setData([]);
            setLoading(false)
          }
        } else {
          setData([]);
          setLoading(false)
        }
      })
      .catch((err) => console.log(err));
  }
  // ------------------------Deleting Student table's data-------------------------------------

  // const onDelete=(id  ,resolve)=> {

  //   const delobj = {
  //     id: id,
  //     uname: state.uname, 
  //     tokenId: 123,
  //   };
  //     axios.post(url+"php/inventory_transaction/delete_inventory_transaction.php", delobj)
  //         .then((res) => {
  //           console.log(res);
  //           resolve();
  //         })
  //         .catch((err) => console.log(err));
  // }



  // #############################################################################################

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Inventory</li>
            <li className="breadcrumb-item active" aria-current="page">Transactions</li>
          </ol>
        </nav>

        <Heading name="Transactions" />

        <div className="container-fluid mb-5">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end ">
              <Button variant="contained" onClick={() => navigate("/inv-add-transaction")}>Add Transaction <Add /></Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{ isLoading: loading }}
                columns={Columns}
                data={data}
                enableRowNumbers
                // enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                getRowId={(originalRow) => originalRow.inventory_trans_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/inv-view-transaction/" + row.id),
                  sx: { cursor: 'pointer' },
                })}
              />
            </div>
          </div>
        </div>


      </div>
    </>
  )
}
export default TransactionTableComp

