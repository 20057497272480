import React from "react";
import Navbar from "../../../Common/Navbar";
import Footer from "../../../Common/Footer";
import Sidebar from "../../../Sidebar/Sidebar";
import ViewUserComp from "./Component/ViewUserComp"

const ViewUser =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <ViewUserComp />
        </div>
        <Footer />
        </>
    )
}

export default ViewUser

