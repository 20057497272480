import React, { useState, useEffect, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import Heading from "../../../../Common/Heading"
import { toast } from "react-toastify"
import LoadingSpinner from "../../../../Common/LoadingSpinner";

import Tooltip from '@mui/material/Tooltip';

import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

import axios from "axios";
import url from "../../../../Common/URL"
import { useConfirm } from 'material-ui-confirm';
import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";

const TestTable = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
  })

  const Columns = useMemo(
    () => [
      { header: 'Test Name', accessorKey: 'test_name' },
      { header: 'Type', accessorKey: 'test_type' },
      { header: 'Assignment Type', id: "assignment_type", accessorFn: rowData => rowData.assignment_type === "" ? "--" : rowData.assignment_type },
      { header: 'Page', id: "page", accessorFn: rowData => rowData.page === "" ? "--" : rowData.page },
      { header: 'Level', accessorKey: 'level_name' },
      {
        header: 'Action', id: 'action', enableColumnActions: false, enableSorting: false, accessorFn: rowData => {
          return (
            rowData.status === "1" ?
              <Tooltip title="Preview" placement="right" >
                <VisibilityIcon style={{ padding: 0 }} onClick={(e) => {
                  e.stopPropagation();
                  if (rowData.generator_test === '1') {
                    const win = window.open(`/modern-test-exam-preview/${rowData.test_id}/${0}/${null}`, "_blank");
                    win.focus();
                  }
                  else if (rowData.test_type === "Exam") {
                    const win = window.open(`/exam-preview/${rowData.test_name}/${rowData.test_id}/${0}/${null}/${null}`, "_blank");
                    win.focus();
                  } else if (rowData.test_type === "Assignment") {
                    const win = window.open(`/assignment-preview/${rowData.test_name}/${rowData.test_id}/${0}/${null}/${null}`, "_blank");
                    win.focus();
                  } else {
                    const win = window.open(`/test-preview/${rowData.test_name}/${rowData.test_id}/${0}/${null}/${null}`, "_blank");
                    win.focus();
                  }
                }} />
              </Tooltip> :
              <Tooltip title="Questions unavailable" placement="right" >
                <VisibilityIcon style={{ padding: 0, color: "#D3D3D3" }} onClick={(e) => e.stopPropagation()} />
              </Tooltip>
          );
        }
      }
    ],
    [],
  );
  const [data, setData] = useState([]);

  // ------------------------Fetching Role table data-------------------------------------
  useEffect(() => {
    loadTests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTests = () => {
    const obj = { uname: state.uname }
    axios.post(url + "php/test/test.php", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = JSON.parse(response.data.data);
          // console.log(a);
          a === null ? setData([]) : setData(a);
          setLoading(false)
        } else {
          setData([]);
          setLoading(false)
        }

      })
      .catch((err) => console.log(err));
  }
  // ------------------------Deleting Role table's data-------------------------------------
  function onDelete(id, resolve) {
    //alert(id);
    const delobj = {
      id: id,
      uname: state.uname,
      tokenId: 123,
    };
    axios.post(url + "php/test/delete_test.php", delobj)
      .then((res) => {
        // console.log(res);
        resolve();
      })
      .catch((err) => console.log(err));
  }
  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item active" aria-current="page">Test list</li>
          </ol>
        </nav>

        <Heading name="Test" />

        <div className="container-fluid">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end">
              <Button variant="contained" onClick={() => navigate("/add-test")} >Add Test <Add className="text-center" /></Button>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 mx-auto">

              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.test_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/view-test/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <IconButton
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          // console.log(selectedRows);
                          const delPromise = selectedRows.map((row) => {
                            return new Promise((resolve, reject) => {
                              onDelete(row.id, resolve);
                            })
                          })
                          Promise.all(delPromise).then(() => {
                            loadTests();
                            table.resetRowSelection();
                            toast.error(selectedRows.length + " Row(s) Deleted!")
                          });
                        })
                    }}
                  ><DeleteOutline />
                  </IconButton>
                )}
              />
            </div>
          </div>

        </div>
      </div>

    </>
  )

}
export default TestTable




