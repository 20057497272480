import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Heading from "../../../../Common/Heading";
// import {toast} from "react-toastify"
import LoadingSpinner from "../../../../Common/LoadingSpinner";

import SaveAlt from '@mui/icons-material/SaveAlt';

import axios from "axios";
import url from "../../../../Common/URL";

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
const HistoryTableComp = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);


  const Columns = useMemo(
    () => [
      { header: 'First Name', accessorKey: 'fname' },
      { header: 'Last Name', accessorKey: 'lname' },
      { header: 'Location', accessorKey: 'location_name' },
      { header: 'Batch', accessorKey: 'batch_name' },
      { header: 'Level', accessorKey: 'level_name' },
      { header: 'Updated By', accessorKey: 'created_by' },
      { header: 'Updated Date', accessorKey: 'created_date', },
    ],
    [],
  );

  const loadHistory = () => {
    const obj = { uname: sessionStorage.getItem("username"), id: id }
    axios.post(url + "php/teachers/teacher_history.php", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = JSON.parse(response.data.data);
          // console.log(a);
          a === null ? setData([]) : setData(a.reverse());
          setLoading(false)
        } else {
          setData([]);
          setLoading(false)
        }
      })
      .catch((err) => console.log(err));
  }

  // ------------------------Fetching Teacher table's data-------------------------------------
  useEffect(loadHistory, []);


  // ------------------------Deleting Teacher table's data-------------------------------------

  //     const onDelete=(id)=> {
  //       const delobj = {
  //         id: id,
  //         uname: sessionStorage.getItem("username"),
  //         tokenId: 123,
  //       };
  //         axios.post(url+"php/teachers/delete_teacher.php", delobj)
  //             .then((res) => console.log(res))
  //             .catch((err) => console.log(err));
  //     }
  // ------------------------Custom Theme in table------------------------------------

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item" ><Link to="/teacher">Teachers list</Link></li>
            <li className="breadcrumb-item"><Link to={"/view-teacher/" + id}>View Teacher</Link></li>
            <li className="breadcrumb-item active" aria-current="page">History Log</li>
          </ol>
        </nav>
        <Heading name="History log" />

        <div className="container-fluid mb-5">
          {/* <div className="row my-4">
                <div className="col-12 mx-auto d-flex justify-content-end ">
                    <Link to="/add-teacher" >Add Teacher <Add /></Link>
                </div>
            </div> */}
          <div className="row">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                
                getRowId={(originalRow) => originalRow.teacher_history_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/tch-history-data/" + row.id),
                  sx: { cursor: 'pointer' },
                })}
              />
            </div>
          </div>

        </div>
      </div>

    </>
  )

}
export default HistoryTableComp




