import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Stack, Typography } from "@mui/material";

import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../../Common/Heading";
import url, { laravelUrl } from "../../../../../Common/URL";
import Input from "../../../../Setup/Student/Component/Input";

const AdmissionDetailsComp = () => {

    const { id } = useParams();

    const [loc, setLoc] = useState([]);
    const [paymentStatues, setPaymentStatues] = useState([]);
    const [admissionStatues, setAdmissionStatues] = useState([]);
    const [coordinatorList, setCoordinatorList] = useState([]);

    const [logMessage, setLogMessage] = useState('');

    const [logList, setLogList] = useState([]);

    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({});

    const [admissionFormData, setAdmissionFormData] = useState({
        payment_mode: 'online', payable_amount: '', parent_email: '',
        admission_id: id, created_by: sessionStorage.getItem('username')
    })

    const [admLoading, setAdmLoading] = useState(false);
    const [openSendAdmissionFormDialog, setOpenSendAdmissionFormDialog] = useState(false);

    const [manualPaymentFormData, setManualPaymentFormData] = useState({
        admission_id: id, created_by: sessionStorage.getItem('username'),
        fee_type: 2, fee_amount: '', payment_taken_date: '',
    })
    const [manualPaymentLoading, setManualPaymentLoading] = useState(false);
    const [openManualPaymentDialog, setOpenManualPaymentDialog] = useState(false);


    const Columns = useMemo(
        () => [
            { header: 'Payment Mode', accessorKey: 'fees_mode' },
            { header: 'Amount (Euros)', accessorKey: 'fee' },
            { header: 'Received On', accessorKey: 'paymentDate', type: "date" },
        ],
        [],
    );

    const [paymentsTableData, setPaymentsTableData] = useState([]);

    useEffect(() => {
        loadAllAsyncTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const loadAllAsyncTasks = () => {
        toast.info('Loading...', { autoClose: false, closeOnClick: false });
        setLoading(true);
        let allCompleted = Promise.all([
            loadLocation(),
            loadPaymentStatues(),
            loadAdmissionStatues(),
            loadAdmissionCoordinators(),
            fetchAdmissionDetails(),
            fetchLogMessages(),
            fetchPaymentLogs(),
        ]);
        allCompleted.then(() => {
            toast.dismiss();
            setLoading(false);
        })
    }

    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "php/locations/locations.php", obj)
            .then((response) => {
                let a = JSON.parse(response.data.data);
                // console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    }


    // -----------This function will Fetch all the Existing Payment Statues--------------------
    const loadPaymentStatues = async () => {

        await axios.get(laravelUrl + "lk_payment_status")
            .then((response) => {
                let a = response.data;
                // console.log(a);
                setPaymentStatues(a);
            })
            .catch((err) => console.log(err));
    }

    // -----------This function will Fetch all the Existing Admission Coordinators--------------------
    const loadAdmissionCoordinators = async () => {

        await axios.get(laravelUrl + "admission_coordinator_list")
            .then((response) => {
                let a = response.data;
                // console.log(a);
                setCoordinatorList(a);
            })
            .catch((err) => console.log(err));
    }

    // -----------This function will Fetch all the Existing Admission Statues--------------------
    const loadAdmissionStatues = async () => {

        await axios.get(laravelUrl + "lk_admission_status")
            .then((response) => {
                let a = response.data;
                // console.log(a);
                setAdmissionStatues(a);
            })
            .catch((err) => console.log(err));
    }

    // ------------This function will fetch admission details ----------------------------
    const fetchAdmissionDetails = async () => {
        const obj = {
            admission_id: id,
        };

        await axios.post(laravelUrl + "view_admission_form", obj)
            .then((response) => {
                let a = response.data;
                console.log(a);
                setState(a[0]);
                setAdmissionFormData({ ...admissionFormData, payable_amount: a[0].payable_amount, parent_email: a[0].parent_email });
            })
            .catch((err) => console.log(err));
    }

    // ====================== OnChange =========================
    const onChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        setState(pre => {
            return { ...pre, [name]: value }
        })
    }

    const onSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        state.updated_by = sessionStorage.getItem('username');
        console.log(state);
        await axios
            .patch(laravelUrl + "edit_admission_form", state)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    fetchAdmissionDetails().then(() => {
                        toast.success("Form updated");
                    })
                } else {
                    alert("An error occurred while updating form, please try again later!")
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })
    }

    const handleAddLog = async () => {
        toast.info('Loading...', { autoClose: false, closeOnClick: false });

        const obj = {
            admission_id: id, log_message: logMessage, uname: sessionStorage.getItem('username'),
        };
        console.log(obj);

        await axios.post(laravelUrl + "add_adm_log", obj)
            .then(async (response) => {
                console.log(response);
                if (response.data.success) {
                    fetchLogMessages().then(() => {
                        setLogMessage('');
                        toast.dismiss();
                        toast.success('Log Added');
                    })
                }
            })
            .catch((err) => console.log(err));
    }

    const fetchPaymentLogs = async () => {
        await axios.post(laravelUrl + 'student_fees_log', { admission_id: id }).then((res) => {
            console.log(res);
            setPaymentsTableData(res.data ?? []);
        })
    }


    const fetchLogMessages = async () => {
        await axios.post(laravelUrl + 'adm_log_fetch', { admission_id: id }).then((res) => {
            console.log(res);
            setLogList(res.data ?? []);
        })
    }

    const deleteLogMessage = async (adm_log_id) => {
        toast.info('Loading...', { autoClose: false, closeOnClick: false });
        console.log(adm_log_id);
        await axios.post(laravelUrl + "adm_log_delete", { adm_log_id })
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    fetchLogMessages().then(() => {
                        toast.dismiss();
                        toast.error('Log Deleted');
                    })
                }
            })
            .catch((err) => console.log(err));
    }

    const handleSendAdmissionForm = async (e) => {
        e.preventDefault();
        setAdmLoading(true);
        console.log(admissionFormData);

        toast.info('Loading...', { autoClose: false, closeOnClick: false });
        await axios.post(laravelUrl + "send_admission_form", admissionFormData)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    fetchAdmissionDetails().then(() => {
                        toast.success('Admission form sent');
                    })
                } else {
                    toast.error('An error occurred while sending form, please try again later');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('An error occurred while sending form, please try again later');

            }).finally(() => {
                toast.dismiss();
                setOpenSendAdmissionFormDialog(false);
            })
        setAdmLoading(false);
    }

    const handleManualPayment = async (e) => {
        e.preventDefault();
        setManualPaymentLoading(true);
        // console.log(manualPaymentFormData);

        await axios.post(laravelUrl + "record_manual_payment", manualPaymentFormData)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    fetchPaymentLogs().then(() => {
                        toast.success('Payment added successfully');
                    })
                } else {
                    toast.error('An error occurred while adding payment, please try again later');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('An error occurred while adding payment, please try again later');

            }).finally(() => {
                setAdmLoading(false);
                setManualPaymentLoading(false);
                setManualPaymentFormData({ ...manualPaymentFormData, fee_amount: '', payment_taken_date: '' });
                setOpenManualPaymentDialog(false);
            })
    }

    // ----------functions for Submitting Country and state --------------
    const selectCountry = (val) => {

        setState((preValue) => {
            return {
                ...preValue,
                country: val
            }
        })
    }
    const selectRegion = (val) => {

        setState((preValue) => {
            return {
                ...preValue,
                state: val
            }
        })
    }



    return (
        <>

            {/* ########################################### Dialog to send Admission form ###################################### */}

            <Dialog fullWidth={true} maxWidth="sm" open={openSendAdmissionFormDialog}>
                <DialogTitle id="alert-dialog-title">Send Admission Form</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSendAdmissionForm} id="sendAdmissionForm" >
                        {/* &&&&&&&&&&&&&&&&&&&&&&&& We wont allow to change the payment mode &&&&&&&&&&&&&&&&&&&&&&&& */}

                        {/* <FormControl className="mb-3">
                            <FormLabel id="demo-row-radio-buttons-group-label">Payment Mode</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="payment_mode"
                                value={admissionFormData.payment_mode}
                                onChange={(e) => {
                                    setAdmissionFormData({ ...admissionFormData, payment_mode: e.target.value })
                                }}
                            >
                                <FormControlLabel value="online" control={<Radio />} label="Online" />
                                <FormControlLabel value="offline" control={<Radio />} label="Offline (Manual)" />

                            </RadioGroup>
                        </FormControl> */}
                        <div className="mb-3">
                            <label>Parent Email</label>
                            <input className="form-control" required name="parent_email" value={admissionFormData.parent_email}
                                onChange={(e) => {
                                    setAdmissionFormData({ ...admissionFormData, parent_email: e.target.value })
                                }} />
                            <p className="form-text">The admission form will be sent to this email-address</p>
                        </div>
                        {admissionFormData.payment_mode === 'online' && <div className="mb-3">
                            <label>Payable Amount (In Euros)</label>
                            <input className="form-control" required name="payable_amount" value={admissionFormData.payable_amount}
                                onChange={(e) => {
                                    setAdmissionFormData({ ...admissionFormData, payable_amount: e.target.value })
                                }} />
                        </div>}

                    </form>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" disabled={admLoading} onClick={() => {
                        setOpenSendAdmissionFormDialog(false);
                        setAdmissionFormData({ ...admissionFormData, payment_mode: 'online', payable_amount: '' });
                    }}>Close</Button>
                    <Button type="submit" form="sendAdmissionForm" color="primary" > {admLoading ? <CircularProgress size={20} /> : "Send Form"}</Button>
                </DialogActions>
            </Dialog>
            {/* ################################################################################################################################## */}

            {/* ########################################### Dialog to Record Manual payment ###################################### */}

            <Dialog fullWidth={true} maxWidth="xs" open={openManualPaymentDialog}>
                <DialogTitle id="alert-dialog-title">Record Manual Payment</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleManualPayment} id="handleManualPaymentForm" >
                        <div className="mb-3">
                            <label>Amount (In Euros)</label>
                            <input className="form-control" required name="fee_amount" value={manualPaymentFormData.fee_amount}
                                onChange={(e) => {
                                    setManualPaymentFormData({ ...manualPaymentFormData, fee_amount: e.target.value })
                                }} />
                        </div>

                        <div className="mb-3">
                            <label>Received On</label>
                            <input className="form-control" required name="payment_taken_date" type='date' value={manualPaymentFormData.payment_taken_date}
                                onChange={(e) => {
                                    setManualPaymentFormData({ ...manualPaymentFormData, payment_taken_date: e.target.value })
                                }} />
                            <p className="form-text">Enter the date on which you have received the payment</p>
                        </div>
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={() => {
                        setOpenManualPaymentDialog(false);
                        setManualPaymentFormData({ ...manualPaymentFormData, fee_amount: '', payment_taken_date: '' });
                    }}>Close</Button>
                    <Button type="submit" form="handleManualPaymentForm" color="primary" > {manualPaymentLoading ? <CircularProgress size={20} /> : "Add Payment"}</Button>
                </DialogActions>
            </Dialog>
            {/* ################################################################################################################################## */}


            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">CRM</li>
                        <li className="breadcrumb-item"><Link to="/admission-dashboard">Admission Dashboard</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Admission Details</li>
                    </ol>
                </nav>

                <Heading name="Admission Details" />

                <form onSubmit={onSubmit} id="myform">
                    <div className="container-fluid mt-3">
                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-between mx-auto">
                                <Button variant="contained" size='small' type='button' onClick={() => setOpenSendAdmissionFormDialog(true)} className=" me-3">Send Admission Form To Parent</Button>

                                <Button disabled={loading} variant="contained" type="submit">Update</Button>
                            </div>
                        </div>

                        <div className="row">

                            {/* ----------------------- Column-1 for Inputs ------------------------- */}
                            <div className="col-11 col-md-6">

                                <h5 className="mb-4">Personal Information</h5>

                                <Input name="fname" onChange={onChange} field="First Name" type="text" placeholder="Add text" className="form-control" value={state.fname} />

                                <Input name="mname" onChange={onChange} field="Middle Name" type="text" placeholder="Add text" className="form-control" value={state.mname} required={false} />

                                <Input name="lname" onChange={onChange} field="Last Name" type="text" placeholder="Add text" className="form-control" value={state.lname} />

                                <Input name="dob" onChange={onChange} field="Date of Birth" type="date" placeholder="Add DOB" className="form-control" value={state.dob} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Primary Address</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea name="addressline1" onChange={onChange} className="form-control" placeholder="Add text" value={state.addressline1 ?? ""}  ></textarea>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Secondary Address</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea name="addressline2" onChange={onChange} className="form-control" placeholder="Add text" value={state.addressline2 ?? ""} ></textarea>
                                    </div>
                                </div>

                                <Input name="city" onChange={onChange} field="City" type="text" placeholder="Add text" className="form-control" value={state.city} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Country</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        {/* <select name="state" value={state.state} onChange={onChange} className="form-select">
                                    <option value="province-1">Province 1</option><option value="province-2">Province 2</option><option value="province-3">Province 3</option>
                                </select> */}

                                        <CountryDropdown
                                            className="form-select"
                                            name="country"
                                            value={state.country ?? ""}
                                            onChange={(val) => selectCountry(val)} />
                                    </div>
                                </div>


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Province</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        {/* <select name="country" value={state.country} onChange={onChange} className="form-select">
                                    <option value="1">Country 1</option><option value="country-2">Country 2</option><option value="country-3">Country 3</option>
                                </select> */}

                                        <RegionDropdown
                                            className="form-select"
                                            name="state"
                                            country={state.country}
                                            value={state.state ?? ""}
                                            onChange={(val) => selectRegion(val)} />
                                    </div>
                                </div>


                                <Input name="zip" onChange={onChange} field="Zip" type="text" placeholder="Add text" className="form-control" value={state.zip} />

                                <Input name="mobile_no" onChange={onChange} field="Mobile Number" type="text" placeholder="Add text" className="form-control" value={state.mobile_no} />

                                <Input name="home_no" onChange={onChange} field="Home Phone" type="text" placeholder="Add text" className="form-control" value={state.home_no} required={false} />

                                <Input name="email" onChange={onChange} field="Email" type="email" placeholder="Add text" className="form-control" value={state.email} />


                            </div>
                            {/* -------------Column -2----------------- */}

                            <div className="col-11 col-md-6 ">

                                <h5 className="mb-4">Parent Information</h5>

                                <Input name="parent_fname" onChange={onChange} field="Parent First Name" type="text" placeholder="Add text" className="form-control" value={state.parent_fname} />

                                <Input name="parent_lname" onChange={onChange} field="Parent Last Name" type="text" placeholder="Add text" className="form-control" value={state.parent_lname} />

                                <Input name="parent_mobile_no" onChange={onChange} field="Parent Mobile Number" type="text" placeholder="Add text" className="form-control" value={state.parent_mobile_no} />

                                <Input name="parent_email" onChange={onChange} field="Parent Email" type="email" placeholder="Add text" className="form-control" value={state.parent_email} />

                                <Input name="parent_mobile_no_2" onChange={onChange} field="Parent Mobile Number 2" type="text" placeholder="Add text" className="form-control" value={state.parent_mobile_no_2} />

                                <Input name="parent_email_2" onChange={onChange} field="Parent Email 2" type="email" placeholder="Add text" className="form-control" value={state.parent_email_2} />


                                <h5 className=" mb-4">Account Information</h5>

                                <Input name="acc_name" onChange={onChange} field="Account Name" type="text" placeholder="Add Account Name" className="form-control" value={state.acc_name} />

                                <Input name="acc_number" onChange={onChange} field="Account Number" type="text" placeholder="Add Account Number" className="form-control" value={state.acc_number} />

                                <h5 className="mt-5 mb-4">Admission Information</h5>


                                <div className="w-100 d-flex">
                                    <select name="admission_status_id" value={state.admission_status_id ?? ''} placeholder="Location" onChange={onChange} className="form-select mb-3 me-3">
                                        <option value="">Admission Status </option>
                                        {admissionStatues.map((data, i) => (<option key={i} value={data.admission_type_id}>{data.admission_type}</option>))}
                                    </select>
                                    <select name="payment_status_id" value={state.payment_status_id ?? ''} placeholder="Location" onChange={onChange} className="form-select mb-3">
                                        <option value="">Payment Status </option>
                                        {paymentStatues.map((data, i) => (<option key={i} value={data.payment_status_id}>{data.payment_status}</option>))}
                                    </select>
                                </div>

                                <div className="w-100 d-flex">
                                    <select name="location_id" value={state.location_id ?? ''} placeholder="Location" onChange={onChange} className="form-select mb-3 me-3">
                                        <option value="">Location </option>
                                        {loc.map((data, i) => (<option key={i} value={data.location_id}>{data.name}</option>))}
                                    </select>
                                    <select name="coordinator_id" value={state.coordinator_id ?? ''} placeholder="Location" onChange={onChange} className="form-select mb-3">
                                        <option value="">Admission Coordinator </option>
                                        {coordinatorList.map((data, i) => (<option key={i} value={data.id}>{`${data.first_name} ${data.last_name}`}</option>))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <hr />
                        <div className="row mb-4">

                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-12 col-md-7">
                                <h5 className="mb-3">Payment Logs</h5>

                                <Stack direction='row' justifyContent='space-between' alignItems='start'>
                                    <Input name="payable_amount" onChange={onChange} field="Payable Amount (Euros)" placeholder="Add Amount" className="form-control" value={state.payable_amount} />
                                    <Button variant="contained" size='small' color="warning" type="submit">Add Payable Amount</Button>
                                </Stack>
                                <hr className="m-0 mb-3" />
                                <div className="d-flex justify-content-between mb-3">
                                    <Button
                                        variant="contained"
                                        disabled
                                        size='small'
                                        type='button'
                                        className=""
                                    >Create Student Record</Button>
                                    <Button
                                        variant="outlined"
                                        size='small'
                                        type='button'
                                        onClick={() => setOpenManualPaymentDialog(true)}
                                        className="me-3"
                                    >Record Manual Payment</Button>
                                </div>

                                <MaterialReactTable
                                    columns={Columns}
                                    data={paymentsTableData}
                                    enableRowNumbers
                                    // enableRowSelection
                                    initialState={{ density: 'compact' }}
                                    enableDensityToggle={false}
                                    getRowId={(originalRow) => originalRow.id}

                                />
                            </div>
                            {/* -------------Column -2----------------- */}

                            <div className="col-12 col-md-5 overflow-auto border py-3" style={{ maxHeight: '70vh' }}>
                                <div>
                                    <textarea name="addressline1" className="form-control mb-3"
                                        value={logMessage}
                                        placeholder="Add Log Message"
                                        onChange={(e) => setLogMessage(e.target.value)} >
                                    </textarea>
                                    <Button variant="contained" size='small' type='button' className="float-end" onClick={handleAddLog} >Add Log</Button>
                                </div>

                                <h5 className="mb-4">Logs</h5>
                                {!logList.length && <Typography variant="subtitle2" className=" alert-info alert p-2 fw-semibold">No logs to display, add one.</Typography>}
                                {logList.map((x, i) => {
                                    return <Card className="mb-3" key={i} variant="outlined">
                                        <CardActions className="py-0 border-bottom">
                                            <Typography variant="subtitle2">{`${x.created_by_string} | ${new Date(x.created_at).toLocaleString()}`}</Typography>

                                            <IconButton aria-label="add to favorites" className="ms-auto" onClick={() => deleteLogMessage(x.adm_log_id)}>
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </CardActions>

                                        <CardContent>
                                            <Typography variant="subtitle2" color="text.secondary">
                                                {x.comment_log}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                })}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default AdmissionDetailsComp