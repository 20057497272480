import Axios from "axios";
import url from "./URL";

// -----------This function will Fetch the User Id of logged user  --------------------
    const SetUserId = () => {
        const obj = { uname: sessionStorage.getItem("username") };
        Axios
        .post(url + "php/users/select_user_id.php", obj)
        .then((response) => {
            let resData = JSON.parse(response.data.data);
            // console.log(resData);
            sessionStorage.setItem('userId', resData[0].id);
        }).catch((err) => {
            console.log(err);
            alert('Unable to fetch user-id!');
        });
    };

    export default SetUserId;